/* eslint-disable no-unused-vars */
export interface User {
  userStatus: string;
  userFirstName: string;
  userMiddleName: string;
  userLastName: string;
  userId: number;
  userGender: string;
  userEmail: string;
  userDescription: string;
  userDOB: string;
  updateDate: string;
  schoolName: string;
  schoolId: number;
  roleName: string;
  roleId: number;
  organizationName: string;
  organizationId: number;
  lastActivationDate: string;
  endDate: string;
  createDate: string;
  accountPhone: string;
  classes: string;
}

export type MinimalUser = Pick<User, 'accountPhone' | 'userFirstName' | 'userId' | 'userLastName' | 'userMiddleName'>;

export interface PaginationType {
  page: number;
  totalPages: number;
  totalRows?: number;
  pageSize?: number;
}

export enum UserRoleEnum {
  ORG_ADMIN = '2',
  SCHOOL_ADMIN = '3',
  TEACHER = '4',
  PARENTS = '5',
  STUDENT = '6',
}

export enum EUserRole {
  TEACHER = 'Teacher',
  STUDENT = 'Student',
}

export type UserRole =
  | UserRoleEnum.ORG_ADMIN
  | UserRoleEnum.SCHOOL_ADMIN
  | UserRoleEnum.TEACHER
  | UserRoleEnum.STUDENT
  | UserRoleEnum.PARENTS;

export interface School {
  totalActiveUsers: number;
  totalClasses: number;
  createDate: string;
  endDate: string;
  totalGrades: number;
  organizationId: number;
  schoolId: number;
  schoolName: string;
  updateDate: string;
}
export type MinimalSchool = Pick<School, 'schoolId' | 'schoolName' | 'organizationId' | 'updateDate' | 'createDate'>;

export interface Roles {
  roleName: string;
  roleId: number;
}

export interface AddUserParams {
  dob: string;
  email: string;
  firstName: string;
  gender: string;
  phone: string;
  roleId: number | '';
  schoolId: number | null;
  lastName: string;
  middleName: string;
}

export interface AddSchoolParams {
  cityId: number;
  countryId: number;
  districtId: number;
  schoolAddress: string;
  schoolContactEmail: string;
  schoolContactName: string;
  schoolContactPhone: string;
  schoolName: number;
  grades: Grade[];
}

export interface Grade {
  gradeId: number;
  gradeName: string;
}

export interface SchoolDetail {
  schoolId: number;
  schoolName: string;
  schoolAddress: number;
  districtId: number;
  cityId: number;
  countryId: string;
  schoolPhone: string;
  schoolContactName: string;
  schoolContactEmail: string;
  schoolContactPhone: string;
  grades: Grade[];
  programs: Program[];
}

export interface Program {
  numberOfLessons: number;
  numberOfLevels: number;
  numberOfUnits: number;
  programCode: string;
  programName: string;
  programId: number;
}

export interface District {
  cityId: number;
  cityName: string;
}

export interface City {
  districtId: number;
  districtName: string;
}

export interface ILessonContentOfUnitItem {
  lessonContentDuration: number;
  lessonContentId: number;
  lessonContentName: string;
  lessonContentOrdinalLabel: string;
}

export interface ILessonContentOfUnit {
  lessonModuleAltName: string;
  lessonModuleCode: string;
  lessonModuleDescription: string;
  lessonModuleId: string;
  lessonModuleName: string;
  lessonModuleOrdinalLabel: string;
  lessonModulePath: string;
  lessonModuleThumbnailPath: string;
  programId: number;
  lessonContents: ILessonContentOfUnitItem[];
}
