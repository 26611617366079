import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

interface Props {
  children: ReactNode | ReactNode[];
}

type UserHeader = {
  clickedNewButton: boolean;
};
type HeaderContextProps = {
  userHeader: UserHeader;
  setUserHeader: (userHeader: UserHeader) => void;
};

const HeaderContext = createContext<HeaderContextProps>({
  userHeader: { clickedNewButton: false },
  setUserHeader: () => {},
});

export const HeaderProvider = ({ children }: Props) => {
  const [userHeader, setUserHeader] = useState<UserHeader>({
    clickedNewButton: false,
  });

  const headerContextContextProviderValue = useMemo(() => ({ userHeader, setUserHeader }), [userHeader, setUserHeader]);

  return <HeaderContext.Provider value={headerContextContextProviderValue}>{children}</HeaderContext.Provider>;
};

export const useHeader = () => useContext(HeaderContext);
