import { styled } from '@mui/system';
import { colors, COMMON_VALUE_LAYOUT } from '@/styles/theme';
import { MenuItemBoxProps } from '../ClientLayout';

export const MenuItemBox = styled('div')<MenuItemBoxProps>(({ colorHover }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,

  '&:hover': {
    backgroundColor: colorHover ?? 'transparent',
  },

  '&.activePath': {
    backgroundColor: colorHover,
  },

  borderBottom: `1px solid ${colors.white3}`,
  '&:last-child': {
    borderBottom: 'none',
  },

  '.item-link': {
    color: 'inherit',
    textDecoration: 'none',
    textAlign: 'center',

    width: '100%',
    height: '100%',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    '& .incoming-item': {
      marginBottom: '8px',
      fontStyle: 'italic',
    },

    '&.disable': {
      color: colors.black5,
      pointerEvents: 'none',
    },
  },

  '.custom-svg': {
    width: '40px',
    height: '40px',
  },
}));

export const styles = {
  container: {
    minWidth: COMMON_VALUE_LAYOUT.WIDTH_LEFT_MENU_CLIENT,
    height: '100%',

    position: 'fixed',
    padding: 0,
    borderRight: `1px solid ${colors.white3}`,

    display: 'flex',
    flexDirection: 'column',
  },
};
