import { FC } from 'react';
import { Box, LinearProgress } from '@mui/material';

import { styles } from './styles';
import { TMuiStyle } from '@/types/Common';

const ProgressBar: FC<{ sx: TMuiStyle }> = ({ sx }) => {
  return (
    <Box sx={{ ...styles.loadingWrapper, ...sx }}>
      <LinearProgress />
    </Box>
  );
};

export default ProgressBar;
