import { FC, Fragment } from 'react';
import { Box, List, Stack } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';

import CustomBadge from '@/components/customBadge';
import { MenuProps, studentMenu, teacherMenu } from './data';
import { MenuItemBox, styles } from './styles';
import { UserRoleEnum } from '@/types/Dashboard';

interface LeftMenuClientProps {
  roleId: number | undefined;
}

const LeftMenuClient: FC<LeftMenuClientProps> = ({ roleId }) => {
  const location = useLocation();
  const intl = useIntl();

  const renderMenuItem = (item: MenuProps) => {
    const { name, linkIcon, disableProps } = item;
    const { isDisable, linkIcon: disabledIcon } = disableProps ?? {};

    return (
      <>
        {isDisable && (
          <CustomBadge
            content={intl.formatMessage({
              id: 'common.incoming',
            })}
            className="incoming-item"
          />
        )}
        <Box component="img" src={disabledIcon ?? linkIcon} className="custom-svg" />
        <FormattedMessage id={name} />
      </>
    );
  };

  const menus = roleId?.toString() === UserRoleEnum.STUDENT ? studentMenu : teacherMenu;

  return (
    <List sx={styles.container}>
      {menus.map((item) => {
        const { colorHover, name, link, disableProps, linksActive } = item;
        const { isDisable } = disableProps ?? {};

        const activeLink = linksActive?.some((itemLink) => location.pathname.includes(itemLink));

        return (
          <Fragment key={`menu-${name}`}>
            <MenuItemBox
              colorHover={colorHover}
              className={classNames({
                activePath: !!activeLink,
              })}
            >
              {link ? (
                <Link
                  to={link}
                  className={classNames('item-link', {
                    disable: isDisable,
                  })}
                >
                  {renderMenuItem(item)}
                </Link>
              ) : (
                <Stack
                  className={classNames('item-link', {
                    disable: isDisable,
                  })}
                >
                  {renderMenuItem(item)}
                </Stack>
              )}
            </MenuItemBox>
          </Fragment>
        );
      })}
    </List>
  );
};

export default LeftMenuClient;
