import { useQuery } from '@tanstack/react-query';

import api from '@/apiCustomize';
import { Params } from '@/constants/types';
import { TApiProps } from '@/modules/commonListingPage/CommonListingPage.d';
import { GET_METHOD } from '@/apiCustomize/api.constants';

const getList = async (params: Params, endpoint: string) => {
  const response = await api({
    url: endpoint,
    method: GET_METHOD,
    params,
  });

  return response;
};

const useCommonListPage = (apiProps: TApiProps, params: Params, defaultEnable = true) => {
  const { endpoint, dataKey } = apiProps;

  return useQuery([dataKey, params], () => getList(params, endpoint), {
    enabled: defaultEnable,
  });
};

export default useCommonListPage;
