import { NavigateFunction } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { IntlShape } from 'react-intl';
import get from 'lodash/get';

import { UserRoleEnum } from '@/types/Dashboard';
import { atLeastRole } from '@/utils/validationUtils';
import { ILessonMaterial } from './homework/detail/Homework.d';
import RoutesString from '@/pages/routesString';
import { TEACHING_TYPE } from '../learningManagement/constant';
import { INavigateData } from './scheduleList/ScheduleList.d';
import {
  dateMonthYearHoursMinsHyphenFormat,
  dateMonthYearHyphenFormat,
  dateMonthYearDotFormat,
  hoursMinsFormat,
  TYPE_DATE,
} from '@/constants/datetime';
import { ZERO } from '@/constants';
import { isValidDate } from '@/utils/datetimeUtils';
import {
  ICheckValidEndDateParams,
  ICheckValidStartDateParams,
} from '../learningManagement/lessonSchedule/lessonHomeworkForm/types';
import { IScheduleHomeworkItemEdit as IScheduleHomeworkItemEditFromLesson } from './lesson/form/ScheduleForm.d';
import { IScheduleHomeworkItemEdit as IScheduleHomeworkItemEditFromHomework } from './homework/form/ScheduleForm.d';

export const generateActivities = (lessonMaterialsSort: string, lessonMaterials: ILessonMaterial[] = []) => {
  if (!Array.isArray(lessonMaterials) || (Array.isArray(lessonMaterials) && !lessonMaterials.length)) return [];

  const result: ILessonMaterial[] = [];
  const splittedLessonMaterialsSort = lessonMaterialsSort.split(',');
  splittedLessonMaterialsSort.forEach((item) => {
    const matchedItem = lessonMaterials.find((lessonMaterial) => `${item}` === `${lessonMaterial.lessonMaterialId}`);
    matchedItem && result.push(matchedItem);
  });

  return result;
};

export const checkDateCanStartHomework = (dateStart: string) => new Date() >= new Date(dateStart);
export const generateActionLabel = (roleId: number | undefined, isHomework: boolean, dateStart: string) => {
  if (!roleId) return { canStart: false, actionLabel: 'common.view' };

  let canStart = false;

  if (atLeastRole(roleId, UserRoleEnum.TEACHER)) {
    canStart = !isHomework;
  }

  if (roleId === Number(UserRoleEnum.STUDENT)) {
    canStart = isHomework && checkDateCanStartHomework(dateStart);
  }

  return {
    canStart,
    actionLabel: canStart ? 'common.start' : 'common.view',
  };
};

export const handleNavigateLearning = (
  dataNavigate: INavigateData | undefined,
  roleId: number | undefined,
  navigate: NavigateFunction,
) => {
  if (!roleId) return;
  const { learningId, classId } = dataNavigate ?? {};

  if (atLeastRole(roleId, UserRoleEnum.TEACHER)) {
    // navigate to teaching a lesson
    const isAdmin = roleId !== Number(UserRoleEnum.TEACHER);

    return navigate(
      `${
        isAdmin ? RoutesString.AdminLearningRoute : RoutesString.ClientLearningRoute
      }?learningId=${learningId}&learningType=${TEACHING_TYPE.TEACHING}&classId=${classId}`,
    );
  }

  if (roleId === Number(UserRoleEnum.STUDENT) || roleId === Number(UserRoleEnum.PARENTS)) {
    // navigate to do a homework
    return navigate(
      `${RoutesString.ClientLearningRoute}?learningId=${learningId}&learningType=${TEACHING_TYPE.EXERCISE}&classId=${classId}`,
    );
  }
};

const convertToUnixTime = (date: string | dayjs.Dayjs, time?: string) => {
  if (typeof date === 'string') {
    return time
      ? dayjs(`${date} ${time}`, dateMonthYearHoursMinsHyphenFormat).valueOf()
      : dayjs(date, dateMonthYearHyphenFormat).valueOf();
  }

  return time
    ? dayjs(`${date.format(dateMonthYearHyphenFormat)} ${time}`, dateMonthYearHoursMinsHyphenFormat).valueOf()
    : date.valueOf();
};

const getMessageValidDateTime = (
  unixFirst: number,
  unixSecond: number,
  dateOrTime: string | dayjs.Dayjs,
  format: string,
  typeDate: string,
  intl: IntlShape,
) => {
  let typeMessage = '';
  switch (typeDate) {
    case TYPE_DATE.HOMEWORK_START_DATE:
      typeMessage = 'schedule.form.startDate';
      break;

    case TYPE_DATE.HOMEWORK_START_TIME:
      typeMessage = 'schedule.form.startTime';
      break;

    case TYPE_DATE.HOMEWORK_END_DATE:
      typeMessage = 'schedule.form.dueDate';
      break;

    case TYPE_DATE.HOMEWORK_END_TIME:
      typeMessage = 'schedule.form.dueTime';
      break;

    default:
      break;
  }

  if (unixFirst < unixSecond || !isValidDate(dateOrTime, format))
    return intl
      .formatMessage({
        id: 'schedule.form.field.invalid',
      })
      .replace(
        '{{fieldName}}',
        intl.formatMessage({
          id: typeMessage,
        }),
      );
};

export const checkValidStartDateHomework = (params: ICheckValidStartDateParams) => {
  const { typeDate, currentDateStart, currentTimeStart, unixLessonDateTimeEnd, intl } = params;

  if (typeDate === TYPE_DATE.HOMEWORK_START_DATE) {
    const unixDateEndLesson = dayjs(
      dayjs(unixLessonDateTimeEnd).format(dateMonthYearHyphenFormat),
      dateMonthYearHyphenFormat,
    ).valueOf();

    const unixDateStartHomework = convertToUnixTime(currentDateStart);

    return getMessageValidDateTime(
      unixDateStartHomework,
      unixDateEndLesson,
      currentDateStart,
      dateMonthYearDotFormat,
      TYPE_DATE.HOMEWORK_START_DATE,
      intl,
    );
  }

  if (typeDate === TYPE_DATE.HOMEWORK_START_TIME) {
    const unixDateStartTimeHomework = convertToUnixTime(currentDateStart, currentTimeStart);

    return getMessageValidDateTime(
      unixDateStartTimeHomework,
      unixLessonDateTimeEnd,
      currentTimeStart,
      hoursMinsFormat,
      TYPE_DATE.HOMEWORK_START_TIME,
      intl,
    );
  }
};

export const checkValidEndDateHomework = (params: ICheckValidEndDateParams) => {
  const { typeDate, currentDateStart, currentTimeStart, currentTimeEnd, currentDateEnd, intl } = params;

  if (typeDate === TYPE_DATE.HOMEWORK_END_DATE) {
    const unixDateStart = convertToUnixTime(currentDateStart);

    const unixDateEnd = convertToUnixTime(currentDateEnd);

    return getMessageValidDateTime(
      unixDateEnd,
      unixDateStart,
      currentDateEnd,
      dateMonthYearDotFormat,
      TYPE_DATE.HOMEWORK_END_DATE,
      intl,
    );
  }

  if (typeDate === TYPE_DATE.HOMEWORK_END_TIME) {
    const unixDateTimeStart = convertToUnixTime(currentDateStart, currentTimeStart);

    const unixDateTimeEnd = convertToUnixTime(currentDateEnd, currentTimeEnd);

    return getMessageValidDateTime(
      unixDateTimeEnd,
      unixDateTimeStart,
      currentTimeEnd,
      hoursMinsFormat,
      TYPE_DATE.HOMEWORK_END_TIME,
      intl,
    );
  }
};

export const getNumberOfDaysFromDate = (date: Dayjs, numberOfDays: number) => {
  const nextNumberOfDaysFromDate = [];

  for (let i = ZERO; i < numberOfDays; i++) {
    const nextDate = date.add(i, 'day');

    nextNumberOfDaysFromDate.push(nextDate);
  }

  return nextNumberOfDaysFromDate;
};

export const getScheduleDaysOfWeek = (days: Dayjs[], indexAlwaysSelected?: number) => {
  return days.map((day, index) => {
    let newDay = {
      date: day,
      selected: false,
      isAlwaysSelected: false,
      startTime: '',
      endTime: '',
    };
    if (index === indexAlwaysSelected) newDay = { ...newDay, isAlwaysSelected: true };
    return newDay;
  });
};

export const prepareFormattedDateTime = (
  initValues: IScheduleHomeworkItemEditFromLesson | IScheduleHomeworkItemEditFromHomework | undefined,
  dateKey: string,
  fallbackDate: string | Date,
  format: string,
  time?: string,
) => {
  const finalDate = get(initValues, dateKey);
  return initValues && typeof fallbackDate === 'string'
    ? `${dayjs(new Date(finalDate)).format(format)} ${time}`
    : `${dayjs(new Date(fallbackDate)).format(format)} ${time}`;
};
