import { colors, fontSize, fontWeight, lineHeight } from '@/styles/theme';
import { TMuiStyle } from '@/types/Common';

export const styles: TMuiStyle = {
  dropzoneContainer: {
    cursor: 'pointer',

    '&.MuiContainer-root': {
      padding: 0,
    },

    '.MuiTypography-root': {
      fontSize: fontSize.textSM,
      lineHeight: lineHeight.textLineHeightXL,
    },
  },
  dropzoneWrapper: {
    padding: '16px',
    backgroundColor: colors.white2,
    boxShadow: `0px 1px 2px -4px ${colors.black6Opa10}`,
    borderRadius: '8px',
    color: colors.black1,
    fontWeight: fontWeight.bold,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '4px',
  },
  dropzoneWrapperError: {
    border: `1px ${colors.destructiveRed} dashed`,
  },
  dropzoneWrapperLoading: {
    background: colors.linearPrimary2,
  },
  browseText: {
    fontWeight: fontWeight.bold,
    textDecoration: 'underline',
  },
  errorText: {
    color: colors.destructiveRed,
    marginTop: '4px',
  },
  loadingWrapper: {
    width: '60%',
    maxWidth: '480px',
    marginTop: '20px',
    marginBottom: '18px',

    '> .MuiLinearProgress-root': {
      height: '6px',
      borderRadius: '24px',
    },
  },
};
