import { Dialog, DialogProps, Slide, SlideProps, styled } from '@mui/material';
import classNames from 'classnames';
import { ReactElement, forwardRef } from 'react';
import { colors } from '@/styles/theme';
import { TRANSITION_TIME } from '@/constants/timers';

type CustomDialogProps = DialogProps & {
  position?: string;
};

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    margin: 0,
    minWidth: '536px',
    boxShadow: '0px 2px 4px 0px rgb(0 0 0 / 20%)',
    borderRadius: '16px',
  },

  '&.top': {
    '& .MuiDialog-container': {
      alignItems: 'flex-start',
    },

    '& .MuiDialog-paper': {
      borderRadius: '0 0 16px 16px',
    },
  },

  '&.bottom-right': {
    '& .MuiDialog-container': {
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },

    '& .MuiDialog-paper': {
      borderRadius: '16px 16px 0 0',
    },
  },

  '&.bottom-left': {
    '& .MuiDialog-container': {
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
    },

    '& .MuiDialog-paper': {
      borderRadius: '16px 16px 0 0',
    },
  },

  '&.bottom-middle': {
    '& .MuiDialog-container': {
      alignItems: 'flex-end',
    },

    '& .MuiDialog-paper': {
      borderRadius: '16px 16px 0 0',
    },
  },

  '&.center-middle': {
    '& .MuiDialog-container': {
      alignItems: 'center',
      justifyContent: 'center',
    },

    '& .MuiDialog-paper': {
      borderRadius: '16px',
    },
  },

  '&.darker-backdrop': {
    '& .MuiBackdrop-root': {
      backgroundColor: colors.black6Opa80,
    },
  },

  '& .MuiBackdrop-root': {
    backgroundColor: colors.black6Opa15,
  },
});

const Transition = forwardRef<ReactElement, SlideProps>((props, ref) => {
  return (
    <Slide
      direction="up"
      easing={{
        enter: 'cubic-bezier(0.18,0.89,0.32,1.27)',
      }}
      ref={ref}
      {...props}
    />
  );
});

export const CustomDialog = ({ children, position = 'bottom-right', className, ...rest }: CustomDialogProps) => {
  return (
    <StyledDialog
      scroll="paper"
      TransitionComponent={Transition}
      transitionDuration={TRANSITION_TIME}
      className={classNames(className, position)}
      {...rest}
    >
      {children}
    </StyledDialog>
  );
};
