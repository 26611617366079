import { FC } from 'react';
import { useDropzone, ErrorCode } from 'react-dropzone';
import { Box, Container, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { getAcceptedFileTypes } from './utils';
import { colors } from '@/styles/theme';
import { TDropzoneProps } from './Dropzone.d';
import { ZERO } from '@/constants';

import ProgressBar from '../progressBar/ProgressBar';

import importIcon from '@/assets/images/icons/cloud-upload.svg';

import { styles } from './styles';

const Dropzone: FC<TDropzoneProps> = ({
  errorMessage,
  loading = false,
  field,
  acceptedFileTypes,
  multiple = false,
  maxFiles,
  maxSize,
  minSize,
  disabled = false,
  mappingFileRejectionErrors,
  onDrop,
  onFileDialogOpen,
}) => {
  const supportedFormats = acceptedFileTypes.join(', ');
  const errorText = (
    <Typography sx={styles.errorText}>
      <FormattedMessage id={errorMessage} />
    </Typography>
  );

  const handleOnDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      const formData = new FormData();
      acceptedFiles.forEach((file) => {
        formData.append(field, file, file.name);
      });
      onDrop(formData);
    }
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: handleOnDrop,
    onFileDialogOpen,
    accept: getAcceptedFileTypes(acceptedFileTypes),
    multiple,
    maxFiles,
    maxSize,
    minSize,
    disabled: loading || disabled,
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) =>
    mappingFileRejectionErrors
      ? mappingFileRejectionErrors(file, errors)
      : errors.map((errorItem, index) => {
          const errorNo = errors.length - index;
          const { code, message } = errorItem;
          let finalMessage: JSX.Element | string = message;
          switch (code) {
            case ErrorCode.FileInvalidType:
              finalMessage = <FormattedMessage id="common.import.error.invalidFileType" />;
              break;
            case ErrorCode.FileTooLarge:
              finalMessage = <FormattedMessage id="common.import.error.fileSizeExceed" />;
              break;
            default:
              break;
          }

          return (
            <Typography key={`rejection-item-${code}-${message}-${errorNo}`} sx={styles.errorText}>
              {finalMessage}
            </Typography>
          );
        }),
  );

  const isError = Boolean(fileRejectionItems.length) || Boolean(errorMessage);

  const renderErrors = () => {
    if (fileRejectionItems.length > ZERO) return fileRejectionItems;
    if (errorMessage) return errorText;
    return '';
  };

  return (
    <Container sx={styles.dropzoneContainer}>
      <Box
        sx={{
          ...styles.dropzoneWrapper,
          ...(isError && styles.dropzoneWrapperError),
          ...(loading && styles.dropzoneWrapperLoading),
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Box component="img" src={importIcon} />
        {loading ? (
          <ProgressBar sx={styles.loadingWrapper} />
        ) : (
          <>
            <Typography>
              <FormattedMessage
                id="common.import.placeholder"
                values={{
                  action: (
                    <Typography component="span" sx={styles.browseText}>
                      <FormattedMessage id="common.browse" />
                    </Typography>
                  ),
                }}
              />
            </Typography>
            <Typography color={colors.black3}>
              <FormattedMessage
                id="common.import.supportedFormats"
                values={{
                  supportedFormats,
                }}
              />
            </Typography>
          </>
        )}
      </Box>
      {renderErrors()}
    </Container>
  );
};

export default Dropzone;
