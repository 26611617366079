import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Grid, Typography } from '@mui/material';
import { styleConfirmDialog } from './styles';
import { BulkScheduleConfirmDialogProps } from './BulkScheduleForm.d';

const BulkScheduleConfirmDialog: FC<BulkScheduleConfirmDialogProps> = ({
  lessons,
  homeworks,
  lessonModuleName,
  courseName,
}) => {
  // sometimes homeworks could be longer than lessons, to render both in with 1 map loop without error of lacking data,
  // we need to find the longer part and use it as the main factor to loop

  return (
    <Box>
      <Box textAlign="center" sx={styleConfirmDialog.subTitleContainer}>
        <Box display="flex" justifyContent="center" sx={styleConfirmDialog.labelSubtitle}>
          <FormattedMessage id="dashboard.bulk.schedule.confirmation.dialog.program.title" />:{' '}
          <Typography variant="body1" sx={styleConfirmDialog.subTitle}>
            {lessonModuleName}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" sx={styleConfirmDialog.labelSubtitle}>
          <FormattedMessage id="dashboard.bulk.schedule.confirmation.dialog.sub.title" />:{' '}
          <Typography variant="body1" sx={styleConfirmDialog.subTitle}>
            {courseName}
          </Typography>
        </Box>
      </Box>
      <Grid item={true} container={true}>
        <Grid item={true} xs={5.75} sx={styleConfirmDialog.columnContainer}>
          <Typography sx={styleConfirmDialog.columnHeader}>
            <FormattedMessage id="dashboard.lesson.schedules" /> ({lessons.length})
          </Typography>
        </Grid>
        <Grid item={true} xs={0.5}>
          {' '}
        </Grid>
        <Grid item={true} xs={5.75} sx={styleConfirmDialog.columnContainer}>
          <Typography sx={styleConfirmDialog.columnHeader}>
            <FormattedMessage id="dashboard.homework.schedules" /> ({homeworks.length})
          </Typography>
        </Grid>
      </Grid>
      <Box sx={styleConfirmDialog.lessonsWrapper}>
        {lessons.map((lesson, index) => {
          const { lessonContentId, formattedName } = lesson;
          return (
            <Grid item={true} container={true} sx={styleConfirmDialog.lessonLine} key={`${lessonContentId}-${index}`}>
              <Grid item={true} xs={5.75} sx={styleConfirmDialog.columnContainer}>
                <Typography>{formattedName}</Typography>
              </Grid>
              <Grid item={true} xs={0.5}>
                {' '}
              </Grid>
              <Grid item={true} xs={5.75} sx={styleConfirmDialog.columnContainer}>
                <Typography>{homeworks[index].formattedName}</Typography>
              </Grid>
            </Grid>
          );
        })}
      </Box>
    </Box>
  );
};

export default BulkScheduleConfirmDialog;
