import { FC } from 'react';
import { Box, Grid } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import get from 'lodash/get';
import dayjs from 'dayjs';

import { ETimeValidationType, validateDaysOfWeekRequired, validateStartTimeEndTime } from './validation';
import { dateMonthYearDotFormat } from '@/constants/datetime';
import { FIRST_ITEM_NUMBER } from '@/constants';
import { buildValidation } from '@/utils/validationUtils';

import ScheduleTimeSelector from '@/components/scheduleTimeSelector';
import TimePicker from '@/components/timePicker';

import { IScheduleStepTab } from './BulkScheduleForm.d';

import { StyledSwitch, styles } from './styles';

const ScheduleStepTab: FC<IScheduleStepTab> = ({ control, errors, watch, setValue, getValues }) => {
  const intl = useIntl();
  const watchGlobalTimeEnable = watch('globalTime.enable');
  const watchLessonStart = watch('globalTime.startTime');
  const watchLessonEnd = watch('globalTime.endTime');
  const convertedWatchLessonStart = `${dayjs().format(dateMonthYearDotFormat)} ${watchLessonStart}`;
  const convertedWatchLessonEnd = `${dayjs().format(dateMonthYearDotFormat)} ${watchLessonEnd}`;
  const minLessonEnd = dayjs(convertedWatchLessonStart).add(FIRST_ITEM_NUMBER, 'minute');
  const maxLessonStart = dayjs(convertedWatchLessonEnd).subtract(FIRST_ITEM_NUMBER, 'minute');

  const watchDaysOfWeek = watch('daysOfWeek') || [];

  const errorDaysOfWeek = errors?.daysOfWeek
    ? get(errors, 'daysOfWeek.message') ?? 'dashboard.bulk.schedule.daysOfWeek.invalidDateTime'
    : '';

  const onResetTimeValue = () => {
    const currentDaysOfWeek = getValues('daysOfWeek');
    const resetTimeDaysOfWeek = currentDaysOfWeek.map((dayOfWeek) => ({ ...dayOfWeek, startTime: '', endTime: '' }));
    setValue('globalTime.endTime', '');
    setValue('globalTime.startTime', '');
    setValue('daysOfWeek', resetTimeDaysOfWeek);
  };

  return (
    <>
      <Grid container={true} sx={styles.scheduleTimeSelectorWrapper}>
        <Grid item={true} xs={12}>
          <Box sx={styles.stepTwoLabel}>
            <FormattedMessage id="dashboard.bulk.schedule.selectDates" />
          </Box>
        </Grid>

        <Grid item={true} xs={12} sx={styles.scheduleTimeSelectorContainer}>
          <Controller
            name="daysOfWeek"
            control={control}
            rules={{ validate: validateDaysOfWeekRequired }}
            render={() => (
              <>
                {watchDaysOfWeek.map((dayItem, index) => {
                  const { date, startTime } = dayItem;

                  return (
                    <ScheduleTimeSelector
                      key={`${date.valueOf()}-${startTime}`}
                      control={control}
                      watch={watch}
                      pathName={`daysOfWeek.${index}`}
                      dayItem={dayItem}
                      showTimeSelector={!watchGlobalTimeEnable}
                      setValue={setValue}
                      error={get(errors, `daysOfWeek.${index}`)}
                    />
                  );
                })}
              </>
            )}
          />
        </Grid>
        <Grid item={true} xs={12}>
          {errorDaysOfWeek && (
            <span className="errorMsg">
              <FormattedMessage id={errorDaysOfWeek} />
            </span>
          )}
        </Grid>

        {watchGlobalTimeEnable && (
          <Grid container={true}>
            <Grid item={true} xs={6} sx={styles.globalTimeBlock}>
              <Box sx={styles.stepTwoLabel}>
                <FormattedMessage id="dashboard.bulk.schedule.lessonStart" />
              </Box>
              <Controller
                name="globalTime.startTime"
                control={control}
                rules={{
                  required: intl.formatMessage({
                    id: 'dashboard.bulk.schedule.lessonStart.required',
                  }),
                  validate: (startTime, formValues) => {
                    const endTime = get(formValues, 'globalTime.endTime') || '';
                    const validationValues = validateStartTimeEndTime({
                      startTime,
                      endTime,
                      type: ETimeValidationType.START,
                      translationKey: {
                        invalid: 'dashboard.bulk.schedule.lessonStart.invalid',
                        timeNotInRange: 'dashboard.bulk.schedule.lessonStart.mustBeSmallerThanEndTime',
                      },
                    });
                    return buildValidation(validationValues, intl);
                  },
                }}
                render={({ field, fieldState }) => (
                  <TimePicker
                    {...field}
                    {...fieldState}
                    label="schedule.form.from.placeholder"
                    placeholder="schedule.form.startTime.placeholder"
                    maxTime={maxLessonStart}
                  />
                )}
              />
            </Grid>

            <Grid item={true} xs={6} sx={styles.globalTimeBlock}>
              <Box sx={styles.stepTwoLabel}>
                <FormattedMessage id="dashboard.bulk.schedule.lessonEnd" />
              </Box>
              <Controller
                name="globalTime.endTime"
                control={control}
                rules={{
                  required: intl.formatMessage({
                    id: 'dashboard.bulk.schedule.lessonEnd.required',
                  }),
                  validate: (endTime, formValues) => {
                    const startTime = get(formValues, 'globalTime.startTime') || '';
                    const validationValues = validateStartTimeEndTime({
                      startTime,
                      endTime,
                      type: ETimeValidationType.END,
                      translationKey: {
                        invalid: 'dashboard.bulk.schedule.lessonEnd.invalid',
                        timeNotInRange: 'dashboard.bulk.schedule.lessonEnd.mustBeBiggerThanStartTime',
                      },
                    });
                    return buildValidation(validationValues, intl);
                  },
                }}
                render={({ field, fieldState }) => (
                  <TimePicker
                    {...field}
                    {...fieldState}
                    label="schedule.form.to.placeholder"
                    placeholder="schedule.form.endTime.placeholder"
                    minTime={minLessonEnd}
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid container={true} sx={styles.scheduleTimeAppliesGloballyBlock}>
        <Grid item={true} xs={10}>
          <Box className="schedule-time-globally-label">
            <FormattedMessage id="dashboard.bulk.schedule.scheduleTimeAppliesGlobally" />
          </Box>
        </Grid>
        <Grid item={true} xs={2} sx={styles.rightBlock}>
          <Controller
            name="globalTime.enable"
            control={control}
            render={({ field }) => (
              <StyledSwitch
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  onResetTimeValue();
                }}
                checked={field.value}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ScheduleStepTab;
