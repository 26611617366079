import { colors, fontSize, fontWeight, lineHeight } from '@/styles/theme';

export const styles = {
  dialogWrapper: {
    maxWidth: 'fit-content',
  },
  dialogTitle: {
    padding: '32px 24px 24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  },
  dialogTitleText: {
    fontWeight: fontWeight.semiBold,
    fontSize: fontSize.textLG,
    lineHeight: lineHeight.titleXS,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: colors.secondary1,
  },
  dialogContent: {
    background: colors.linearPrimary2,
    borderBottom: `1px solid ${colors.white3}`,
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '24px',

    '*': {
      fontSize: fontSize.textMD,
      lineHeight: lineHeight.textLineHeightXL,
    },

    '>.MuiBox-root': {
      display: 'flex',
      gap: '4px',

      '>.MuiTypography-root:first-child': {
        fontWeight: fontWeight.semiBold,
        color: colors.black2,
      },
    },
  },
  progressBar: {
    marginTop: '16px',
    marginBottom: '28px',
  },
};
