import { FC, useEffect, useState } from 'react';
import { Box, Stepper, Step, styled, StepIconProps, StepLabel } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useIntl } from 'react-intl';

import { ZERO } from '@/constants';
import { IHorizontalStepper } from './HorizontalStepper.d';

import { styles } from './styles';
import { colors } from '@/styles/theme';

const CustomConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: '20px',
    left: 'calc(-50% + 60px)',
    right: 'calc(50% + 60px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.secondary1,

      '::before': {
        background: colors.secondary1,
      },

      '::after': {
        background: colors.secondary1,
      },
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.secondary1,

      '::before': {
        background: colors.secondary1,
      },

      '::after': {
        background: colors.secondary1,
      },
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    position: 'relative',
    borderColor: colors.grey1,
    borderTopWidth: 3,
    borderRadius: 1,

    '::before, ::after': {
      position: 'absolute',
      top: '-4px',
      content: '""',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      background: colors.grey1,
      transform: 'translate(0, -1px)',
    },

    '::before': {
      left: 0,
    },

    '::after': {
      right: 0,
    },
  },
}));

const CustomStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ ownerState }) => ({
  color: colors.grey1,
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: colors.white1,
  }),
}));

const CustomStepIcon = (props: StepIconProps) => {
  const intl = useIntl();
  const { active, className, icon } = props;

  return (
    <CustomStepIconRoot ownerState={{ active }} className={className}>
      {intl.formatMessage({
        id: 'common.step',
      })}{' '}
      {icon}
    </CustomStepIconRoot>
  );
};

const HorizontalStepper: FC<IHorizontalStepper> = ({
  steps,
  currentStep,
  strictNextStep,
  disableClickNextStep,
  onClick,
}) => {
  const intl = useIntl();

  const initActiveStep = {
    current: currentStep ?? ZERO,
    largest: currentStep ?? ZERO,
  };
  const [activeStep, setActiveStep] = useState(initActiveStep);
  const { current, largest } = activeStep;

  const handleStep = (step: number) => () => {
    if ((strictNextStep && step > largest) || (disableClickNextStep && step > current)) return;

    setActiveStep({
      current: step,
      largest: step >= largest ? step : largest,
    });

    if (onClick) onClick(step);
  };

  useEffect(() => {
    typeof currentStep === 'number' &&
      currentStep < steps.length &&
      setActiveStep({
        largest: currentStep >= largest ? currentStep : largest,
        current: currentStep,
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  return (
    <Box sx={styles.horizontalStepperContainer}>
      <Stepper alternativeLabel={true} activeStep={current} connector={<CustomConnector />}>
        {steps.map((item, index) => {
          const { key, label } = item;
          return (
            <Step key={key} sx={styles.stepItem}>
              <StepLabel StepIconComponent={CustomStepIcon} onClick={handleStep(index)}>
                {intl.formatMessage({
                  id: label,
                }) || ''}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export default HorizontalStepper;
