import { colors, fontSize, fontWeight, lineHeight } from '@/styles/theme';
import { TMuiStyle } from '@/types/Common';

export const styles: TMuiStyle = {
  rosterTitle: {
    color: colors.secondary1,
  },
  rosterImportingBtn: {
    background: colors.linearPrimary2,
    width: '40px',
    height: '40px',
    borderRadius: '24px',
    padding: '10px',

    '& > img': {
      width: '20px',
      height: '20px',
    },
  },
  rosterImporting: {
    padding: '4px',
    width: '104px',
    borderRadius: '24px',
    border: `1px dotted ${colors.secondary1}`,
  },
  selectSchoolPopover: {
    borderRadius: '16px',
    boxShadow: `0px 8px 8px -8px ${colors.black6Opa20}`,
  },
  selectSchoolWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '16px',
    gap: '32px',
    width: '448px',

    '> div': {
      width: '100%',
    },
  },
  selectSchoolTitle: {
    color: colors.secondary1,
    fontWeight: fontWeight.semiBold,
    fontSize: fontSize.textMD,
    lineHeight: lineHeight.textLineHeightXL,
  },
  tooltip: {
    background: colors.white1,
    color: colors.black1,
    fontSize: fontSize.textSM,
    boxShadow: `-2px 2px 8px 0px ${colors.black6Opa20}`,
    padding: '8px',

    '&.MuiTooltip-tooltip.MuiTooltip-tooltip.MuiTooltip-tooltip': {
      marginTop: '8px',
    },

    '.MuiTooltip-arrow': {
      color: colors.white1,
    },
  },
  dialogWrapper: {
    width: '90%',
    maxWidth: '1200px',
  },
  dialogImportTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogImportTitle: {
    '&.MuiDialogTitle-root': {
      color: colors.secondary1,
      fontSize: fontSize.titleSM,
      lineHeight: lineHeight.titleMD,
      /**
       * Due to Inter font support issues for Windows OS
       * Temporary use fontWeight.bold instead of fontWeight.extraBold
       * Will look back later if have time
       */
      fontWeight: fontWeight.bold,
      padding: '28px 40px 44px 40px',

      img: {
        width: '20px',
        height: '20px',
        marginLeft: '6px',
      },
    },
  },
  dialogImportContent: {
    padding: '0 40px 0 40px',

    '>.MuiBox-root': {
      padding: '0 0 16px 0',
    },
  },
  dialogImportLabel: {
    fontSize: fontSize.textSM,
    lineHeight: lineHeight.textLineHeightXL,
    marginBottom: '4px',
  },
  dialogImportTableLabel: {
    fontSize: fontSize.textMD,
    fontWeight: fontWeight.semiBold,
  },
  boxSchoolName: {
    padding: '18px 16px',
    backgroundColor: colors.white2,
    boxShadow: `0px 1px 2px -4px ${colors.black6Opa10}`,
    borderRadius: '6px',
    fontSize: fontSize.textMD,
    lineHeight: lineHeight.textLineHeightXL,
    color: colors.black1,
    fontWeight: fontWeight.bold,
  },
  buttonWrapper: {
    gap: '4px',
    padding: '24px 40px 24px 40px',

    '>.MuiButtonBase-root': {
      padding: '10px 16px',
      minWidth: '108px',
      height: '40px',
    },
  },
};

export const classRosterImportDialogStyles: TMuiStyle = {
  dialogWrapper: {
    width: '50%',
    maxWidth: '536px',
    padding: '24px',
  },
  dialogContent: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '24px',

    '> img': {
      width: '42px',
      height: '42px',
    },
  },
  dialogImportLabel: {
    color: colors.secondary1,
    fontWeight: fontWeight.semiBold,
    fontSize: fontSize.textLG,
    lineHeight: lineHeight.titleXS,
  },
  dialogImportDescription: {
    color: colors.black3,
    fontSize: fontSize.textMD,
    lineHeight: lineHeight.textLineHeightXL,
  },
  dialogAction: {
    padding: 0,
    flexDirection: 'column',
    gap: '12px',
    justifyContent: 'center',

    '> button.MuiButtonBase-root': {
      width: '100%',
      height: '48px',
      marginLeft: 0,
      fontSize: fontSize.textMD,
      lineHeight: lineHeight.textLineHeightXL,
    },
  },
  loadingWrapper: {
    width: '100%',
    marginTop: '16px',
    marginBottom: '28px',
  },
};
