import { colors, globalStyles, fontWeight, fontSize, lineHeight } from '@/styles/theme';

export const styles = {
  contentContainer: {
    overflow: 'auto',
    minWidth: '900px',
  },
  scheduleListingContainer: {
    ...globalStyles.toolbarPadding,
    minHeight: '100vh',
    background: colors.white2,
    padding: '40px',
    overflow: 'auto',

    '& .scroll-able': {
      height: '100vh',
      overflow: 'scroll',
      borderRadius: '0 0 16px 16px',
      border: `1px solid ${colors.grey1}`,
    },

    '& .schedule-by-day-container': {
      borderTop: 'unset',
      borderLeft: 'unset',
      borderRight: 'unset',
      background: colors.white1,

      '&:last-child': {
        border: '0',
      },
    },

    '& .schedule-by-day': {
      padding: '16px 12px',

      '&:not(:last-child)': {
        borderBottom: `1px solid ${colors.grey1}`,
      },

      '& .day-group': {
        margin: '4px 10px',
        fontWeight: fontWeight.semiBold,
        fontSize: '15px',
        lineHeight: '20px',
        color: colors.black3,
      },
    },
  },
};

export const scheduleHeaderStyle = {
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    fontWeight: fontWeight.bold,
    fontSize: fontSize.textLG,
    lineHeight: lineHeight.titleXS,
    flexGrow: 1,
    color: colors.secondary1,
    textTransform: 'uppercase',
    margin: 'auto',
  },
  button: {
    borderRadius: '24px',
    backgroundColor: colors.secondary1,
    width: '144px',
    height: '40px',
    color: colors.white1,
    fontSize: fontSize.textMD,
    fontWeight: fontWeight.medium,
    '&:hover': {
      backgroundColor: colors.white3,
      color: colors.secondary1,
    },
  },
};
