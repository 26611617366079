export const TEACHING_TYPE = {
  TEACHING: 'teaching',
  EXERCISE: 'exercise',
};

export const TYPE_POPUP_HOMEWORK = {
  CHECK_MICROPHONE: 'checkMicrophone',
  RESULT_FORM: 'resultForm',
  CONGRATULATION_FORM: 'congratulationForm',
  HOMEWORK_FORM: 'homeworkForm',
  DEFAULT: 'default',
  CONFIRM_CLOSE: 'confirmClose',
};

export const initShowHomeworkForm = {
  showCheckingForm: false,
  showResultForm: false,
  showCongratulationForm: false,
  showHomeworkForm: false,
  confirmClose: false,
};

export const TYPE_OF_PERMISSIONS_BROWSER = {
  GRANTED: 'granted',
  PROMPT: 'prompt',
  DENIED: 'denied',
};

export const TYPE_LESSON_API = {
  START: 'start',
  END: 'end',
  RESULT: 'result',
  RESULT_SUMMARY: 'result-summary',
};
