import { ReactElement } from 'react';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage } from 'react-intl';

import { CustomDialog } from '@/components/customDialog/CustomDialog';
import recycleBinIcon from '@/assets/images/recycle-bin-icon.svg';

import { styles } from './styles';

export interface ConfirmationOptions {
  title?: string;
  description?: string | ReactElement;
  typeDialog?: 'middle' | 'bottom-right';
  textYes?: string;
  textNo?: string;
  hideYesBtn?: boolean;
  hideNoBtn?: boolean;
  isShowIconTitle?: boolean;
  iconSrc?: string;
  typeFooterText?: 'uppercase' | '';
  hasCloseBtn?: boolean;
}

interface ConfirmDialogProps extends ConfirmationOptions {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  hasCloseBtn?: boolean;
}

export const ConfirmDialog = ({
  open,
  title,
  description,
  typeDialog = 'bottom-right',
  textYes,
  textNo,
  hideYesBtn,
  hideNoBtn,
  onSubmit,
  onClose,
  isShowIconTitle = true,
  typeFooterText = '',
  hasCloseBtn = true,
  iconSrc,
}: ConfirmDialogProps) => {
  return (
    <CustomDialog position={typeDialog} onClose={hasCloseBtn ? onClose : () => {}} open={open}>
      <DialogTitle sx={styles.dialogTitle}>
        {isShowIconTitle && <Box sx={styles.icon} component="img" src={iconSrc ?? recycleBinIcon} />}
        {typeDialog === 'middle' && hasCloseBtn && <CloseIcon className="close" onClick={onClose} />}
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <Stack>
          <Typography sx={styles.title}>
            {title ?? (
              <FormattedMessage id="dashboard.user.cancelTitle" defaultMessage="Are you sure want to cancel?" />
            )}
          </Typography>
          <Box sx={styles.description}>
            {description ?? (
              <FormattedMessage
                id="dashboard.user.cancelDescription"
                defaultMessage="All unsaved data will be lost. Proceed?"
              />
            )}
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <Stack sx={styles.buttonStack}>
          {!hideYesBtn && (
            <Button className={`normal-text ${typeFooterText}`} variant="contained" onClick={onSubmit}>
              {textYes ?? <FormattedMessage id="common.yes" defaultMessage="Yes" />}
            </Button>
          )}
          {!hideNoBtn && (
            <Button className={`normal-text ${typeFooterText}`} variant="outlined" onClick={() => onClose()}>
              {textNo ?? <FormattedMessage id="common.no" defaultMessage="No" />}
            </Button>
          )}
        </Stack>
      </DialogActions>
    </CustomDialog>
  );
};
