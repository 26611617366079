import { fontSize, lineHeight, fontWeight, colors } from '@/styles/theme';
import { TMuiStyle } from '@/types/Common';

export const styles: TMuiStyle = {
  tabList: {
    width: '100%',
    borderRadius: '8px',
  },
  tabListLabel: {
    marginBottom: '8px',
    color: colors.black2,
    fontSize: fontSize.btnXS,
    fontWeight: fontWeight.bold,
    lineHeight: lineHeight.textLineHeightLG,
  },
  tabItem: {
    maxWidth: 'unset',
    padding: '16px',
    background: colors.white1,
    color: colors.secondary1,
    fontSize: fontSize.textLG,
    lineHeight: lineHeight.titleXXS,
    fontWeight: fontWeight.normal,
    textTransform: 'capitalize',

    '&.Mui-selected': {
      background: colors.secondary1,
      fontWeight: fontWeight.bold,
      color: colors.white1,
    },
  },
};
