import { FC } from 'react';
import { Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { styles } from './styles';

const FormTitle: FC = () => {
  return (
    <Box sx={styles.bulkScheduleFormTitle}>
      <FormattedMessage id="dashboard.bulk.schedule.newScheduleTitle" />
    </Box>
  );
};

export default FormTitle;
