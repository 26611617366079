import { KeyboardEvent, MouseEvent } from 'react';
import { Box, List } from '@mui/material';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import { MenuItemBtn } from '../menuItemBtn';

import RoutesString, { RoutesStringWithoutDynamicKey } from '@/pages/routesString';

import calendarIcon from '@/assets/images/icons/calendar-icon.svg';
import classRosterIcon from '@/assets/images/icons/class-roster-icon.svg';
import hourGlassIcon from '@/assets/images/icons/hourglass-icon.svg';
import libraryIcon from '@/assets/images/icons/library-icon.svg';
import pieChartIcon from '@/assets/images/icons/pie-chart-icon.svg';
import schoolIcon from '@/assets/images/icons/school-icon.svg';
import userIcon from '@/assets/images/icons/user-icon.svg';
import disabledDieChartIcon from '@/assets/images/icons/disabled-pie-chart-size-20-icon.svg';

import { styles } from './styles';
import { ECalendarMode } from '@/constants/enums';

type LeftMenuProps = {
  handleLeftMenuClose: (_event: KeyboardEvent | MouseEvent) => void;
};

export const LeftMenu = ({ handleLeftMenuClose }: LeftMenuProps) => {
  const intl = useIntl();
  const location = useLocation();

  return (
    <List sx={styles.list}>
      <Box sx={styles.containerItemMenu}>
        <MenuItemBtn
          handleLeftMenuClose={handleLeftMenuClose}
          nameMenu={intl.formatMessage({
            id: 'menu.manage',
          })}
          srcImage={hourGlassIcon}
          isExpand={true}
          componentChild={
            <>
              <Link to={RoutesString.UserDashboard} className="link">
                <MenuItemBtn
                  nameMenu={intl.formatMessage({
                    id: 'menu.user',
                  })}
                  activePath={location.pathname === RoutesString.UserDashboard}
                  srcImage={userIcon}
                  key={userIcon}
                  handleLeftMenuClose={handleLeftMenuClose}
                />
              </Link>
              <Link to={RoutesString.SchoolDashboard} className="link">
                <MenuItemBtn
                  nameMenu={intl.formatMessage({
                    id: 'menu.school',
                  })}
                  activePath={location.pathname === RoutesString.SchoolDashboard}
                  srcImage={schoolIcon}
                  handleLeftMenuClose={handleLeftMenuClose}
                />
              </Link>
              <Link to={RoutesString.ClassRosterListDashboard} className="link">
                <MenuItemBtn
                  nameMenu={intl.formatMessage({
                    id: 'menu.classRoster',
                  })}
                  activePath={location.pathname === RoutesString.ClassRosterListDashboard}
                  srcImage={classRosterIcon}
                  handleLeftMenuClose={handleLeftMenuClose}
                />
              </Link>
            </>
          }
        />
      </Box>

      <Box sx={styles.containerItemMenu}>
        <Link to={`${RoutesString.AdminScheduleListingPage}?mode=${ECalendarMode.MONTH}`} className="link">
          <MenuItemBtn
            nameMenu={intl.formatMessage({
              id: 'menu.schedule',
            })}
            activePath={location.pathname === RoutesString.AdminScheduleListingPage}
            srcImage={calendarIcon}
            handleLeftMenuClose={handleLeftMenuClose}
          />
        </Link>
      </Box>

      <Box sx={styles.containerItemMenu}>
        <MenuItemBtn
          nameMenu={intl.formatMessage({
            id: 'menu.report',
          })}
          srcImage={pieChartIcon}
          handleLeftMenuClose={handleLeftMenuClose}
          disableProps={{ isDisable: true, linkIcon: disabledDieChartIcon }}
        />
      </Box>

      <Box sx={styles.containerItemMenu}>
        <Link to={RoutesString.Library} className="link">
          <MenuItemBtn
            nameMenu={intl.formatMessage({
              id: 'menu.library',
            })}
            activePath={
              location.pathname === RoutesString.Library ||
              location.pathname.includes(RoutesStringWithoutDynamicKey.ProgramDetail)
            }
            srcImage={libraryIcon}
            handleLeftMenuClose={handleLeftMenuClose}
          />
        </Link>
      </Box>
    </List>
  );
};
