import { useIntl } from 'react-intl';
import { AppBar, Box, IconButton, Stack, Theme, Toolbar, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactNode } from 'react';

import headerLogo from '@/assets/images/header-logo.png';
import { colors } from '@/styles/theme';
import RoutesString from '@/pages/routesString';

import { UserMenu } from '../userMenu';
import menuIcon from '@/assets/images/menu-icon.svg';

interface Props {
  onMenuClick: () => void;
  titleComponent?: ReactNode;
}

const styles = {
  appBar: () => ({
    backgroundColor: 'white',
  }),
  logo: {
    height: '40px',
    cursor: 'pointer',
  },
  menuContainer: {
    marginLeft: '20px',
  },
  menuButton: {
    width: '40px',
    height: '40px',
    padding: '8px',
    backgroundColor: colors.white2,
  },
  back: {
    color: colors.secondary1,
    fontSize: '18px',
    height: '40px',
    whiteSpace: 'nowrap',
    lineHeight: '40px',
    cursor: 'pointer',
    textTransform: 'uppercase',
  },
  titleContainer: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,

    [theme.breakpoints.up('lg')]: {
      marginLeft: '120px',
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: '60px',
    },
  }),
  wrapperTitle: {
    width: '100%',
  },
};

export const Header = ({ onMenuClick, titleComponent }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(RoutesString.Library);
  };

  return (
    <AppBar position="static" sx={styles.appBar}>
      <Toolbar variant="dense">
        <Box component="img" src={headerLogo} sx={styles.logo} />
        <Stack direction="row" sx={styles.menuContainer}>
          <IconButton aria-label="attendees" onClick={onMenuClick}>
            <Box component="img" src={menuIcon} />
          </IconButton>

          <Typography sx={styles.back} align="center" onClick={handleBackClick}>
            /{' '}
            {intl.formatMessage({
              id: 'dashboard.header.backButton',
              defaultMessage: 'BACK',
            })}
          </Typography>
        </Stack>
        <Box sx={styles.titleContainer}>
          <Box sx={styles.wrapperTitle}>{titleComponent}</Box>
          <Box ml="50px" />
          <UserMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
};
