import { colors, fontSize, fontWeight, lineHeight } from '@/styles/theme';

export const styles = {
  customBadgeContainer: {
    padding: '2px 10px',
    gap: '8px',
    borderRadius: '24px',
    fontSize: fontSize.textSM,
    fontWeight: fontWeight.normal,
    lineHeight: lineHeight.textLineHeightXL,

    '&.bg-green-1': {
      background: colors.green1,
    },

    '.text-green-1': {
      color: colors.green1,
    },

    '.text-green-2': {
      color: colors.green2,
    },
  },
};
