import { colors } from '@/styles/theme';

export const styles = {
  drawer: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiDrawer-paper': () => ({
      boxShadow: '0 28px 24px 0 rgba(0, 0, 0, 0.2)',
      borderRight: `1px solid ${colors.grey2}`,
      top: '120px',

      '@media (max-width: 1024px)': {
        top: '90px',
      },
    }),
  },
};
