import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, getAuth, RecaptchaVerifier, User } from 'firebase/auth';

type Props = {
  children?: ReactNode | ReactNode[];
};

type FirebaseContextProps = {
  firebaseApp: FirebaseApp;
  firebaseAuth: Auth;
  firebaseUser: User | null;
  setupRecaptchaVerifier: (_elementID: string, _locationCode?: string) => RecaptchaVerifier | null;
  checkFirebasePhone: (_phone: string) => boolean;
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const FirebaseContext = createContext<FirebaseContextProps>({
  firebaseApp: initializeApp(firebaseConfig),
  firebaseAuth: getAuth(),
  firebaseUser: getAuth().currentUser,
  setupRecaptchaVerifier: () => null,
  checkFirebasePhone: () => false,
});

export const FirebaseProvider = ({ children }: Props) => {
  const firebaseApp = initializeApp(firebaseConfig);
  const firebaseAuth = getAuth();
  const [firebaseUser, setFirebaseUser] = useState(firebaseAuth.currentUser);

  useEffect(() => {
    const unListen = firebaseAuth.onAuthStateChanged((user) => {
      setFirebaseUser(user);
    });

    return () => {
      unListen();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setupRecaptchaVerifier = useCallback(
    (elementID: string): RecaptchaVerifier | null => {
      try {
        return new RecaptchaVerifier(
          elementID,
          {
            size: 'invisible',
            callback: (response: any) => {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              console.warn(response);
            },
          },
          firebaseAuth,
        );
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    [firebaseAuth],
  );

  const checkFirebasePhone = useCallback(
    (phone: string): boolean => {
      return firebaseUser?.phoneNumber === phone;
    },
    [firebaseUser],
  );

  const firebaseContextProps = useMemo(() => {
    return {
      firebaseApp,
      firebaseAuth,
      firebaseUser,
      setupRecaptchaVerifier,
      checkFirebasePhone,
    };
  }, [firebaseApp, firebaseAuth, firebaseUser, setupRecaptchaVerifier, checkFirebasePhone]);

  return <FirebaseContext.Provider value={firebaseContextProps}>{children}</FirebaseContext.Provider>;
};

export const useFirebase = () => {
  return useContext(FirebaseContext);
};
