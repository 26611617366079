import { FC, useEffect } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Controller } from 'react-hook-form';
import get from 'lodash/get';
import classNames from 'classnames';

import { ZERO } from '@/constants';
import { validateNumberLargerThan } from '@/utils/validationUtils';
import { DEFAULT_HOMEWORK_DAYS } from '../constants';

import StyledTextField from '@/components/styledTextField';

import { IBulkScheduleValues, ISettingStep } from './BulkScheduleForm.d';

import { StyledSwitch, styles } from './styles';
import { fontWeight } from '@/styles/theme';

const SettingStep: FC<ISettingStep> = ({ control, errors, watch, setValue }) => {
  const enableAttachHomework = Boolean(watch('attachHomework.enable'));
  const errorDurationDay = get(errors, 'attachHomework.durationDay.message');

  const validateInDaysField = (value: number, formValues: IBulkScheduleValues) => {
    if (formValues.attachHomework.enable)
      return validateNumberLargerThan(value, ZERO) || 'dashboard.bulk.schedule.inDays.mustBeLargerThan0';
    return true;
  };

  useEffect(() => {
    if (enableAttachHomework) {
      setValue('attachHomework.durationDay', DEFAULT_HOMEWORK_DAYS);
    } else setValue('attachHomework.durationDay', ZERO);
  }, [enableAttachHomework, setValue]);

  return (
    <>
      <Container sx={styles.attachHomeworkToLessonWrapper}>
        <Grid container={true} sx={styles.stepThreeCommonStyles}>
          <Grid item={true} xs={10}>
            <Box sx={styles.leftBlock}>
              <FormattedMessage id="dashboard.bulk.schedule.attachHomeworkToLesson" />
            </Box>
          </Grid>
          <Grid item={true} xs={2} sx={styles.rightBlock}>
            <Controller
              name="attachHomework.enable"
              control={control}
              render={({ field }) => <StyledSwitch {...field} checked={field.value} />}
            />
          </Grid>

          <Grid display="flex" marginTop="8px" item={true} xs={12} sx={styles.subDesc}>
            <Typography sx={styles.subDesc} fontWeight={fontWeight.bold}>
              <FormattedMessage id="dashboard.bulk.schedule.on" />
            </Typography>
            <FormattedMessage id="dashboard.bulk.schedule.on.subDesc.firstBlock" />
          </Grid>

          <Grid display="flex" marginBottom="8px" item={true} xs={12} sx={styles.subDesc}>
            <Typography sx={styles.subDesc} fontWeight={fontWeight.bold}>
              <FormattedMessage id="dashboard.bulk.schedule.off" />
            </Typography>
            <FormattedMessage id="dashboard.bulk.schedule.off.subDesc.firstBlock" />
          </Grid>

          <Grid
            item={true}
            xs={12}
            className={classNames({
              show: enableAttachHomework,
            })}
            sx={styles.bulkScheduleHomeworkDurationBox}
          >
            <Grid item={true} xs={9} sx={styles.subDesc}>
              <b>
                <FormattedMessage id="dashboard.bulk.schedule.homeworkDuration" />
              </b>
            </Grid>
            <Grid item={true} xs={3} sx={styles.rightBlock}>
              <Controller
                name="attachHomework.durationDay"
                control={control}
                rules={{
                  required: 'dashboard.bulk.schedule.inDays.required',
                  validate: validateInDaysField,
                }}
                render={({ field }) => (
                  <Box sx={{ ...styles.fieldContainer }}>
                    <StyledTextField
                      {...field}
                      type="number"
                      sx={styles.homeworkDurationInput}
                      disabled={!enableAttachHomework}
                    />
                  </Box>
                )}
              />
            </Grid>
          </Grid>

          {enableAttachHomework && (
            <Grid item={true} xs={12}>
              {errorDurationDay && (
                <span className="errorMsg">
                  <FormattedMessage id={errorDurationDay} />
                </span>
              )}
            </Grid>
          )}
        </Grid>
      </Container>
      {/* 
      <Container>
        <Grid container={true} sx={{ ...styles.stepThreeCommonStyles, ...styles.nationalHolidaysBlock }}>
          <Grid item={true} xs={12} sx={{ ...styles.bulkScheduleEnableDesc, ...styles.subDesc }}>
            <FormattedMessage id="dashboard.bulk.schedule.enable.subDesc.secBlock" />
          </Grid>

          <Grid item={true} xs={10}>
            <Box sx={styles.leftBlock}>
              <FormattedMessage id="dashboard.bulk.schedule.scheduleIncludeNationalHolidays" />
            </Box>
          </Grid>
          <Grid item={true} xs={2} sx={styles.rightBlock}>
            <Controller
              name="includeNationalHoliday"
              control={control}
              render={({ field }) => <StyledSwitch {...field} checked={field.value} />}
            />
          </Grid>
        </Grid>
      </Container> */}
    </>
  );
};

export default SettingStep;
