import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import get from 'lodash/get';

import RoutesString from '@/pages/routesString';
import { zIndex } from '@/styles/theme';
import { TAG_HELP_DESK } from '@/constants/enums';
import { loadScript } from '../commonUtil';
import { DELAY_ONE_AND_A_HALF } from '@/constants/timers';

const pagesToHideHelpDesk = [
  RoutesString.ClientLearningRoute,
  RoutesString.AdminLearningRoute,
  RoutesString.AdminScheduleHomeworkCreatePage,
  RoutesString.AdminScheduleLessonCreatePage,
];
const scriptId = 'freshchat-web';
const elementId = 'fc_frame';
const destroyHelpDeskScriptId = 'destroy-freshchat-web';

export const useHelpDesk = (roleName: string = '') => {
  const location = useLocation();

  const widgetElement = document.getElementById(elementId);

  const tagsFilter = roleName ? [roleName] : [TAG_HELP_DESK.WEB_PLATFORM];

  const helpDeskScriptStr = `
    function initFreshChat() {
      if (window.fcWidget) {
        window.fcWidget.init({
          token: "${process.env.REACT_APP_HELP_DESK_TOKEN}",
          host: "${process.env.REACT_APP_HELP_DESK_HOST}",
          faqTags: { 
            tags: ${JSON.stringify(tagsFilter)}, 
            filterType: 'category'
          },
          onLoad: function() {
            const widgetElement = document.getElementById("${elementId}");
            if (widgetElement) widgetElement.style.zIndex = ${zIndex.zIndexFreshChatReset};
          },
        });
      } else {
        console.error("Freshchat widget not initialized");
      }
    }

    function loadFreshchatScript() {
      var script = document.createElement("script");
      script.src = "${process.env.REACT_APP_HELP_DESK_HOST}/js/widget.js";
      script.onload = initFreshChat;
      document.head.appendChild(script);
    }

    function initiateCall() {
      if (window.fcWidget) {
        initFreshChat();
      } else {
        loadFreshchatScript();
      }
    }

    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", initiateCall);
    } else {
      initiateCall();
    }
  `;

  const destroyHelpDeskScriptStr = `window?.fcWidget?.destroy()`;

  useEffect(() => {
    if (pagesToHideHelpDesk.includes(location.pathname)) {
      widgetElement && (widgetElement.style.display = 'none');
    } else {
      widgetElement && (widgetElement.style.display = 'block');
    }

    const scriptHelpDesk = loadScript({
      scriptId,
      innerHtml: helpDeskScriptStr,
    });

    return () => {
      // Cleanup script element on unmount
      if (scriptHelpDesk) {
        scriptHelpDesk.remove();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const scriptElementSetTags = loadScript({
      scriptId: destroyHelpDeskScriptId,
      innerHtml: destroyHelpDeskScriptStr,
    });

    setTimeout(() => {
      loadScript({
        scriptId,
        innerHtml: helpDeskScriptStr,
      });
    }, DELAY_ONE_AND_A_HALF);

    return () => {
      // Cleanup script element on unmount
      if (scriptElementSetTags) {
        scriptElementSetTags.remove();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleName]);

  const openHelpDesk = () => {
    const fcWidget = get(window, 'fcWidget') as any;

    if (fcWidget) {
      fcWidget.open();
    }
  };

  return { openHelpDesk };
};
