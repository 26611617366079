import { defaults, StoreState } from 'react-sweet-state';

import database from '@/cache';
import { STORE_NAME } from '@/apiCustomize/storeAPI';
import { AUTH_MANAGEMENT_STORE } from '@/store/authManagement/AuthManagement';
import { PROFILE_MANAGEMENT_STORE } from '@/store/profileManagement/ProfileManagement';
import { LANGUAGE_MANAGEMENT_STORE } from '@/store/languageManagement/LanguageManagement';
import { isDevEnv } from '@/config';

const WHITE_LIST = [STORE_NAME, AUTH_MANAGEMENT_STORE, PROFILE_MANAGEMENT_STORE, LANGUAGE_MANAGEMENT_STORE];

export const persistent = (storeState: StoreState<any>) => (next: any) => (fn: any) => {
  const result = next(fn);
  const { key } = storeState;
  const isWhiteList: string[] = WHITE_LIST.filter((store) => key.includes(store));

  if (isWhiteList.length > 0) {
    const state = storeState.getState();
    database.setItem(storeState.key, state).catch((err: Error) => {
      // tslint:disable-next-line:no-console
      console.error(err);
    });
  }

  return result;
};

if (isDevEnv()) {
  defaults.devtools = true;
}

defaults.middlewares.add(persistent);
