import { Drawer } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Header } from '@/modules/dashboard/commonElementsManagement/header';
import { LeftMenu } from '@/modules/dashboard/commonElementsManagement/leftMenu';
import { HeaderProvider } from '@/modules/dashboard/commonElementsManagement/providers/HeaderProvider';
import { useToast } from '@/modules/dashboard/commonElementsManagement/providers/ToastProvider';
import ScrollToTopBtn from '@/components/scrollToTop';

import RoutesString from '@/pages/routesString';
import { IDashboardLayout } from './DashboardLayout';
import useAPIErrorManagement from '@/store/apiErrorManagement/APIErrorManagement';
import useProfileManagement from '@/store/profileManagement/ProfileManagement';
import { ERROR_CODE } from '@/constants/apiCode';
import { checkAccessRole } from '@/utils/validationUtils';
import { dashboardRoleArr, ZERO } from '@/constants';

import { styles } from './styles';

const DashboardLayout: FC<IDashboardLayout> = ({ headerTitleComponent, children, path, scrollToTopOnMount }) => {
  const navigate = useNavigate();
  const [profileMgtState] = useProfileManagement();
  const { activeUser } = profileMgtState ?? {};
  const [isShowLeftMenu, setIsShowLeftMenu] = useState(false);
  const [state] = useAPIErrorManagement();
  const { showError } = useToast();

  useEffect(() => {
    const { statusCode, msg } = state;
    if (statusCode === ERROR_CODE.PERMISSION_DENIED) {
      navigate(RoutesString.PermissionDenied);
      msg && showError(msg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    const { roleId = ZERO } = activeUser ?? {};

    if (!activeUser || !roleId) navigate(RoutesString.UserProfile);
    if (roleId && !checkAccessRole(dashboardRoleArr, `${roleId}`)) navigate(RoutesString.ClientHome);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenuClick = () => {
    setIsShowLeftMenu(true);
  };

  const handleLeftMenuClose = () => {
    setIsShowLeftMenu(false);
  };

  return (
    <HeaderProvider>
      <Header onMenuClick={handleMenuClick} titleComponent={headerTitleComponent} />
      {children}
      <Drawer
        anchor="left"
        open={isShowLeftMenu}
        onClose={handleLeftMenuClose}
        sx={styles.drawer}
        key={`drawer-${path}`}
      >
        <LeftMenu handleLeftMenuClose={handleLeftMenuClose} />
      </Drawer>
      {scrollToTopOnMount && <ScrollToTopBtn />}
    </HeaderProvider>
  );
};

export default DashboardLayout;
