import { useMutation } from '@tanstack/react-query';

import api from '@/apiCustomize';
import config from '@/config';
import { POST_METHOD } from '@/apiCustomize/api.constants';

import {
  IBulkScheduleDaysOfWeek,
  IBulkScheduleValues,
} from '@/modules/scheduleManagement/bulkSchedule/form/BulkScheduleForm.d';

type TBulkScheduleDaysOfWeekRequestBody = Omit<IBulkScheduleDaysOfWeek, 'date'>;

export type TBulkScheduleRequestBody = Omit<
  IBulkScheduleValues,
  'daysOfWeek' | 'programName' | 'className' | 'schoolId' | 'scheduleTabId'
> & {
  daysOfWeek: TBulkScheduleDaysOfWeekRequestBody[];
};

type TBulkScheduleResponse = {
  courseName: string;
  scheduleId: number;
  uuid: string;
  lessonNumber: number;
  relatedHomework: number;
  durationWeek: number;
  startDate: string;
  endDate: string;
};

const scheduleBulkCreate = async (data: TBulkScheduleRequestBody) => {
  const response = await api({
    url: config.API.SCHEDULES_BULK_CREATE,
    method: POST_METHOD,
    data,
  });

  return response.data.data as TBulkScheduleResponse;
};

const useScheduleBulkCreate = () => useMutation((data: TBulkScheduleRequestBody) => scheduleBulkCreate(data));

export { useScheduleBulkCreate };
