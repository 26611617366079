import { Component } from 'react';
import get from 'lodash/get';

import ErrorsPage from '@/pages/errorManagement/ErrorsPage';

class ErrorBoundary extends Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.warn(error, errorInfo);
  }

  render() {
    if (get(this.state, 'hasError')) {
      // You can render any custom fallback UI
      return <ErrorsPage />;
    }

    return get(this.props, 'children') ?? null;
  }
}

export default ErrorBoundary;
