import { FC } from 'react';
import { Collapse, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { SYMBOLS, ZERO } from '@/constants';
import { convertPhoneToInternationalFormat, mapErrorCode } from '@/utils/commonUtil';
import { TClassRosterImportUserTableProps } from './ClassRosterImportClassTable.d';

import { classRosterImportUserTableStyles as styles } from './styles';
import { colors } from '@/styles/theme';

const ClassRosterImportUserTable: FC<TClassRosterImportUserTableProps> = ({ open, users }) => {
  return (
    <TableCell sx={styles.tableCellWrapper} colSpan={12}>
      <Collapse in={open}>
        <Table sx={styles.table}>
          <TableHead sx={styles.tableHead}>
            <TableRow sx={styles.tableHeadRow}>
              <TableCell className="th-no" align="left">
                <Typography>
                  <FormattedMessage id="dashboard.user.tbHeaderNo" />
                  <Divider />
                </Typography>
              </TableCell>
              <TableCell className="th-userName" align="left">
                <Typography>
                  <FormattedMessage id="dashboard.user.tbHeaderUserName" />
                  {SYMBOLS.ASTERISK}
                  <Divider />
                </Typography>
              </TableCell>
              <TableCell className="th-phone" align="left">
                <Typography>
                  <FormattedMessage id="dashboard.user.tbHeaderPhone" />
                  {SYMBOLS.ASTERISK}
                  <Divider />
                </Typography>
              </TableCell>
              <TableCell className="th-role" align="left">
                <Typography>
                  <FormattedMessage id="dashboard.user.tbHeaderRole" />
                  {SYMBOLS.ASTERISK}
                  <Divider />
                </Typography>
              </TableCell>
              <TableCell className="th-class" align="left">
                <Typography>
                  <FormattedMessage id="dashboard.information.class" />
                  <Divider />
                </Typography>
              </TableCell>
              <TableCell className="th-status" align="left">
                <Typography>
                  <FormattedMessage id="dashboard.user.tbHeaderStatus" />
                  <Divider />
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => {
              const { fullName, phone, role, className, error, no } = user;
              const isError = Array.isArray(error) && error.length > ZERO;
              return (
                <TableRow
                  sx={{ ...styles.tableRow, ...(isError && styles.tableRowError) }}
                  key={`${fullName}-${phone}-${no}`}
                >
                  <TableCell align="left" className="td-no">
                    {no}
                  </TableCell>
                  <TableCell align="left">{fullName}</TableCell>
                  <TableCell align="left">{phone ? convertPhoneToInternationalFormat(phone) : ''}</TableCell>
                  <TableCell align="left">{role}</TableCell>
                  <TableCell align="left">{className}</TableCell>
                  <TableCell align="left">
                    {!isError ? (
                      <Typography color={colors.spectrum1}>
                        <FormattedMessage id="dashboard.classRoster.valid" />
                      </Typography>
                    ) : (
                      <>
                        {error.map((item) => {
                          const { code, msg } = item;

                          return (
                            <Typography color={colors.destructiveRed1} key={`error-item-${code}-${msg}`}>
                              {SYMBOLS.BULLET} <FormattedMessage id={mapErrorCode(code)} />
                            </Typography>
                          );
                        })}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Divider sx={styles.divider} />
      </Collapse>
    </TableCell>
  );
};

export default ClassRosterImportUserTable;
