import { colors, zIndex } from '@/styles/theme';

export const styles = {
  spinnerLoadingWrapper: {
    position: 'absolute',
    display: 'flex',
    backgroundColor: colors.spinnerBg,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    zIndex: zIndex.zIndexSpinnerLoading,
  },
};
