import { createTheme, responsiveFontSizes } from '@mui/material';
import { createBreakpoints } from '@mui/system';

import InterRegularTTF from '@/assets/fonts/Inter-Regular.ttf';
import InterRegularOTF from '@/assets/fonts/Inter-Regular.otf';
import InterRegularWoff from '@/assets/fonts/Inter-Regular.woff';
import InterRegularWoff2 from '@/assets/fonts/Inter-Regular.woff2';

export const COMMON_VALUE_LAYOUT = {
  WIDTH_LEFT_MENU_CLIENT: '110px',
  HEIGHT_HEADER_LEARNING_LAYOUT: '64px',
};

export const zIndex = {
  zIndexSpinnerLoading: 2000,
  zIndexPopupLearning: 10,
  zIndexDropdown: 20,
  zIndexFreshChatReset: 1000,
};

export const colors = {
  transparent: 'transparent',
  white1: '#FFFFFF',
  white2: '#F9F8F7',
  white3: '#EDEDED',
  white4: '#E5E5E5',
  black: '#000',
  black1: '#242322',
  black2: '#555453',
  black3: '#7D7D7C',
  black4: '#B5B5B5',
  black5: '#A3A3A3',
  black6: '#333',
  black6Opa15: 'rgba(0, 0, 0, 0.15)',
  black6Opa80: 'rgba(0, 0, 0, 0.8)',
  black6Opa10: 'rgba(0, 0, 0, 0.1)',
  black6Opa20: 'rgba(0, 0, 0, 0.2)',
  primary1: '#F4C044',
  primaryDark1: '#dcad3d',
  primaryDark2: '#b79033',
  primaryLight1: '#f6c960',
  primaryLight2: '#f7d37c',
  primaryLight3: '#fbe6b4',
  secondaryLight1: '#445d9d',
  secondaryLight2: '#657bae',
  secondaryLight3: '#F0F6FF',
  secondaryLight4: '#C1CFE5',
  secondary1: '#23428D',
  secondaryDark1: '#1f3b7f',
  secondaryDark2: '#1a316a',
  spectrum1: '#24913F',
  spectrum2: '#16654A',
  spectrum3: '#17526A',
  spectrum4: '#40598F',
  spectrum5: '#A1788E',
  destructiveRed: '#EF4444',
  destructiveRed1: '#B91C1C',
  red1: '#FEF2F2',
  red2: '#FEE2E2',
  shadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.2)',
  spinnerBg: 'rgba(255, 255, 255, 0.4)',
  grey1: '#C1CFE6',
  grey2: '#DDE1E4',
  linearPrimary1: 'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #F4C044',
  yellow1: '#FAEAC3',
  yellow2: '#FFF2D1',
  linearPrimary2: 'linear-gradient(90deg, rgba(251, 233, 215, 0.8) 0%, rgba(246, 213, 247, 0.8) 100%), #FFFFFF;',
  linearPrimary3: 'linear-gradient(90deg, rgba(251, 233, 215, 0.8) 0%, rgba(246, 213, 247, 0.8) 100%)',
  green1: '#EBFEEB',
  green2: '#24903F',
  green3: '#15803D',
  green4: '#DCFCE7',
  nhUnit1: '#66B3B1',
  nhBackgroundViolet1: '#F8F2FB',
};

export const fontWeight = {
  thin: 100,
  extraLight: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
};

export const fontSize = {
  textXXS: '9px',
  textXS: '11px',
  textSM: '13px',
  textMD: '15px',
  textLG: '18px',
  textXL: '20px',
  textXXL: '24px',
  titleXS: '24px',
  titleSM: '36px',
  titleSMD: '40px',
  titleMD: '48px',
  titleLG: '52px',
  titleXL: '80px',
  btnXS: '12px',
  btnSM: '14px',
  btnMD: '16px',
  subTitleSM: '32px',
  subTitleMD: '44px',
  titlePopupMD: '36px',
};

export const lineHeight = {
  textLineHeightXXS: '10px',
  textLineHeightLG: '18px',
  textLineHeightXL: '20px',
  textLineHeightXXL: '22px',
  titleXXS: '24px',
  titleXS: '28px',
  titleSM: '32px',
  titleSMD: '40px',
  titleMD: '48px',
  titleLG: '52px',
  titleXL: '80px',
  btnXS: '14px.5',
  btnSM: '17px',
  btnMD: '20px',
  subTitleMD: '36px',
  subTitleLG: '44px',
};

export const chartProps = {
  dashboardDoughnutSpacingBetweenLegendAndChart: 28,
  dashboardDoughnutChartWidth: '360px',
  dashboardDoughnutChartHeight: '240px',
  dashboardDoughnutLabelSpacing: 20,
  pointStyle: {
    circle: 'circle',
  },
  position: {
    left: 'left' as const,
    right: 'right' as const,
    bottom: 'bottom' as const,
    top: 'top' as const,
  },
  align: {
    center: 'center' as const,
    end: 'end' as const,
    start: 'start' as const,
  },
  fontWeight: {
    bold: 'bold' as const,
    bolder: 'bolder' as const,
    lighter: 'lighter' as const,
    normal: 'normal' as const,
  },
};

const breakpoints = createBreakpoints({});

export const headerStyles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    fontWeight: fontWeight.bold,
    fontSize: '18px',
    lineHeight: '28px',
    flexGrow: 1,
    color: colors.secondary1,
    textTransform: 'uppercase',
    margin: 'auto',
  },
  button: {
    borderRadius: '24px',
    backgroundColor: colors.secondary1,
    width: '144px',
    height: '40px',
    color: colors.white1,
    fontSize: '15px',
    fontWeight: fontWeight.medium,

    '&:hover': {
      backgroundColor: colors.white3,
      color: colors.secondary1,
    },
  },
};

export const globalStyles = {
  fadeIn: {
    opacity: '1',
    transition: 'opacity 0.2s ease-in-out',
    pointerEvents: 'unset',
  },
  fadeOut: {
    opacity: '0',
    transition: 'opacity 0.2s ease-in-out',
    pointerEvents: 'none',
  },
  toolbarPadding: {
    [breakpoints.up('lg')]: {
      paddingLeft: '80px',
      paddingRight: '80px',
    },
    [breakpoints.down('lg')]: {
      paddingLeft: '60px',
      paddingRight: '60px',
    },
    '@media (max-width: 1024px)': {
      paddingLeft: '30px',
      paddingRight: '30px',
    },
  },
  truncate: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  disabledField: {
    cursor: 'not-allowed',

    '.MuiAutocomplete-root, .MuiInputBase-root': {
      pointerEvents: 'none',
    },
    '.MuiInputBase-root, .MuiIconButton-root, .MuiSvgIcon-root': {
      color: colors.black4,
    },
  },
  dialog: {
    '& .MuiDialog-paper': {
      overflow: 'unset',
    },
  },
  dialogActions: {
    padding: '10px 30px',
  },
};

const defaultTheme = createTheme({
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': `
        @font-face {
          font-family: 'Inter Regular';
          font-style: normal;
          font-weight: ${fontWeight.normal};
          src:
            url(${InterRegularTTF}) format("truetype"),
            url(${InterRegularOTF}) format("opentype"),
            url(${InterRegularWoff}) format("woff"),
            url(${InterRegularWoff2}) format("woff2"),
          font-display: swap;
        }`,
      },
    },
    MuiToolbar: {
      styleOverrides: {
        dense: {
          height: '120px',
          minHeight: '120px',
          ...globalStyles.toolbarPadding,
          '@media (max-width: 1024px)': {
            height: '90px',
            minHeight: '90px',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#23428d',
    },
    secondary: {
      main: '#7D7D7C',
    },
    warning: {
      main: '#F4C044',
    },
  },
});

const theme = responsiveFontSizes(defaultTheme);

export default theme;
