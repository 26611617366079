import { colors } from '@/styles/theme';
import { TMuiStyle } from '@/types/Common';

export const styles: TMuiStyle = {
  loadingWrapper: {
    width: '100%',
    marginTop: '20px',
    marginBottom: '18px',

    '> .MuiLinearProgress-root': {
      height: '6px',
      borderRadius: '24px',
      backgroundColor: colors.white3,
    },
  },
};
