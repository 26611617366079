import { Box, Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { useHeader } from '@/modules/dashboard/commonElementsManagement/providers/HeaderProvider';
import { colors, fontWeight } from '@/styles/theme';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    fontWeight: fontWeight.bold,
    fontSize: '18px',
    lineHeight: '28px',
    flexGrow: 1,
    color: colors.secondary1,
    textTransform: 'uppercase',
    margin: 'auto',
  },
  button: {
    borderRadius: '24px',
    backgroundColor: colors.secondary1,
    width: '144px',
    height: '40px',
    color: colors.white1,
    fontSize: '15px',
    fontWeight: fontWeight.medium,
    '&:hover': {
      backgroundColor: colors.white3,
      color: colors.secondary1,
    },
  },
};

const ManagerUserHeader = () => {
  const { userHeader, setUserHeader } = useHeader();
  const handleNewClick = () => {
    setUserHeader({ ...userHeader, clickedNewButton: true });
  };

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>
        <FormattedMessage id="dashboard.user.headerTitle" />
      </Typography>
      <Button sx={styles.button} onClick={handleNewClick}>
        <FormattedMessage id="dashboard.user.newUserButton" />
      </Button>
    </Box>
  );
};

export default ManagerUserHeader;
