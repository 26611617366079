import { colors } from '@/styles/theme';

export const styles = {
  enteredTextField: {
    '& label': {
      transform: 'translate(0px, -25px) scale(0.75)',
      color: colors.black3,
    },
  },
  lastOption: {
    padding: '6px 16px',

    '&.load-more-btn': {
      cursor: 'pointer',
      color: colors.secondary1,

      '&.disabled': {
        cursor: 'auto',
        color: colors.secondaryLight4,
      },
    },

    '&.end-of-list': {
      color: colors.black5,
      fontStyle: 'italic',
    },
  },

  textFieldStyle: {
    '& label.Mui-focused': {
      transform: 'translate(0px, -25px) scale(0.75)',
    },
    '& legend': {
      display: 'none',
    },
  },
};
