import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { headerStyles } from '@/styles/theme';

const ScheduleLessonHeader = () => {
  return (
    <Box sx={headerStyles.container}>
      <Typography sx={headerStyles.title}>
        <FormattedMessage id="schedule.headerTitle" />
      </Typography>
    </Box>
  );
};

export default ScheduleLessonHeader;
