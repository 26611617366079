export const GET_METHOD = 'get';
export const POST_METHOD = 'post';
export const DELETE_METHOD = 'delete';
export const PUT_METHOD = 'put';
export const PATCH_METHOD = 'patch';
export const DEFAULT_URL = '/';
export const BLOB_TYPE = 'blob';

export const API_FORM_DATA_KEY = {
  FORM_DATA: 'formData',
  IS_FORM_DATA_TYPE: 'isFormDataType',
};
