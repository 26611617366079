import { FC, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { Button, TextField } from '@mui/material';

import { ICalendarTab } from './CalendarTab';

import { styles } from './styles';

const CalendarTab: FC<ICalendarTab> = ({ tabKey, label, active = false, sxTextField, onClick }) => {
  const today = dayjs();
  const [value, setValue] = useState<Dayjs | null>(today);
  const [open, setOpen] = useState(false);

  const onToggle = (toggle: boolean) => {
    setOpen(toggle);
    setValue(today);
    if (toggle && onClick) onClick(tabKey);
  };

  const handleOnChange = (newValue: Dayjs | null) => {
    setValue(newValue);
    if (onClick) onClick(tabKey, newValue);
  };

  return (
    <DatePicker
      open={open}
      onOpen={() => setOpen(true)}
      value={value}
      disablePast={true}
      onChange={handleOnChange}
      onClose={() => onToggle(false)}
      dayOfWeekFormatter={(day) => day}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={sxTextField}
          InputProps={{
            endAdornment: (
              <Button
                onClick={() => onToggle(true)}
                style={
                  active ? { ...styles.customAdornment, ...styles.activeAdornment } : { ...styles.customAdornment }
                }
              >
                {label}
              </Button>
            ),
          }}
        />
      )}
    />
  );
};

export default CalendarTab;
