import { FC } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import ClassRosterImportClassTableRow from './ClassRosterImportClassTableRow';

import { TClassRosterImportTableProps } from './ClassRosterImportClassTable.d';

import { classRosterImportClassTableStyles as styles } from './styles';

const ClassRosterImportTable: FC<TClassRosterImportTableProps> = ({ classGroups }) => {
  return (
    <TableContainer sx={styles.tableContainer}>
      <Table>
        <TableHead sx={styles.tableHead}>
          <TableRow>
            <TableCell className="th-class" align="left">
              <FormattedMessage id="dashboard.information.class" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="dashboard.user.teacher" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="dashboard.user.student" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage id="dashboard.classRoster.valid" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {classGroups.map((classData) => {
            const { classId, className, classType } = classData;
            return (
              <ClassRosterImportClassTableRow key={`${classId}-${className}-${classType}`} classData={classData} />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClassRosterImportTable;
