import { Box, Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { colors, fontSize, fontWeight, lineHeight } from '@/styles/theme';
import { TMuiStyle } from '@/types/Common';
import useCommonListingStore from '@/store/CommonListingStore/CommonListingStore';
import { DEFAULT_COMMON_LISTING_STORE_NAME, PAGE_ACTIONS } from '@/constants';

import ClassRosterImportButtons from './classRosterImport/ClassRosterImportButtons';

const styles: TMuiStyle = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
  },
  title: {
    fontWeight: fontWeight.bold,
    fontSize: fontSize.textLG,
    lineHeight: lineHeight.titleXS,
    flexGrow: 1,
    color: colors.secondary1,
    textTransform: 'uppercase',
    margin: 'auto',
  },
  button: {
    borderRadius: '24px',
    backgroundColor: colors.secondary1,
    width: '144px',
    height: '40px',
    color: colors.white1,
    fontSize: fontSize.textMD,
    fontWeight: fontWeight.medium,
    '&:hover': {
      backgroundColor: colors.white3,
      color: colors.secondary1,
    },
  },
};

const ClassRosterListHeader = () => {
  const store = useCommonListingStore(DEFAULT_COMMON_LISTING_STORE_NAME);
  const [, actionStore] = store();

  const handleNewClick = () => {
    actionStore.setMoreActionsData({
      actionType: PAGE_ACTIONS.CREATE_CLASS_ROSTER,
    });
  };

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>
        <FormattedMessage id="dashboard.classRoster.headerTitle" />
      </Typography>
      <ClassRosterImportButtons />
      <Button sx={styles.button} onClick={handleNewClick}>
        <FormattedMessage id="dashboard.user.newUserButton" />
      </Button>
    </Box>
  );
};

export default ClassRosterListHeader;
