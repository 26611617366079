import { createContainer, createHook, createStore, StoreActionApi } from 'react-sweet-state';

import { ELocaleCode } from '@/constants/enums';
import { ILanguageManagement } from './LanguageManagement.d';

type StoreApi = StoreActionApi<ILanguageManagement>;

export const LANGUAGE_MANAGEMENT_STORE = 'StoreLanguageManagement';

export const initialState: ILanguageManagement = {
  localeCode: ELocaleCode.EN,
  initiated: false,
};

export const actions = {
  setLanguage:
    (payload: any) =>
    ({ setState, getState }: StoreApi) => {
      setState({
        ...getState(),
        ...payload,
      });
    },
  reset:
    () =>
    ({ setState }: StoreApi) => {
      setState(initialState);
    },
};

type Actions = typeof actions;

type StoreContainerProps = {
  initialState: ILanguageManagement;
};

const getCurrentLanguageManagementStore = (state: ILanguageManagement) => state;

export const Store = createStore({
  initialState,
  actions,
  name: LANGUAGE_MANAGEMENT_STORE,
});

const useLanguageManagement = createHook(Store);

export const storeKey = `${Store.key}@__global__`;

export const useLanguageManagementData = createHook(Store, {
  selector: getCurrentLanguageManagementStore,
});

export const LanguageManagementContainer = createContainer<ILanguageManagement, Actions, StoreContainerProps>(Store, {
  onInit:
    () =>
    ({ setState }: StoreApi, { initialState: customInitialState }) => {
      setState({ ...customInitialState });
    },
});

export default useLanguageManagement;
