const REGEX = {
  NUMBER_ONLY: /^\d([.,]\d)?$/,
  DIGIT_ONLY: /^\d+$/,
  DOUBLE_QUOTES_WITH_ANY_TEXT_INSIDE: /"[^"\\]*(?:\\[\s\S][^"\\]*)*"/g,
  AVOID_SPECIAL_CHARACTER_FOR_REGEX: /[-[\]{}()*+?.\\^$#]/g,
  SELECT_FIRST_ZERO: /^0+/,
  SELECT_FIRST_EIGHT_FOUR: /^\+84/,
  NON_DIGIT: /\D/g,
  EMAIL_TYPE: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  PHONE_NUMBER: /(^[1-9](\d{8})+$)|(^0(\d{9})+$)/g,
  HAS_AT_LEAST_A_NUMBER: /\d/,
  HAS_AT_LEAST_A_LETTER: /^(?=[^A-Za-z]*[A-Za-z])[ -~]*$/,
  PASSWORD_RANGE: /^(?=.{8,64}$).*/,
  IOS: /iPhone|iPod|iPad/,
  ANDROID: /Android/,
  ZALO_JS: /zaloJSV2/i,
  VERSION_ONLY: /\d+\.\d+\.\d+/,
  H5P_LIBRARY: /^H5P\.([A-Za-z]+)\s\d+\.\d+$/,
};

export default REGEX;
