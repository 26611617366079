import { colors, fontSize, fontWeight, lineHeight } from '@/styles/theme';
import { TMuiStyle } from '@/types/Common';

export const classRosterImportClassTableStyles: TMuiStyle = {
  tableContainer: {
    '*': {
      fontSize: fontSize.textMD,
      lineHeight: lineHeight.textLineHeightXL,
    },

    th: {
      color: colors.secondary1,
      fontWeight: fontWeight.semiBold,
      padding: '20px 8px',
    },

    td: {
      padding: '20px 8px',
    },
  },
  tableHead: {
    th: {
      borderBottom: `1px dashed ${colors.grey1}`,

      '&.th-class': {
        width: '50%',
      },
    },
  },
};

export const classRosterImportClassTableRowStyles: TMuiStyle = {
  open: {
    '>td': {
      borderBottom: 'none',
    },
  },
  '>td': {
    borderBottom: `1px dashed ${colors.grey1}`,
    padding: '20px 8px',
  },
  classNameWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  validWrapper: {
    display: 'flex',
    gap: '8px',
  },
  userTableCollapse: {
    padding: 0,
    marginRight: '8px',

    svg: {
      width: '24px',
      height: '24px',
    },
  },
  roundedBox: {
    padding: '2px 10px',
    borderRadius: '24px',
    width: 'fit-content',
    backgroundColor: colors.green4,
  },
  roundedBoxInvalid: {
    backgroundColor: colors.red2,
  },
};

export const classRosterImportUserTableStyles: TMuiStyle = {
  tableCellWrapper: {
    marginTop: '16px',

    '&.MuiTableCell-root': {
      padding: 0,
      borderBottom: 'unset',
    },
  },
  table: {
    border: `1px dashed ${colors.secondaryLight3}`,

    'td, th, tr': {
      borderBottom: `1px dashed ${colors.secondaryLight3}`,
    },
  },
  tableHeadRow: {
    '>th': {
      '&.th-no': {
        width: '50px',
        paddingLeft: '40px',
      },

      '&.th-userName': {
        width: '20%',
      },

      '&.th-phone': {
        width: '150px',
      },

      '&.th-role': {
        width: '150px',
      },

      '&.th-class': {
        width: '20%',
      },

      '&.th-status': {
        paddingRight: '40px',
      },

      '>.MuiTypography-root': {
        position: 'relative',
        fontWeight: fontWeight.semiBold,

        hr: {
          position: 'absolute',
          width: 'calc(100% + 16px)',
          bottom: '-16px',
          borderColor: 'black',
          left: '-8px',
        },
      },
    },
  },
  tableRow: {
    '>td': {
      '&.td-no': {
        paddingLeft: '40px',
      },

      '&.td-status': {
        paddingRight: '40px',
      },
    },
  },
  tableRowError: {
    backgroundColor: colors.red1,
  },
  divider: {
    borderColor: colors.grey1,
    borderStyle: 'dashed',
    marginTop: '24px',
  },
};
