import { useQuery } from '@tanstack/react-query';

import api from '@/apiCustomize';
import config from '@/config';
import { API_ENTITY } from '@/constants';
import { POST_METHOD } from '@/apiCustomize/api.constants';

interface IPayloadTransfer {
  userId: number | undefined;
  organizationId: number | undefined;
}

const getTransferToken = async (payload: IPayloadTransfer, authToken: string) => {
  type GetTransferTokenResponse = {
    token: string;
  };

  const response = await api({
    url: `${config.API.TRANSFER_ENDPOINT}`,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    method: POST_METHOD,
    data: payload,
  });

  return response.data.data as GetTransferTokenResponse;
};

const useTransferTokenQuery = (payload: IPayloadTransfer, authToken: string) => {
  const { userId } = payload;

  return useQuery([API_ENTITY.TRANSFER, userId, authToken], () => getTransferToken(payload, authToken), {
    enabled: !!userId,
  });
};

export default useTransferTokenQuery;
