import { FC } from 'react';
import { Box, Checkbox, CheckboxProps } from '@mui/material';

import checkbox from '@/assets/images/checkbox.svg';
import unCheckbox from '@/assets/images/uncheckbox.svg';

export const StyledCheckBox: FC<CheckboxProps> = ({ ...rest }) => {
  return (
    <Checkbox
      icon={<Box component="img" src={unCheckbox} />}
      checkedIcon={<Box component="img" src={checkbox} />}
      sx={{
        padding: '6px 10px',
      }}
      {...rest}
    />
  );
};
