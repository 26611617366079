import { FC } from 'react';
import { Stack, CircularProgress } from '@mui/material';

import { styles } from './styles';

const Spinner: FC = () => {
  return (
    <Stack sx={styles.spinnerLoadingWrapper}>
      <CircularProgress />
    </Stack>
  );
};

export default Spinner;
