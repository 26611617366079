import { EFileExtension } from './enums';

export const MATCHING_MIME_TYPE_TO_FILE_EXTENSION = {
  [EFileExtension.CSV]: ['text/csv', 'application/vnd.ms-excel'],
  [EFileExtension.XLS]: ['application/vnd.ms-excel'],
  [EFileExtension.XLSX]: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  [EFileExtension.PNG]: ['image/png'],
  [EFileExtension.JPG]: ['image/jpg'],
  [EFileExtension.HTML]: ['text/html'],
  [EFileExtension.HTM]: ['text/html'],
};
