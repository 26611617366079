import { createStore, createHook, StoreActionApi } from 'react-sweet-state';

import { ICommonListingStore, IMoreActions } from './CommonListingStore.d';
import { PAGINATION } from '@/constants';
import { AnyObject, Params } from '@/constants/types';

type StoreApi = StoreActionApi<ICommonListingStore>;

const defaultPage = PAGINATION.DEFAULT_CURRENT_PAGE;
const defaultPageSize = String(PAGINATION.DEFAULT_PAGE_SIZE);
const defaultTotalPages = PAGINATION.DEFAULT_TOTAL_PAGES;

export const initialParams = {
  search: null,
  pageSize: defaultPageSize,
  page: defaultPage,
  totalPages: defaultTotalPages,
};

export const initialState: ICommonListingStore = {
  data: [],
  params: initialParams,
  isQuickFilter: false,
  isSearching: false,
  isPaging: false,
  isSorting: false,
  moreActionsData: {
    actionType: '',
    data: null,
  },
};

export const actions = {
  setData:
    (newData: AnyObject[]) =>
    async ({ getState, setState }: StoreApi) => {
      setState({ ...getState(), data: [...newData] });
    },
  setPagination:
    ({ page, totalPages, pageSize }: Params) =>
    ({ setState, getState }: StoreApi) => {
      const currentState = {
        ...getState(),
      };
      setState({
        ...currentState,
        params: {
          ...currentState.params,
          pageSize,
          page,
          totalPages,
        },
      });
    },
  setSearching:
    (isSearching = false, params: Params = {}) =>
    ({ setState, getState }: StoreApi) => {
      const finalParams = {
        pageSize: defaultPageSize,
        page: defaultPage,
        ...params,
      };

      setState({
        ...getState(),
        params: finalParams,
        isSearching,
      });
    },
  setSorting:
    (isSorting = false, params: Params = {}) =>
    ({ setState, getState }: StoreApi) => {
      const finalParams = {
        pageSize: defaultPageSize,
        page: defaultPage,
        ...params,
      };

      setState({
        ...getState(),
        params: finalParams,
        isSorting,
      });
    },
  setQuickFilter:
    (isQuickFilter = false, params: Params = {}) =>
    ({ setState, getState }: StoreApi) => {
      const finalParams = {
        pageSize: defaultPageSize,
        page: defaultPage,
        ...params,
      };

      setState({
        ...getState(),
        params: finalParams,
        isQuickFilter,
      });
    },
  setMoreActionsData:
    (moreActionsData: IMoreActions) =>
    ({ setState, getState }: StoreApi) => {
      setState({
        ...getState(),
        moreActionsData,
      });
    },
  setParams:
    (params: Params = {}) =>
    ({ setState, getState }: StoreApi) => {
      setState({
        ...getState(),
        params,
      });
    },
};

const Store = (storeName: string) =>
  createStore({
    initialState,
    actions,
    name: storeName,
  });

const useCommonListingStore = (storeName: string) => createHook(Store(storeName));

export default useCommonListingStore;
