import { Box, List, ListItemButton, ListItemIcon, ListItemText, Badge } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { ReactNode, KeyboardEvent, MouseEvent, useState } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import CustomBadge from '@/components/customBadge';

import { CustomSvg, styles } from './styles';
import { colors } from '@/styles/theme';

interface IDisableItem {
  isDisable: boolean;
  linkIcon: string;
}

type Props = {
  disableProps?: IDisableItem;
  srcImage: string;
  nameMenu: string;
  componentChild?: ReactNode | ReactNode[] | null;
  isExpand?: boolean;
  isNotify?: boolean;
  activePath?: boolean;
  handleLeftMenuClose?: (_event: KeyboardEvent | MouseEvent) => void;
};
export const MenuItemBtn = ({
  nameMenu,
  srcImage,
  isExpand = false,
  isNotify = false,
  componentChild = null,
  activePath,
  disableProps,
  handleLeftMenuClose,
  ...props
}: Props) => {
  const intl = useIntl();
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [colorHover, setColorHover] = useState<string | null>(null);
  const { isDisable, linkIcon: disabledIcon } = disableProps ?? {};

  const handleOnClick = (_event: KeyboardEvent | MouseEvent) => {
    if (isDisable) return;

    isExpand ? setOpenSubMenu(!openSubMenu) : handleLeftMenuClose?.(_event);
  };

  return (
    <>
      <ListItemButton
        sx={styles.itemButton}
        className={classNames({
          openSubMenu: openSubMenu || activePath,
          disable: isDisable,
        })}
        onMouseOver={() => {
          !isDisable && setColorHover(colors.secondary1);
        }}
        onMouseLeave={() => {
          !isDisable && setColorHover(null);
        }}
        onClick={handleOnClick}
        {...props}
      >
        <ListItemIcon sx={styles.itemIcon}>
          {disabledIcon ? (
            <Box component="img" src={disabledIcon} />
          ) : (
            <CustomSvg
              src={srcImage}
              myColor={colorHover}
              className={classNames({
                openSubMenu: openSubMenu || activePath,
              })}
            />
          )}
        </ListItemIcon>
        <ListItemText>
          {nameMenu}{' '}
          {isDisable && (
            <CustomBadge
              content={intl.formatMessage({
                id: 'common.incoming',
              })}
              className="incoming-item"
            />
          )}
        </ListItemText>

        {isNotify && <Badge badgeContent={3} sx={styles.notify} />}

        {isExpand && (openSubMenu ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>

      {isExpand && (
        <Collapse in={openSubMenu} timeout="auto" unmountOnExit={true}>
          <List component="div" disablePadding={true} sx={styles.staightLine}>
            <Box sx={styles.boxChild}>{componentChild}</Box>
          </List>
        </Collapse>
      )}
    </>
  );
};
