import { fontSize, lineHeight, fontWeight, colors } from '@/styles/theme';
import { TMuiStyle } from '@/types/Common';

export const styles: TMuiStyle = {
  lessonModuleWrapper: {
    padding: '0 24px',

    '.errorMsg': {
      color: colors.destructiveRed,
      marginTop: '3px',
      marginLeft: '10px',
      fontSize: '0.75rem',
      textAlign: 'left',
    },
  },
  headerWrapper: {
    padding: '36px 0',
  },
  headerTitle: {
    textAlign: 'center',
    fontSize: fontSize.textLG,
    lineHeight: lineHeight.titleXXS,
    fontWeight: fontWeight.bold,
  },
  unitDetailWrapper: {
    marginTop: '8px',
    alignItems: 'center',
  },
  unitName: {
    fontSize: fontSize.textLG,
    lineHeight: lineHeight.textLineHeightXL,
  },
  unitDetail: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',

    img: {
      cursor: 'pointer',
    },
  },
  chosenLessons: {
    fontSize: fontSize.textSM,
    color: colors.secondary1,
    fontWeight: fontWeight.bold,
  },
};
