import { Box, TextField, InputAdornment, IconButton } from '@mui/material';
import IconClear from '@mui/icons-material/CloseRounded';
import { useIntl } from 'react-intl';

import searchIcon from '@/assets/images/search-icon.svg';

import { styles } from './styles';

const ManageDashboardHeader = () => {
  const intl = useIntl();

  return (
    <TextField
      sx={styles.search}
      variant="standard"
      placeholder={intl.formatMessage({
        id: 'dashboard.header.searchPlaceHolder',
        defaultMessage: 'Try searching for menu, units, lesson or materials',
      })}
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <Box component="img" src={searchIcon} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="Clear phone number">
              <IconClear color="primary" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default ManageDashboardHeader;
