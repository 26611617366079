import { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import BulkScheduleFormWrapper from '@/modules/scheduleManagement/bulkSchedule/form/BulkScheduleFormWrapper';
import { scheduleHeaderStyle } from './styles';

const ScheduleListLayoutHeader = () => {
  const [openBulkScheduleModal, setOpenBulkScheduleModal] = useState(false);

  const handleNewClick = () => {
    setOpenBulkScheduleModal(true);
  };

  return (
    <>
      <Box sx={scheduleHeaderStyle.container}>
        <Typography sx={scheduleHeaderStyle.title}>
          <FormattedMessage id="dashboard.schedule.headerTitle" />
        </Typography>
        <Button sx={scheduleHeaderStyle.button} onClick={handleNewClick}>
          <FormattedMessage id="dashboard.bulk.schedule.add" />
        </Button>
      </Box>
      <BulkScheduleFormWrapper open={openBulkScheduleModal} setOpen={setOpenBulkScheduleModal} />
    </>
  );
};

export default ScheduleListLayoutHeader;
