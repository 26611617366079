import { FC, Suspense, useEffect, useMemo } from 'react';
import { Navigate, RouteProps, useLocation } from 'react-router-dom';
import get from 'lodash/get';
import { useIntl } from 'react-intl';

import Spinner from '@/components/spinner/Spinner';
import PageNotFound from './errorManagement/PageNotFound';
import DashboardLayout from '@/components/layout/dashboardLayout';
import ClientLayout from '@/components/layout/clientLayout';

import RoutesString from './routesString';
import { SECOND_ITEM_INDEX, ROUTE_ENTITY, SYMBOLS } from '@/constants';
import useAuthManagement from '@/store/authManagement/AuthManagement';
import { LAYOUT_MODE } from '@/constants/enums';
import { usePreventDownloadMedia } from '@/utils/hooks/usePreventDownloadMedia';

interface IProps {
  component: any;
  path: string;
  exact?: boolean;
  pageName?: string;
  location?: Location;
  title?: string;
  miscPage?: boolean;
  scrollToTopOnMount?: boolean;
  headerTitleComponent?: any;
  isShowHeader?: boolean;
  titleHeader?: string;
  layoutMode: string;
}

const isIncludePrivateRoute = (path: string | undefined) => {
  return Object.keys(RoutesString).some((item) => {
    return RoutesString[item] === path;
  });
};

const renderPage = (InsideComponent: any) => (props: RouteProps) => {
  usePreventDownloadMedia();

  return (
    <Suspense fallback={<Spinner />}>
      <InsideComponent {...props} />
    </Suspense>
  );
};

const PrivateRoute: FC<IProps & RouteProps> = ({ component, path, layoutMode, title, ...rest }) => {
  const [authMgtState] = useAuthManagement();
  const { token, transferToken } = authMgtState;
  const isIncluded = isIncludePrivateRoute(path);
  const location = useLocation();

  // NOTES: useMemo to remember old page not create every time in rendering pages
  // or remove curry function in rerenderPage to avoid error when rerender
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const RenderPage = useMemo(() => renderPage(component), [component, location.pathname, location.search]);
  const intl = useIntl();

  useEffect(() => {
    document.title = title
      ? `${intl.formatMessage({ id: 'common.title' })} ${SYMBOLS.HYPHEN} ${intl.formatMessage({ id: title })}`
      : intl.formatMessage({ id: 'common.title' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  if (!token) return <Navigate to={RoutesString.Login} />;
  const noNeedTransferTokenRoutes = [RoutesString.Profile, RoutesString.BusinessSelection, RoutesString.Support];

  if (!transferToken && !noNeedTransferTokenRoutes.includes(path)) {
    return <Navigate to={RoutesString.BusinessSelection} />;
  }

  if (!isIncluded) return <PageNotFound />;

  if (layoutMode === LAYOUT_MODE.NO) return <RenderPage />;

  const clientLayout = layoutMode === LAYOUT_MODE.CLIENT;
  const welcomeUrl = clientLayout ? RoutesString.ClientHome : RoutesString.Library;

  const routePrefix = get(path.split('/'), [SECOND_ITEM_INDEX]) ?? '';
  const whitelistRouting =
    (!clientLayout && routePrefix === ROUTE_ENTITY.ADMIN) || (clientLayout && routePrefix === ROUTE_ENTITY.CLIENT);

  if (!whitelistRouting) return <Navigate to={welcomeUrl} />;

  const Layout = clientLayout ? ClientLayout : DashboardLayout;

  return (
    <Layout path={path} {...rest}>
      <RenderPage />
    </Layout>
  );
};

export default PrivateRoute;
