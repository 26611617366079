import { lazy } from 'react';

/**
 * Public Pages
 */
const Home = lazy(() => import('@/pages/authenManagement/CheckingPhonePage'));
const Login = lazy(() => import('@/pages/authenManagement/LoginPage'));
const Register = lazy(() => import('@/pages/authenManagement/RegisterPage'));
const ForgotPassword = lazy(() => import('@/pages/authenManagement/ForgotPasswordPage'));

/**
 * Auth Pages
 * Use for both admin and client
 */
const Logout = lazy(() => import('@/pages/authenManagement/LogoutPage'));
const Profile = lazy(() => import('@/pages/authenManagement/ProfilePage'));
const BusinessSelectionPage = lazy(() => import('@/pages/authenManagement/BusinessSelection'));
const SupportPage = lazy(() => import('@/pages/errorManagement/SupportPage'));

/**
 * Admin Pages
 */
const Dashboard = lazy(() => import('@/pages/adminManagement/dashboardManagement'));
const Library = lazy(() => import('@/pages/adminManagement/libraryManagement/LibraryListingPage'));
const ProgramDetail = lazy(() => import('@/pages/adminManagement/programManagement/ProgramDetailPage'));
const UserDashboard = lazy(() => import('@/pages/adminManagement/userManagement/UserListingPage'));
const SchoolDashboard = lazy(() => import('@/pages/adminManagement/schoolManagement/SchoolListingPage'));
const ClassRosterListDashboard = lazy(
  () => import('@/pages/adminManagement/classRosterManagement/ClassRosterListingPage'),
);

const AdminScheduleListingPage = lazy(() => import('@/pages/adminManagement/scheduleManagement/ScheduleListingPage'));
const AdminScheduleHomeworkCreatePage = lazy(
  () => import('@/pages/adminManagement/scheduleManagement/homework/ScheduleHomeworkCreatePage'),
);
const AdminScheduleLessonCreatePage = lazy(
  () => import('@/pages/adminManagement/scheduleManagement/lesson/ScheduleLessonCreatePage'),
);

/**
 * Client Pages
 */
const ClientScheduleListingPage = lazy(() => import('@/pages/clientManagement/scheduleManagement/ScheduleListingPage'));
const ClientHomePage = lazy(() => import('@/pages/clientManagement/dashboardManagement'));
const ClientClassRosterListPage = lazy(
  () => import('@/pages/clientManagement/classRosterManagement/ClassRosterListingPage'),
);
const ClientProgramsPage = lazy(() => import('@/pages/clientManagement/programManagement/LibraryListingPage'));
const ClientLearningPage = lazy(() => import('@/pages/clientManagement/learningManagement/LearningPage'));
const ClientPaymentPage = lazy(() => import('@/pages/clientManagement/paymentManagement/PaymentPage'));

/**
 * Error Pages
 */
const PermissionDenied = lazy(() => import('@/pages/errorManagement/PermissionDenied'));
const CheckPayment = lazy(() => import('@/pages/errorManagement/CheckPayment'));

type RouteString = {
  [key: string]: string;
};

export const Pages = {
  Home,
  Login,
  Register,
  Logout,
  ForgotPassword,
  Profile,
  Dashboard,
  UserDashboard,
  SchoolDashboard,
  Library,
  ProgramDetail,
  ClassRosterListDashboard,
  PermissionDenied,
  ClientHomePage,
  AdminScheduleListingPage,
  ClientClassRosterListPage,
  AdminScheduleHomeworkCreatePage,
  AdminScheduleLessonCreatePage,
  ClientProgramsPage,
  ClientScheduleListingPage,
  ClientLearningPage,
  CheckPayment,
  ClientPaymentPage,
  BusinessSelectionPage,
  SupportPage,
  /**
   * TODO will change the approach for this header layout
   */
  // ManageDashboardHeader,
  // ManagerUserHeader,
  // ManageSchoolHeader,
  // ClassRosterListHeader,
  // AdminScheduleListLayoutHeader,
  // AdminScheduleHomeworkHeader,
  // AdminScheduleLessonHeader,
};

const RoutesString: RouteString = {
  Home: '/',
  Login: '/login',
  Register: '/register',
  Logout: '/logout',
  ForgotPassword: '/forgot-password',
  Profile: '/profiles',
  Dashboard: '/dashboard',
  UserDashboard: '/dashboard/users',
  SchoolDashboard: '/dashboard/schools',
  Library: '/dashboard/library',
  ProgramDetail: '/dashboard/program/:programId',
  ClassRosterListDashboard: '/dashboard/class-roster',
  PermissionDenied: '/permission-denied',
  ClientHome: '/client/home',
  AdminScheduleListingPage: '/dashboard/schedule',
  ClientScheduleListingPage: '/client/schedule',
  ClientClassRosterList: '/client/class-roster',
  AdminScheduleHomeworkCreatePage: '/dashboard/schedule/homework/create',
  AdminScheduleLessonCreatePage: '/dashboard/schedule/lesson/create',
  ClientPrograms: '/client/programs',
  ClientProgramDetail: '/client/program/:programId',
  ClientLearningRoute: '/client/learning',
  AdminLearningRoute: '/dashboard/learning',
  CheckPayment: '/check-payment',
  ClientPaymentPage: '/payment',
  BusinessSelection: '/business-selection',
  Support: '/support',
};

export const RoutesStringWithoutDynamicKey = {
  ProgramDetail: '/dashboard/program/',
  ClientProgramDetail: '/client/program/',
};

export default RoutesString;
