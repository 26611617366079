export const styles = {
  list: {
    minWidth: '265px',
    '.link': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },

  containerItemMenu: {
    padding: '4px 12px',
  },
};
