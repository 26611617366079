import { colors, fontWeight } from '@/styles/theme';

export const styles = {
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '.close': {
      cursor: 'pointer',
      position: 'absolute',
      right: 0,
      top: 0,
      margin: '16px 16px 0 0',
    },
  },
  dialogContent: {
    textAlign: 'center',
  },
  title: {
    fontSize: '18px',
    fontWeight: fontWeight.medium,
    color: colors.secondary1,
  },
  description: {
    fontSize: '15px',
    color: colors.black3,
  },
  dialogActions: {
    padding: '10px 30px 30px 30px',
  },
  buttonStack: {
    flexGrow: 1,
    gap: '15px',
    '.normal-text': {
      textTransform: 'capitalize',
    },
    '.uppercase': {
      textTransform: 'uppercase',
    },
  },

  textUppercase: {
    textTransform: 'uppercase',
  },
  icon: {
    maxWidth: '40px',
  },
};
