import { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import RoutesString from '../routesString';
import useAuthManagement from '@/store/authManagement/AuthManagement';
import { navigateDefault } from '@/utils/commonUtil';
import useProfileManagement from '@/store/profileManagement/ProfileManagement';
import { ZERO as CURRENT_INDEX_ROUTE } from '@/constants';

const ErrorsPage: FC = () => {
  const intl = useIntl();
  const [authMgtState] = useAuthManagement();
  const [profileMgtState] = useProfileManagement();
  const { activeUser } = profileMgtState ?? {};
  const { token } = authMgtState;
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (token) {
      activeUser && navigateDefault(activeUser, navigate);
      // Navigates to the current route, triggering a re-render
      navigate(CURRENT_INDEX_ROUTE);
      return;
    }

    navigate(RoutesString.Home);
    // Navigates to the current route, triggering a re-render
    navigate(CURRENT_INDEX_ROUTE);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Typography variant="h3">
        {intl.formatMessage({
          id: 'common.errors.somethingWentWrong',
        })}
      </Typography>
      <Box mt="20px" />

      <Button variant="contained" onClick={handleGoBack}>
        {intl.formatMessage({
          id: 'common.button.goBack',
        })}
      </Button>
    </Box>
  );
};

export default ErrorsPage;
