import { Ref, forwardRef } from 'react';
import { Box } from '@mui/material';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';
import { useIntl } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';
import { FieldPath, FieldValues } from 'react-hook-form';
import get from 'lodash/get';

import StyledTextField from '@/components/styledTextField';
import { TTimePicker } from './TimePicker';

import { hoursMinsFormat } from '@/constants/datetime';

import { styles } from './styles';

const TimePicker = forwardRef(
  <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    {
      maxTime,
      minTime,
      timeFormat = hoursMinsFormat,
      placeholder,
      label,
      error,
      hideError,
      onBlur,
      onChange,
      ...datePickerProps
    }: TTimePicker<TFieldValues, TName>,
    ref: Ref<HTMLInputElement>,
  ) => {
    const intl = useIntl();
    const { value } = datePickerProps;

    const handleOnChange = (timeValue: Dayjs | null) => {
      const time = dayjs(timeValue).format(timeFormat);
      onChange(time);
    };

    const errorMsg = get(error, 'message');

    return (
      <Box sx={styles.fieldContainer}>
        <MuiTimePicker
          {...datePickerProps}
          inputRef={ref}
          ampm={false}
          label={intl.formatMessage({
            id: label,
          })}
          value={value ? dayjs(value, timeFormat) : null}
          renderInput={(renderProps) => {
            const { inputProps = {} } = renderProps;

            return (
              <StyledTextField
                sx={value ? styles.enteredTextField : null}
                {...renderProps}
                inputProps={{ ...inputProps, type: 'text' }}
                onBlur={onBlur}
                placeholder={intl.formatMessage({
                  id: placeholder,
                })}
              />
            );
          }}
          maxTime={dayjs(maxTime, timeFormat)}
          minTime={dayjs(minTime, timeFormat)}
          PopperProps={{
            placement: 'bottom-end',
          }}
          onChange={handleOnChange}
        />
        {errorMsg && !hideError && <span className="errorMsg">{errorMsg}</span>}
      </Box>
    );
  },
);

export default TimePicker;
