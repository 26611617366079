import { useQuery } from '@tanstack/react-query';

import api from '@/apiCustomize';
import config from '@/config';
import { Organization } from '@/types/Organization';
import { API_ENTITY } from '@/constants';
import { ZERO_CACHE_TIME } from '@/constants/timers';
import useAuthManagement from '@/store/authManagement/AuthManagement';

const getProfiles = async (authToken: string) => {
  type OrganizationsResponse = {
    organizations: Organization[];
  };

  const response = await api({
    url: `${config.API.PROFILES_ENDPOINT}`,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

  return response.data.data as OrganizationsResponse;
};

const useProfilesQuery = (authToken: string) => {
  const [authMgtState] = useAuthManagement();
  const { phoneNumber } = authMgtState;
  return useQuery([API_ENTITY.PROFILES, phoneNumber], () => getProfiles(authToken), {
    enabled: false,
    cacheTime: ZERO_CACHE_TIME,
  });
};

export default useProfilesQuery;
