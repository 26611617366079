import { styled } from '@mui/system';
import { colors } from '@/styles/theme';

type SvgProps = {
  src: any;
  myColor?: string | null;
};

export const CustomSvg = styled('svg')<SvgProps>(({ myColor, src }) => ({
  width: '20px',
  height: '20px',
  backgroundColor: myColor ?? colors.black1,
  mask: `url(${src}) no-repeat center`,
  '&.openSubMenu': {
    backgroundColor: colors.secondary1,
  },
}));

export const styles = {
  itemIcon: {
    minWidth: 0,
  },
  itemButton: {
    position: 'relative',
    gap: '10px',
    borderRadius: '8px',
    '&.MuiListItemButton-root': {
      ':hover': {
        color: colors.secondary1,
        backgroundColor: colors.secondaryLight3,
      },
    },
    '&.MuiListItemButton-root.openSubMenu': {
      color: colors.secondary1,
      backgroundColor: colors.secondaryLight3,
    },

    '& .incoming-item': {
      position: 'absolute',
      right: '15px',
      top: '50%',
      fontStyle: 'italic',
      transform: 'translate(-5px, -50%)',
    },

    '&.disable': {
      color: colors.black5,
      pointerEvents: 'none',
    },
  },

  staightLine: {
    position: 'relative',
    '&::before': {
      content: '" "',
      position: 'absolute',
      left: '22px',
      width: '1px',
      height: '100%',
      background: colors.black4,
    },
  },

  boxChild: {
    padding: '4px 0 4px 35px',
  },
  notify: {
    color: colors.secondary1,
    '.MuiBadge-badge': {
      backgroundColor: colors.secondaryLight3,
    },
  },
};
