import axios, { AxiosError } from 'axios';
import { defaultRegistry } from 'react-sweet-state';
import get from 'lodash/get';

import API from '@/apiCustomize';
import ApiConfig from '@/config';
import { ERROR_CODE } from '@/constants/apiCode';
import RoutesString from '@/pages/routesString';
import { isChangeUserRole } from '@/utils/commonUtil';
import { Store } from '@/store/apiErrorManagement/APIErrorManagement';
import { Store as AuthStore } from '@/store/authManagement/AuthManagement';
import { Store as ProfileStore } from '@/store/profileManagement/ProfileManagement';

export const getProfiles = async (token: string) => {
  const response = await API({
    url: ApiConfig.API.PROFILES_ENDPOINT,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

axios.interceptors.request.use(
  async (config) => {
    // ignore login request
    const ignoreTokenRequest = [ApiConfig.API.AUTH_ENDPOINT];
    const configURL = get(config, 'url') ?? '';
    if (ignoreTokenRequest.includes(configURL)) {
      return config;
    }

    const customAuthorization = get(config, 'headers.Authorization');
    const newConfig = { ...config };

    if (newConfig.headers) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      newConfig.headers.timezone = timezone;

      const authStore = defaultRegistry.getStore(AuthStore);
      const { transferToken } = authStore.storeState.getState() ?? {};
      if (transferToken && !customAuthorization) {
        newConfig.headers.Authorization = `Bearer ${transferToken}`;
      }
    }

    return newConfig;
  },
  (error) => {
    return Promise.reject(error);
  },
);

/**
 * Will enhance incase handle Refresh token, reject request, etc
 */
axios.interceptors.response.use(
  (res) => res,
  async (error: AxiosError) => {
    const logger = get(error, 'response.data') ?? {};
    const code = get(logger, 'code') ?? null;
    const msg = get(logger, 'msg') ?? null;
    console.warn('code', code);
    console.warn('msg', msg);

    if (error.response?.status === ERROR_CODE.PERMISSION_DENIED) {
      const apiErrorStore = defaultRegistry.getStore(Store);
      const payload = {
        code,
        msg,
        statusCode: ERROR_CODE.PERMISSION_DENIED,
      };
      await apiErrorStore.actions.setAPIError(payload);

      const authStore = defaultRegistry.getStore(AuthStore);
      const { token } = authStore.storeState.getState() ?? {};
      const response = await getProfiles(token);
      const organizations = get(response, 'data.data.organizations');

      const profileStore = defaultRegistry.getStore(ProfileStore);
      const { organizations: activeOrganizations } = profileStore.storeState.getState() ?? {};
      profileStore.actions.setProfileInfo({ organizations });

      const result = isChangeUserRole(activeOrganizations, organizations);
      if (result) {
        window.location.href = RoutesString.Profile;
      }
    }
    return Promise.reject(error);
  },
);
