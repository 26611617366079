import { createContainer, createHook, createStore, StoreActionApi } from 'react-sweet-state';
import get from 'lodash/get';

import { AUTH_STATUS_STATE, OTP_STATUS, OTP_STEP } from '@/constants/enums';
import { authClient } from '@/api/auth';
import { FIRE_BASE_ERROR_CODE, SUCCESS_CODE } from '@/constants/apiCode';
import { IAuthManagement } from './AuthManagement.d';

type StoreApi = StoreActionApi<IAuthManagement>;

export const AUTH_MANAGEMENT_STORE = 'StoreAuthManagement';

const initAuthInfo = {
  status: AUTH_STATUS_STATE.IDLE,
  error: undefined,
};

const initCheckPhoneState = {
  ...initAuthInfo,
  existed: undefined,
};

const initAuthState = {
  phoneNumber: '',
  token: '',
  transferToken: '',
};

const initOtpState = {
  step: OTP_STEP.REQUEST,
  status: OTP_STATUS.IDLE,
  error: '',
};

export const initialState: IAuthManagement = {
  ...initCheckPhoneState,
  ...initAuthState,
  otpState: initOtpState,
  initiated: false,
};

const successInfo = {
  status: AUTH_STATUS_STATE.SUCCEEDED,
  error: undefined,
};

const renderErrorInfo = (errorCode: number) => {
  let errorInfo = {
    messageId: '',
    defaultMessage: '',
  };

  switch (errorCode) {
    case FIRE_BASE_ERROR_CODE.LOGIN_FAILED:
      errorInfo = {
        ...errorInfo,
        messageId: 'auth.login.loginFailed',
      };
      break;
    case FIRE_BASE_ERROR_CODE.CREATE_TOKEN_FAILED:
      errorInfo = {
        ...errorInfo,
        messageId: 'auth.register.createTokenFailed',
      };
      break;
    case FIRE_BASE_ERROR_CODE.CHECK_PHONE_FAILED:
      errorInfo = {
        ...errorInfo,
        messageId: 'auth.register.checkPhoneFailed',
      };
      break;
    case FIRE_BASE_ERROR_CODE.REGISTER_FAILED:
      errorInfo = {
        ...errorInfo,
        messageId: 'auth.register.registerFailed',
      };
      break;
    case FIRE_BASE_ERROR_CODE.CHECK_ID_TOKEN_FAILED:
      errorInfo = {
        ...errorInfo,
        messageId: 'auth.register.checkIdTokenFailed',
      };
      break;
    case FIRE_BASE_ERROR_CODE.ID_TOKEN_NOT_OF_PHONE:
      errorInfo = {
        ...errorInfo,
        messageId: 'auth.register.idTokenNotOfPhone',
      };
      break;
    case FIRE_BASE_ERROR_CODE.PHONE_NOT_EXISTED:
      errorInfo = {
        ...errorInfo,
        messageId: 'auth.register.phoneNotExisted',
      };
      break;
    case FIRE_BASE_ERROR_CODE.CHANGE_PASSWORD_FAILED:
      errorInfo = {
        ...errorInfo,
        messageId: 'auth.forgotPassword.changePasswordFailed',
      };
      break;
    default:
      break;
  }

  return errorInfo;
};

export const actions = {
  setAuthToken:
    (token: string) =>
    ({ setState, getState }: StoreApi) => {
      setState({
        ...getState(),
        token,
      });
    },
  setTransferToken:
    (transferToken: string) =>
    async ({ setState, getState }: StoreApi) => {
      setState({
        ...getState(),
        transferToken,
      });
    },
  setPhoneNumber:
    (phoneNumber: string) =>
    async ({ setState, getState }: StoreApi) => {
      setState({
        ...getState(),
        phoneNumber,
      });
    },
  checkPhone:
    (payload: any) =>
    async ({ setState, getState }: StoreApi) => {
      const response = await authClient.checkPhone(payload);
      if (response) {
        setState({
          ...getState(),
          ...successInfo,
          existed: get(response, 'data.existed'),
        });
      }

      return response;
    },
  login:
    (payload: any) =>
    async ({ setState, getState }: StoreApi) => {
      const response = await authClient.loginWithPhone(payload);
      const { code, data } = response;
      const { token } = data ?? {};

      if (code === SUCCESS_CODE.OK && token) {
        setState({
          ...getState(),
          ...successInfo,
          token,
        });
      } else {
        setState({
          ...getState(),
          status: AUTH_STATUS_STATE.FAILED,
          error: { ...renderErrorInfo(code) },
        });
      }

      return response;
    },

  requestOTP:
    (payload: any) =>
    ({ setState, getState }: StoreApi) => {
      setState({
        ...getState(),
        error: undefined,
        otpState: {
          ...getState().otpState,
          ...payload,
        },
      });
    },
  resetOTP:
    () =>
    ({ setState, getState }: StoreApi) => {
      setState({
        ...getState(),
        otpState: initOtpState,
      });
    },
  reset:
    () =>
    ({ setState }: StoreApi) => {
      setState(initialState);
    },
};

type Actions = typeof actions;

type StoreContainerProps = {
  initialState: IAuthManagement;
};

const getCurrentAuthManagementStore = (state: IAuthManagement) => state;

export const Store = createStore({
  initialState,
  actions,
  name: AUTH_MANAGEMENT_STORE,
});

const useAuthManagement = createHook(Store);

export const storeKey = `${Store.key}@__global__`;

export const useAuthManagementData = createHook(Store, {
  selector: getCurrentAuthManagementStore,
});

export const AuthManagementContainer = createContainer<IAuthManagement, Actions, StoreContainerProps>(Store, {
  onInit:
    () =>
    ({ setState }: StoreApi, { initialState: customInitialState }) => {
      setState({ ...customInitialState });
    },
});

export default useAuthManagement;
