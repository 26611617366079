import { ERROR_CODE } from '@/constants/apiCode';

export const errors = [
  {
    errorCode: ERROR_CODE.GENERATE_H5P_FAIL,
    strErrorCode: 'common.error.10008',
  },
  {
    errorCode: ERROR_CODE.USER_NOT_FOUND,
    strErrorCode: 'common.error.10207',
  },
  {
    errorCode: ERROR_CODE.GET_USER_FAIL,
    strErrorCode: 'common.error.10213',
  },
  {
    errorCode: ERROR_CODE.GET_CLASS_FAIL,
    strErrorCode: 'common.error.10902',
  },
  {
    errorCode: ERROR_CODE.CLASS_NOT_FAIL,
    strErrorCode: 'common.error.10907',
  },
  {
    errorCode: ERROR_CODE.GET_CLASS_STUDENT_FAIL,
    strErrorCode: 'common.error.10918',
  },
  {
    errorCode: ERROR_CODE.GET_USER_ORG_SCHOOL_FAIL,
    strErrorCode: 'common.error.11001',
  },
  {
    errorCode: ERROR_CODE.GET_HOMEWORK_FAIL,
    strErrorCode: 'common.error.30104',
  },
  {
    errorCode: ERROR_CODE.HOMEWORK_NOT_BELONG_TO_CURRENT_USER,
    strErrorCode: 'common.error.30107',
  },
  {
    errorCode: ERROR_CODE.ADD_ACTIVITY_FOR_HOMEWORK_FAIL,
    strErrorCode: 'common.error.30108',
  },
  {
    errorCode: ERROR_CODE.CANNOT_START_HOMEWORK_BEFORE_ITS_START_TIME,
    strErrorCode: 'common.error.30109',
  },
  {
    errorCode: ERROR_CODE.HOMEWORK_NOT_FOUND,
    strErrorCode: 'common.error.30111',
  },
  {
    errorCode: ERROR_CODE.STUDENT_IT_NOT_ALLOWED_DO_HOMEWORK,
    strErrorCode: 'common.error.30114',
  },
  {
    errorCode: ERROR_CODE.GET_HOMEWORK_STUDENT_FAIL,
    strErrorCode: 'common.error.30115',
  },
  {
    errorCode: ERROR_CODE.SCHEDULE_NOT_FOUND,
    strErrorCode: 'common.error.40001',
  },
  {
    errorCode: ERROR_CODE.GET_SCHEDULE_FAIL,
    strErrorCode: 'common.error.40002',
  },

  {
    errorCode: ERROR_CODE.ONLY_TEACHER_DO,
    strErrorCode: 'common.error.10106',
  },
  {
    errorCode: ERROR_CODE.GET_CLASS_TEACHER_FAIL,
    strErrorCode: 'common.error.10919',
  },
  {
    errorCode: ERROR_CODE.LESSON_NOT_FOUND,
    strErrorCode: 'common.error.30014',
  },
  {
    errorCode: ERROR_CODE.GET_LESSON_DETAIL_FAIL,
    strErrorCode: 'common.error.30015',
  },

  {
    errorCode: ERROR_CODE.ADD_ACTIVITY_FOR_LESSON_FAIL,
    strErrorCode: 'common.error.30019',
  },
  {
    errorCode: ERROR_CODE.TEACHER_IS_NOT_ALLOWED_TO_TEACH_THIS_LESSON,
    strErrorCode: 'common.error.30020',
  },

  {
    errorCode: ERROR_CODE.LESSON_NOT_BELONG_TO_CURRENT_USER_OR_SCHOOL,
    strErrorCode: 'common.error.30021',
  },
  {
    errorCode: ERROR_CODE.CANNOT_START_LESSON_BEFORE_ITS_START_TIME,
    strErrorCode: 'common.error.30022',
  },
  {
    errorCode: ERROR_CODE.CANNOT_START_LESSON_WITHOUT_STUDENTS,
    strErrorCode: 'common.error.30023',
  },
  {
    errorCode: ERROR_CODE.USERS_BULK_IMPORT_FAILED,
    strErrorCode: 'dashboard.classRoster.import.error.common',
  },
  {
    errorCode: ERROR_CODE.USERS_BULK_IMPORT_FILE_ROWS_EXCEED,
    strErrorCode: 'dashboard.classRoster.import.error.fileRowsExceed',
  },
  {
    errorCode: ERROR_CODE.USERS_BULK_IMPORT_FILE_SIZE_EXCEED,
    strErrorCode: 'common.import.error.fileSizeExceed',
  },
  {
    errorCode: ERROR_CODE.USERS_BULK_IMPORT_FILE_EMPTY,
    strErrorCode: 'dashboard.classRoster.import.error.fileEmpty',
  },
  {
    errorCode: ERROR_CODE.USERS_BULK_IMPORT_INVALID_RECORDS,
    strErrorCode: 'dashboard.classRoster.import.error.invalidRecords',
  },
  {
    errorCode: ERROR_CODE.USERS_BULK_IMPORT_USER_NAME_REQUIRED,
    strErrorCode: 'dashboard.classRoster.import.error.userNameRequired',
  },
  {
    errorCode: ERROR_CODE.USERS_BULK_IMPORT_PHONE_REQUIRED,
    strErrorCode: 'dashboard.classRoster.import.error.phoneRequired',
  },
  {
    errorCode: ERROR_CODE.USERS_BULK_IMPORT_PHONE_INVALID,
    strErrorCode: 'dashboard.classRoster.import.error.phoneInvalid',
  },
  {
    errorCode: ERROR_CODE.USERS_BULK_IMPORT_ROLE_REQUIRED,
    strErrorCode: 'dashboard.classRoster.import.error.roleRequired',
  },
  {
    errorCode: ERROR_CODE.USERS_BULK_IMPORT_ROLE_ONLY_TEACHER_STUDENT,
    strErrorCode: 'dashboard.classRoster.import.error.roleOnlyTeacherStudent',
  },
  {
    errorCode: ERROR_CODE.USERS_BULK_IMPORT_DATE_OF_BIRTH_INVALID,
    strErrorCode: 'dashboard.classRoster.import.error.dateOfBirthInvalid',
  },
  {
    errorCode: ERROR_CODE.USERS_BULK_IMPORT_EMAIL_INVALID,
    strErrorCode: 'dashboard.classRoster.import.error.emailInvalid',
  },
  {
    errorCode: ERROR_CODE.USERS_BULK_IMPORT_GENDER_INVALID,
    strErrorCode: 'dashboard.classRoster.import.error.genderInvalid',
  },
  {
    errorCode: ERROR_CODE.USERS_BULK_IMPORT_DUPLICATE_FROM_FILE,
    strErrorCode: 'dashboard.classRoster.import.error.duplicateFromFile',
  },
  {
    errorCode: ERROR_CODE.USERS_BULK_IMPORT_USER_ALREADY_EXIST,
    strErrorCode: 'dashboard.classRoster.import.error.userAlreadyExist',
  },
  {
    errorCode: ERROR_CODE.USERS_BULK_IMPORT_USER_ALREADY_EXIST,
    strErrorCode: 'dashboard.classRoster.import.error.userAlreadyExist',
  },
  {
    errorCode: ERROR_CODE.SCHEDULES_BULK_CREATE_DUPLICATE_COURSE,
    strErrorCode: 'dashboard.bulk.schedule.bulkCreate.error.duplicatedSchedule',
  },
];
