import { useMutation } from '@tanstack/react-query';

import api from '@/apiCustomize';
import config from '@/config';
import { POST_METHOD } from '@/apiCustomize/api.constants';

import { IBulkUploadUser } from '@/modules/classRoster/classRosterListing/classRosterImport/ClassRosterImport.d';

interface TBulkUploadUsersDataResponse {
  fileName: string;
  results: IBulkUploadUser[];
}

const verifyBulkUploadUsers = async (data: FormData) => {
  const response = await api({
    url: config.API.USER_BULK_UPLOAD_VERIFY_ENDPOINT,
    method: POST_METHOD,
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data.data as TBulkUploadUsersDataResponse;
};

const importBulkUploadUsers = async (data: FormData) => {
  const response = await api({
    url: config.API.USER_BULK_UPLOAD_IMPORT_ENDPOINT,
    method: POST_METHOD,
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data.data as null;
};

const useVerifyBulkUploadUsers = () => useMutation((data: FormData) => verifyBulkUploadUsers(data));
const useImportBulkUploadUsers = () => useMutation((data: FormData) => importBulkUploadUsers(data));

export { useVerifyBulkUploadUsers, useImportBulkUploadUsers };
