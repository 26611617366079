import { FC } from 'react';
import { Box, Container, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { SYMBOLS } from '@/constants';

import { ILessonContentDialog } from './LessonContent.d';

import arrowLeft from '@/assets/images/icons/arrow-left-size-20.svg';
import arrowRight from '@/assets/images/icons/arrow-right-size-20.svg';

import { styles } from './styles';
import { StyledCheckBox } from '@/components/styledCheckbox';
import { getUniqueObjectFromNestedArray, removeDuplicatesByField } from '@/utils/commonUtil';
import { TSelectedLesson } from '@/modules/scheduleManagement/bulkSchedule/form/BulkScheduleForm.d';
import { ILessonContentOfUnitItem } from '@/types/Dashboard';

const LessonContentDialog: FC<ILessonContentDialog> = ({
  onPrev,
  onNext,
  showPrev,
  showNext,
  lessonModuleName,
  lessons,
  width,

  selectedLessons,
  onModifyLessonList,
  unitOrdinal: unitOrdinalLabel,
  unitId,
}) => {
  const handleOnPrev = () => {
    if (onPrev) onPrev();
  };

  const handleOnNext = () => {
    if (onNext) onNext();
  };

  const selectedLessonsCurrentUnit = selectedLessons.filter((lesson) => lesson.unitId === unitId);

  const handleClickAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedLessonsInUnit = lessons.map((lesson) => {
      const formattedName = `${lessonModuleName} - ${lesson.lessonContentName}`;
      const { lessonContentName, lessonContentId } = lesson;
      return { lessonContentName, lessonContentId, unitId, unitOrdinalLabel, formattedName };
    });
    let result = [...selectedLessons, ...formattedLessonsInUnit];
    const { checked } = event.target;

    if (!checked) {
      const uniqueLessons: TSelectedLesson[] = result.filter((lesson) => lesson.unitId !== unitId);
      result = uniqueLessons;
    }

    const finalResult: TSelectedLesson[] = removeDuplicatesByField(result, 'lessonContentId');
    onModifyLessonList(finalResult);
  };

  const handleOnCheck = (event: React.ChangeEvent<HTMLInputElement>, lessonIn: ILessonContentOfUnitItem) => {
    const { lessonContentName, lessonContentId } = lessonIn;
    const formattedName = `${lessonModuleName} - ${lessonContentName}`;
    const formattedChosenLesson = {
      lessonContentId,
      unitId,
      unitOrdinalLabel,
      formattedName,
    };
    let result = [...selectedLessons, formattedChosenLesson];
    const { checked } = event.target;
    if (!checked) {
      const uniqueLessons: TSelectedLesson[] = getUniqueObjectFromNestedArray(result, 'lessonContentId');
      result = uniqueLessons;
    }

    const allLessonIdsOfCurrentList = lessons.map((lesson) => lesson.lessonContentId);

    const lessonIdSet = new Set(allLessonIdsOfCurrentList);

    const orderedLessons = allLessonIdsOfCurrentList
      .map((id) => result.find((item) => item.lessonContentId === id))
      .filter((item) => item !== undefined) as TSelectedLesson[];

    const unorderedLessons = result.filter((item) => !lessonIdSet.has(item.lessonContentId));

    const fianlSortedLessonList = [...orderedLessons, ...unorderedLessons];
    onModifyLessonList(fianlSortedLessonList);
  };

  return (
    <Container sx={{ ...styles.lessonContentWrapper, width }}>
      <Grid container={true} sx={styles.headerWrapper}>
        <Grid item={true} xs={1} sx={styles.headerLeftBlock}>
          {showPrev && <Box component="img" src={arrowLeft} onClick={handleOnPrev} />}
        </Grid>
        <Grid item={true} xs={10} sx={styles.headerTitle}>
          {lessonModuleName && (
            <>
              {lessonModuleName}
              {SYMBOLS.COLON} {lessons.length} <FormattedMessage id="dashboard.bulk.schedule.lessons" />
            </>
          )}
        </Grid>
        <Grid item={true} xs={1} sx={styles.headerRightBlock}>
          {showNext && <Box component="img" src={arrowRight} onClick={handleOnNext} />}
        </Grid>
      </Grid>
      <Grid container={true}>
        <Grid display="flex" item={true} xs={10} sx={[styles.selectAllText, styles.checkboxContainer]}>
          <StyledCheckBox
            checked={Array.isArray(lessons) && lessons.length === selectedLessonsCurrentUnit.length}
            onChange={(event) => handleClickAll(event)}
          />
          <Box sx={styles.textPadding}>
            <FormattedMessage id="common.selectAll" />
          </Box>
        </Grid>
      </Grid>
      {Array.isArray(lessons) && lessons.length
        ? lessons.map((lesson) => {
            const { lessonContentName, lessonContentDuration, lessonContentId } = lesson;

            return (
              <Grid container={true} key={`lesson-item-${lessonContentName}-${lessonContentDuration}`}>
                <Grid display="flex" item={true} xs={9} sx={styles.checkboxContainer}>
                  <StyledCheckBox
                    checked={selectedLessonsCurrentUnit.some(
                      (selectedLesson) => selectedLesson.lessonContentId === lessonContentId,
                    )}
                    onChange={(event) => handleOnCheck(event, lesson)}
                  />
                  <Box sx={[styles.lessonDetailName, styles.textPadding]}>{lessonContentName}</Box>
                </Grid>
                <Grid item={true} xs={3} sx={styles.lessonDetailTime}>
                  <FormattedMessage
                    id="dashboard.bulk.schedule.duration_with_unit"
                    values={{
                      duration: lessonContentDuration,
                      timeUnit: <FormattedMessage id="time.minutes" />,
                    }}
                  />
                </Grid>
              </Grid>
            );
          })
        : null}
    </Container>
  );
};

export default LessonContentDialog;
