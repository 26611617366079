import { FC, useEffect, useRef, useState, SyntheticEvent } from 'react';
import { TextField, Autocomplete, CircularProgress, Box } from '@mui/material';
import get from 'lodash/get';
import { useIntl } from 'react-intl';

import LookupFieldItem from '../LookupFieldItems';
import { LookupFieldLoadMore } from '../LookupFieldComponent';

import API from '@/apiCustomize';
import { GET_METHOD } from '@/apiCustomize/api.constants';
import { FIRST_ITEM_NUMBER, PAGINATION, ZERO } from '@/constants';
import { DEBOUNCE_INPUT_TIME } from '@/constants/timers';
import { renderLookupFieldOption } from '@/utils/commonUtil';
import { defaultLoadMoreProps } from '../constants';

import { ILookupFieldPagination, ISingleLookupFieldWrapper } from '../LookupField.d';
import { Params } from '@/constants/types';
import { globalStyles } from '@/styles/theme';

import { styles } from '../styles';

const SingleLookupField: FC<ISingleLookupFieldWrapper> = ({
  endpoint,
  disabled,
  defaultParams,
  dataKey,
  selectedDataKey,
  itemDataKeys,
  autoSubmitAfterTime = DEBOUNCE_INPUT_TIME,
  placeholder = 'common.placeholder',
  placeholderOptionNoData = 'common.noResultMatched',
  defaultData,
  label = '',
  enableLoadMore,
  loadMoreProps = defaultLoadMoreProps,
  searchKey = 'search',
  mappingDataFn,
  onSelectedItem,
  onReset,
  triggerEmptyValueField,
  onResetFieldToEmpty,
}) => {
  const [defaultDataState, setDefaultDataState] = useState(defaultData);
  const { pageSize } = loadMoreProps;

  const { defaultLabel, currentValue } = defaultDataState ?? {};
  const intl = useIntl();
  const typingTimeoutRef = useRef<any>(null);

  const [options, setOptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(defaultLabel);
  const [valueField, setValueField] = useState(currentValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(Number(PAGINATION.DEFAULT_CURRENT_PAGE));
  const [totalPagination, setTotalPagination] = useState<ILookupFieldPagination>({
    totalPages: ZERO,
    totalRows: ZERO,
    pageSize: ZERO,
  });

  useEffect(() => {
    if (triggerEmptyValueField) {
      setValueField(undefined);
      onResetFieldToEmpty && onResetFieldToEmpty();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerEmptyValueField]);

  const loadMoreResults = () => {
    const nextPage = currentPage + PAGINATION.STEP;
    setCurrentPage(nextPage);
  };

  const buildParams = () => {
    let params: Params = {
      ...defaultParams,
      [searchKey]: inputValue,
    };

    if (enableLoadMore) {
      params = {
        ...params,
        pageSize: String(pageSize),
        page: String(currentPage),
      };
    }

    return params;
  };

  const onFetch = async () => {
    setLoading(true);
    let response;

    if (endpoint) {
      try {
        response = await API({
          url: endpoint,
          method: GET_METHOD,
          params: buildParams(),
        });
      } catch (error) {
        console.warn(error);
      } finally {
        setLoading(false);
      }
    }

    if (response) {
      const finalData = get(response, `data.data.${dataKey}`) || [];
      const mappedData = mappingDataFn ? mappingDataFn(finalData) : [...finalData];

      if (defaultDataState) {
        // for default case
        mappedData.forEach((item: any) => {
          if (item[selectedDataKey] === get(defaultDataState, 'defaultValue')) {
            setValueField(item);
          }
        });
      }

      const pagination: ILookupFieldPagination = get(response, `data.data.pagination`);
      const { totalPages: responsePages, totalRows: responseRows, pageSize: responsePageSize } = pagination ?? {};
      if (pagination)
        setTotalPagination({ totalPages: responsePages, totalRows: responseRows, pageSize: responsePageSize });

      const newOptions = !enableLoadMore ? [...mappedData] : [...options, ...mappedData];
      setOptions(newOptions);
    }
  };

  const onResetInputField = () => {
    setOptions([]);
    setCurrentPage(Number(PAGINATION.DEFAULT_CURRENT_PAGE));
  };

  const onInputChange = (_event: SyntheticEvent<Element, Event>, newInputValue: string, reason: string) => {
    if (disabled) return;

    if (reason === 'input') {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }

      if (!get(_event, 'target.value')) {
        if (onReset) onReset();
        setInputValue('');
        setValueField('');
        onResetInputField();
        setDefaultDataState(undefined);
        return;
      }

      if (!autoSubmitAfterTime) return;

      typingTimeoutRef.current = setTimeout(() => {
        setCurrentPage(Number(PAGINATION.DEFAULT_CURRENT_PAGE));
        setOptions([]);
        setInputValue(newInputValue);
        clearTimeout(typingTimeoutRef.current);
      }, autoSubmitAfterTime);
    }
  };

  const onChange = (_event: SyntheticEvent<Element, Event>, newValue: any) => {
    if (disabled) return;

    setDefaultDataState(newValue);
    if (onSelectedItem) onSelectedItem(newValue);
    setValueField(newValue);
  };

  const onBlur = () => {
    if (valueField) {
      setInputValue(
        renderLookupFieldOption({
          option: valueField,
          itemDataKeys,
        }),
      );
    } else {
      setInputValue('');
    }
  };

  const onOpen = () => {
    setOpen(true);
    (!Array.isArray(options) || (Array.isArray(options) && !options.length)) && onFetch();
  };

  const onClose = () => {
    setOpen(false);
    onResetInputField();
  };

  useEffect(() => {
    if (endpoint && open) {
      onFetch();
    }

    // case only showing data!
    if (!endpoint) {
      setValueField({
        [`${itemDataKeys}`]: inputValue,
        [`${selectedDataKey}`]: valueField,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, currentPage]);

  useEffect(() => {
    // TO-DO: recheck why the edit mode call API twice
    if (endpoint && defaultLabel) {
      onFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={disabled ? globalStyles.disabledField : null}>
      <Autocomplete
        // add key to force re-render when value change when trigger by another field
        key={`autocomplete-${valueField}-${selectedDataKey}`}
        filterOptions={(filterOptions) => filterOptions}
        open={open}
        loading={loading}
        options={options}
        autoComplete={true}
        includeInputInList={true}
        getOptionLabel={(option) => renderLookupFieldOption({ option, itemDataKeys })}
        onInputChange={onInputChange}
        onChange={onChange}
        onBlur={onBlur}
        onOpen={onOpen}
        onClose={onClose}
        noOptionsText={intl.formatMessage({
          id: placeholderOptionNoData,
        })}
        value={valueField}
        renderInput={(params) => (
          <TextField
            {...params}
            label={intl.formatMessage({
              id: label || placeholder,
            })}
            placeholder={intl.formatMessage({
              id: placeholder,
            })}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            sx={[styles.textFieldStyle, valueField ? styles.enteredTextField : null]}
          />
        )}
        renderOption={(props, option, { selected, index }) => {
          const isLastOption = index === options.length - FIRST_ITEM_NUMBER;

          return (
            <Box key={`lookup-field-item-${dataKey}-${get(option, selectedDataKey)}-${index}`}>
              <LookupFieldItem {...props} selected={selected} option={option} itemDataKeys={itemDataKeys} />
              {isLastOption && (
                <LookupFieldLoadMore
                  pagination={totalPagination}
                  currentPage={currentPage}
                  onLoadMore={loadMoreResults}
                  disabled={loading}
                />
              )}
            </Box>
          );
        }}
        loadingText={intl.formatMessage({
          id: 'common.loading',
        })}
        ListboxProps={{
          role: 'list-box',
        }}
        getOptionDisabled={() => loading}
        clearIcon={false}
      />
    </Box>
  );
};

export default SingleLookupField;
