import { colors, fontSize, lineHeight, fontWeight } from '@/styles/theme';
import curve from '../../assets/images/curve.jpg';

export const styles = {
  errorPageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: '100vh',
    marginBottom: '24px',
  },
  errorDescription: {
    color: colors.spectrum4,
    fontSize: fontSize.textMD,
    lineHeight: lineHeight.titleXS,
    fontWeight: fontWeight.semiBold,
    marginBottom: '24px',
  },
  errorTitle: {
    color: colors.black1,
    fontSize: fontSize.titleSMD,
    fontWeight: fontWeight.bold,
    lineHeight: lineHeight.titleSMD,
    textAlign: 'center',
  },
  backToHomeBtn: {
    padding: '10px 24px',
    gap: '6px',
    borderRadius: '24px',
    boxShadow: '0 2px 4px -4px rgba(0, 0, 0, 0.2)',
  },
  checkPaymentContainer: {
    backgroundImage: `url(${curve})`,
    backgroundSize: '100% 100%',
  },
  checkPaymentRightBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    img: {
      width: '70%',
    },
  },
};
