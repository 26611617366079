import dayjs from 'dayjs';
import { FIRST_ITEM_NUMBER, SECOND_ITEM_NUMBER, ZERO } from '@/constants';

import { IBulkScheduleDaysOfWeek, IBulkScheduleValues } from './form/BulkScheduleForm.d';

export const defaultSelectedProgram = {
  programId: '',
  programName: '',
  ageGroupId: ZERO,
  units: [],
  lessonsContent: [],
};

export const bulkScheduleSteps = {
  steps: [
    {
      key: 'overview-step',
      label: 'dashboard.bulk.schedule.overview',
    },
    {
      key: 'schedule-step',
      label: 'dashboard.bulk.schedule.schedule',
    },
    {
      key: 'setting-step',
      label: 'dashboard.bulk.schedule.setting',
    },
  ],
  currentStep: ZERO,
  disableClickNextStep: true,
};

export const DEFAULT_HOMEWORK_DAYS = 7;

export const defaultDayOfWeek: IBulkScheduleDaysOfWeek = {
  startTime: '',
  endTime: '',
  date: dayjs(),
  isAlwaysSelected: false,
};

export const defaultDaysOfWeek = Array.from({ length: DEFAULT_HOMEWORK_DAYS }, () => defaultDayOfWeek);

export const SCHEDULE_STEP_TAB_KEY = {
  NEXT_WEEK: 'next-week',
  CUSTOMIZE: 'customize',
  TODAY: 'today',
};

export const BULK_SCHEDULE_STEP = {
  OVERVIEW: ZERO,
  SCHEDULE: FIRST_ITEM_NUMBER,
  SETTING: SECOND_ITEM_NUMBER,
};

export const defaultBulkScheduleValues: IBulkScheduleValues = {
  courseName: '',
  classId: '',
  programId: '',
  ageGroupId: ZERO,
  units: [],
  startDate: '',
  daysOfWeek: [],
  globalTime: {
    enable: true,
    startTime: '',
    endTime: '',
  },
  attachHomework: {
    enable: false,
    durationDay: DEFAULT_HOMEWORK_DAYS,
  },
  includeNationalHoliday: false,
  className: '',
  programName: '',
  schoolId: ZERO,
  scheduleTabId: SCHEDULE_STEP_TAB_KEY.NEXT_WEEK,
  forceCreate: false,
};

export const splitAgeGroupIdFromProgram = (values: string | number, valueToSplit: number): string => {
  const valuesStr = values.toString();
  const valueToSplitStr = valueToSplit.toString();

  const lengthValueToSplit = valueToSplitStr.length;

  const result = valuesStr.slice(ZERO, -lengthValueToSplit);
  return result;
};
