import { useQuery } from '@tanstack/react-query';

import { ZERO_CACHE_TIME } from '@/constants/timers';
import config from '@/config';
import api from '@/apiCustomize';
import { API_ENTITY } from '@/constants';
import { ILessonContentOfUnit } from '@/types/Dashboard';

const getLessonContent = async (ageGroupId: number, lessonModuleId: number) => {
  const response = await api({
    url: `${config.API.LESSON_MODULES_ENDPOINT}/${lessonModuleId}?ageGroupId=${ageGroupId}`,
  });

  return response.data.data as ILessonContentOfUnit;
};

const useLessonContentOfUnit = (ageGroupId: number, lessonModuleId: number) => {
  return useQuery(
    [API_ENTITY.PROGRAMS, ageGroupId, lessonModuleId],
    () => getLessonContent(ageGroupId, lessonModuleId),
    {
      enabled: !!(ageGroupId || lessonModuleId),
      cacheTime: ZERO_CACHE_TIME,
    },
  );
};

export default useLessonContentOfUnit;
