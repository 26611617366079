import { FC, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import get from 'lodash/get';

import StyledTextField from '@/components/styledTextField';
import SingleLookupField from '@/components/lookupField/singleLookupField';

import config from '@/config';
import { API_ENTITY, KEY_PROPERTY_PARAMS } from '@/constants';
import { SORT_STATUS } from '@/constants/enums';

import { IClassRoster } from '@/types/Class.d';
import { IOverviewStep, ISelectedProgram } from './BulkScheduleForm.d';

import { styles } from './styles';

const OverviewStep: FC<IOverviewStep> = ({
  control,
  errors,
  watch,
  setSelectedProgram,
  setShowLessonBlock,
  setValue,
  onModifyLessonList,
}) => {
  const intl = useIntl();
  const watchSchoolId = watch('schoolId');
  const watchClassId = watch('classId');
  const watchClassName = watch('className');
  const defaultClassOptions = watchClassId
    ? {
        classId: watchClassId,
        className: watchClassName,
      }
    : undefined;
  const watchProgramId = watch('programId');
  const watchProgramName = watch('programName');

  const [triggerEmptyValProgram, setTriggerEmptyValProgram] = useState(false);

  const handleShowLessonBlock = (isShow: boolean) => {
    setShowLessonBlock && setShowLessonBlock(isShow);
  };

  const handleOnSelectedClass = (selectedClass: IClassRoster) => {
    const { classId, schoolId, className } = selectedClass ?? {};
    setValue('classId', classId);
    setValue('className', className);
    setValue('schoolId', schoolId);

    // TODO: trigger to clear program by change key of single lookup component to rerender to clear default field
    setTriggerEmptyValProgram(true);
    setValue('programId', '');
    handleShowLessonBlock(false);
  };

  const handleOnSelectedProgram = (selectedProgram: ISelectedProgram) => {
    const { units, programName, programId, ageGroupId } = selectedProgram ?? {};

    const currentUnitLessons = Array.isArray(units)
      ? units.flatMap((unit) => {
          const unitLessons = get(unit, 'lessonContents');
          const unitId = get(unit, 'lessonModuleId');
          const unitOrdinalLabel = get(unit, 'lessonModuleOrdinalLabel');
          const unitName = get(unit, 'lessonModuleName');
          if (unitLessons)
            return unitLessons.map((lesson) => {
              const { lessonContentId, lessonContentName } = lesson;
              const formattedName = `${unitName} - ${lessonContentName}`;

              return { lessonContentId, unitId, unitOrdinalLabel, formattedName };
            });
          return [];
        })
      : [];

    setValue('programId', programId);
    setValue('programName', programName);
    setValue('ageGroupId', ageGroupId);
    onModifyLessonList(currentUnitLessons);

    if (!programId) return handleShowLessonBlock(false);
    if (setSelectedProgram) setSelectedProgram({ programName, units, programId, ageGroupId });
    handleShowLessonBlock(true);
  };

  const mapToGetUniqueKeyProgram = (dataResponse: any) => {
    return dataResponse.map((programItem: any) => ({
      ...programItem,
      programId: Number(`${programItem.programId}${programItem.ageGroupId}`),
    }));
  };

  const onResetFieldToEmpty = () => {
    setTriggerEmptyValProgram(false);
  };

  return (
    <>
      <Controller
        name="courseName"
        control={control}
        rules={{
          required: intl.formatMessage({
            id: 'dashboard.bulk.schedule.courseName.required',
          }),
        }}
        render={({ field }) => {
          const courseNameErrMsg = get(errors, 'courseName.message');

          return (
            <Box sx={{ ...styles.fieldContainer, ...styles.fieldContainerWithBackground }}>
              <StyledTextField
                label={intl.formatMessage({
                  id: 'dashboard.bulk.schedule.courseName',
                })}
                placeholder={intl.formatMessage({
                  id: 'dashboard.bulk.schedule.courseName.placeholder',
                })}
                {...field}
              />
              {courseNameErrMsg && <span className="errorMsg">{courseNameErrMsg}</span>}
            </Box>
          );
        }}
      />

      <Stack sx={styles.formSpacing}>
        <Controller
          control={control}
          name="classId"
          rules={{
            required: intl.formatMessage({
              id: 'dashboard.bulk.schedule.class.required',
            }),
          }}
          render={({ field }) => {
            const classErrMsg = get(errors, 'classId.message');

            return (
              <Box sx={styles.fieldContainer}>
                <SingleLookupField
                  endpoint={config.API.CLASSES_ENDPOINT}
                  dataKey={API_ENTITY.CLASSES}
                  selectedDataKey="classId"
                  itemDataKeys={['className']}
                  label="dashboard.bulk.schedule.class"
                  placeholder="dashboard.bulk.schedule.class.placeholder"
                  enableLoadMore={true}
                  defaultParams={{
                    sort: KEY_PROPERTY_PARAMS.NAME,
                    sortOrder: SORT_STATUS.ASC,
                  }}
                  onSelectedItem={handleOnSelectedClass}
                  defaultOptions={[defaultClassOptions]}
                  defaultData={
                    watchClassId
                      ? {
                          defaultLabel: watchClassName ?? '',
                          defaultValue: watchClassId,
                          currentValue: field.value,
                        }
                      : undefined
                  }
                />
                {classErrMsg && <span className="errorMsg">{classErrMsg}</span>}
              </Box>
            );
          }}
        />

        <Controller
          control={control}
          name="programId"
          rules={{
            required: intl.formatMessage({
              id: 'dashboard.bulk.schedule.program.required',
            }),
          }}
          render={({ field }) => {
            const programErrMsg = get(errors, 'programId.message');

            return (
              <Box sx={styles.fieldContainer}>
                <SingleLookupField
                  endpoint={config.API.LESSON_MODULES_ENDPOINT}
                  dataKey={API_ENTITY.PROGRAMS}
                  selectedDataKey="programId"
                  itemDataKeys={['programName']}
                  label="dashboard.bulk.schedule.program"
                  placeholder="dashboard.bulk.schedule.program.placeholder"
                  enableLoadMore={true}
                  defaultParams={{
                    sort: KEY_PROPERTY_PARAMS.NAME,
                    sortOrder: SORT_STATUS.ASC,
                    schoolId: `${watchSchoolId}`,
                  }}
                  onSelectedItem={handleOnSelectedProgram}
                  mappingDataFn={mapToGetUniqueKeyProgram}
                  disabled={!watchClassId && !watchSchoolId}
                  defaultData={
                    watchProgramId
                      ? {
                          defaultLabel: watchProgramName ?? '',
                          defaultValue: watchProgramId,
                          currentValue: field.value,
                        }
                      : undefined
                  }
                  triggerEmptyValueField={triggerEmptyValProgram}
                  onResetFieldToEmpty={onResetFieldToEmpty}
                />
                {programErrMsg && <span className="errorMsg">{programErrMsg}</span>}
              </Box>
            );
          }}
        />
      </Stack>
    </>
  );
};

export default OverviewStep;
