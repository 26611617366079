import { useEffect } from 'react';

export const usePreventDownloadMedia = (deps?: any) => {
  useEffect(() => {
    const allVideo = document.querySelectorAll('video');
    const allImages = document.querySelectorAll('img');

    [...allVideo, ...allImages].forEach((node) => {
      node.oncontextmenu = (event) => {
        event.preventDefault();
      };
    });

    return () => {
      [...allVideo, ...allImages].forEach((node) => {
        node.oncontextmenu = null;
      });
    };
  }, [deps]);
};
