import { FC, useState } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle, Box } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import get from 'lodash/get';

import { FormButton } from '@/components/formButton';
import { CustomDialog } from '@/components/customDialog/CustomDialog';
import Dropzone from '@/components/dropzone/Dropzone';
import ClassRosterImportTable from '../classRosterImportTable/ClassRosterImportClassTable';
import ClassRosterConfirmImportDialog from './ClassRosterConfirmImportDialog';

import { mappingUsersIntoClasses } from '../classRosterImportTable/utils';
import { useImportBulkUploadUsers, useVerifyBulkUploadUsers } from '@/utils/hooks/dashboard/useBulkUploadUsers';
import { EFileExtension } from '@/constants/enums';
import { useToast } from '@/modules/dashboard/commonElementsManagement/providers/ToastProvider';
import { mapErrorCode } from '@/utils/commonUtil';
import { MAX_CLASS_ROSTER_IMPORT_FILE_SIZE } from './constants';
import { API_ENTITY, DEFAULT_COMMON_LISTING_STORE_NAME } from '@/constants';
import useCommonListingStore from '@/store/CommonListingStore/CommonListingStore';
import useCommonListPage from '@/utils/hooks/dashboard/useQueryCommonList';
import config from '@/config';

import { EClassType } from '../classRosterImportTable/enums';
import { TClassRosterImportProps } from './ClassRosterImport.d';

import { styles } from './styles';

const ClassRosterImportDialog: FC<TClassRosterImportProps> = ({ open, setOpen, schoolData, onClose }) => {
  const { schoolId, schoolName } = schoolData;
  const [bulkUploadUsersFormData, setBulkUploadUsersFormData] = useState<FormData | null>(null);
  const [openConfirmImportDialog, setOpenConfirmImportDialog] = useState<boolean>(false);
  const { showError, showSuccess } = useToast();
  const intl = useIntl();

  const acceptedFileTypes = [EFileExtension.CSV, EFileExtension.XLS, EFileExtension.XLSX];

  const {
    isLoading: isLoadingImportBulkUploadUsers,
    mutate: triggerImportBulkUploadUsers,
    reset: resetImportBulkUploadUsers,
  } = useImportBulkUploadUsers();

  const {
    data: dataVerifyBulkUploadUsers,
    error: errorVerifyBulkUploadUsers,
    isError: isErrorVerifyBulkUploadUsers,
    isLoading: isLoadingVerifyBulkUploadUsers,
    mutate: triggerVerifyBulkUploadUsers,
    reset: resetVerifyBulkUploadUsers,
  } = useVerifyBulkUploadUsers();

  const apiProps = {
    endpoint: config.API.CLASSES_ENDPOINT,
    dataKey: API_ENTITY.CLASSES,
  };
  const store = useCommonListingStore(DEFAULT_COMMON_LISTING_STORE_NAME);
  const [stateStore] = store();
  const { refetch } = useCommonListPage(apiProps, stateStore.params);

  const userData = get(dataVerifyBulkUploadUsers, 'results') ?? [];
  const classGroups = Array.isArray(userData) && userData.length ? mappingUsersIntoClasses(userData) : null;
  const isAnyRowError = Array.isArray(classGroups) && classGroups.some((classGroup) => classGroup.invalidRows);
  const isAllRowsMatchingClass =
    Array.isArray(classGroups) && classGroups.every((classGroup) => classGroup.classType === EClassType.MATCHING);
  const description = (
    <FormattedMessage
      id={
        isAllRowsMatchingClass
          ? 'dashboard.classRoster.importConfimation.description.rowsHaveClassName'
          : 'dashboard.classRoster.importConfimation.description.rowsNotHaveClassName'
      }
      values={{
        number: userData.length,
      }}
    />
  );

  const errorMessage = get(errorVerifyBulkUploadUsers, 'response.data.code');

  const isDisabledImport =
    !dataVerifyBulkUploadUsers ||
    isErrorVerifyBulkUploadUsers ||
    isAnyRowError ||
    isLoadingImportBulkUploadUsers ||
    isLoadingVerifyBulkUploadUsers;

  const handleCloseConfirmImportDialog = () => {
    setOpenConfirmImportDialog(false);
    resetImportBulkUploadUsers();
  };

  const handleCloseImportDialog = () => {
    setOpen(false);
    resetVerifyBulkUploadUsers();
    setBulkUploadUsersFormData(null);
    handleCloseConfirmImportDialog();
    onClose();
  };

  const handleBackToImport = () => {
    resetVerifyBulkUploadUsers();
    setBulkUploadUsersFormData(null);
    handleCloseConfirmImportDialog();
  };

  const handleImportDialog = () => {
    if (bulkUploadUsersFormData)
      return triggerImportBulkUploadUsers(bulkUploadUsersFormData, {
        onSuccess: () => {
          handleCloseImportDialog();
          showSuccess(
            intl.formatMessage({
              id: 'dashboard.classRoster.importSuccess',
            }),
          );
          refetch();
        },
        onError: (error: any) => {
          const errorCode = get(error, 'response.data.code');
          handleCloseConfirmImportDialog();
          showError(
            intl.formatMessage({
              id: mapErrorCode(errorCode),
            }),
          );
        },
      });
  };

  const handleConfirmImportDialog = () => {
    if (!isDisabledImport) setOpenConfirmImportDialog(true);
  };

  const onDrop = (formData: FormData) => {
    formData.append('schoolId', String(schoolId) || '');
    if (schoolId) {
      setBulkUploadUsersFormData(formData);
      triggerVerifyBulkUploadUsers(formData);
    }
  };

  const onFileDialogOpen = () => {
    resetVerifyBulkUploadUsers();
  };

  return (
    <>
      <CustomDialog position="middle" PaperProps={{ sx: styles.dialogWrapper }} open={open}>
        <DialogTitle sx={styles.dialogImportTitle}>
          <FormattedMessage id="dashboard.classRoster.rostersImporting" />
        </DialogTitle>
        <DialogContent sx={styles.dialogImportContent}>
          <Box>
            <DialogContentText sx={styles.dialogImportLabel}>
              <FormattedMessage id="menu.school" />
            </DialogContentText>
            <Box sx={styles.boxSchoolName}>{schoolName}</Box>
          </Box>

          <Box>
            <Box sx={styles.dialogImportTitleWrapper}>
              <DialogContentText
                sx={{ ...styles.dialogImportLabel, ...(Boolean(classGroups) && styles.dialogImportTableLabel) }}
              >
                <FormattedMessage id="dashboard.classRoster.importDropzoneLabel" />
              </DialogContentText>
              {classGroups && (
                <FormButton variant="contained" onClick={handleBackToImport}>
                  <FormattedMessage id="dashboard.classRoster.rostersReimporting" />
                </FormButton>
              )}
            </Box>

            {classGroups ? (
              <ClassRosterImportTable classGroups={classGroups} />
            ) : (
              <Dropzone
                loading={isLoadingVerifyBulkUploadUsers}
                acceptedFileTypes={acceptedFileTypes}
                field="file"
                onDrop={onDrop}
                onFileDialogOpen={onFileDialogOpen}
                errorMessage={isErrorVerifyBulkUploadUsers ? mapErrorCode(errorMessage) : ''}
                maxSize={MAX_CLASS_ROSTER_IMPORT_FILE_SIZE}
              />
            )}
          </Box>
        </DialogContent>

        <DialogActions sx={styles.buttonWrapper}>
          <FormButton variant="outlined" onClick={handleCloseImportDialog}>
            <FormattedMessage id="common.cancel" />
          </FormButton>
          <FormButton variant="contained" onClick={handleConfirmImportDialog} disabled={isDisabledImport}>
            <FormattedMessage id="common.import" />
          </FormButton>
        </DialogActions>
      </CustomDialog>
      <ClassRosterConfirmImportDialog
        description={description}
        open={openConfirmImportDialog}
        loading={isLoadingImportBulkUploadUsers}
        onClose={handleCloseConfirmImportDialog}
        onConfirm={handleImportDialog}
      />
    </>
  );
};
export default ClassRosterImportDialog;
