import { fontSize, lineHeight, fontWeight, colors } from '@/styles/theme';
import { TMuiStyle } from '@/types/Common';

export const styles: TMuiStyle = {
  lessonContentWrapper: {
    padding: '0 24px',
  },
  headerLeftBlock: {
    textAlign: 'left',

    img: {
      cursor: 'pointer',
    },
  },
  headerRightBlock: {
    textAlign: 'right',

    img: {
      cursor: 'pointer',
    },
  },
  lessonDetailName: {
    fontSize: fontSize.btnSM,
  },
  lessonDetailTime: {
    color: colors.black3,
    fontSize: fontSize.btnSM,
    lineHeight: lineHeight.textLineHeightXXL,
    textAlign: 'right',
  },
  headerWrapper: {
    padding: '0 0 12px 0',
  },
  headerTitle: {
    textAlign: 'center',
    fontSize: fontSize.textLG,
    lineHeight: lineHeight.titleXXS,
    fontWeight: fontWeight.bold,
  },

  selectAllText: {
    fontSize: fontSize.textMD,
    fontWeight: fontWeight.bold,
    lineHeight: lineHeight.textLineHeightLG,
  },
  textPadding: {
    padding: '6px 0',
  },
  checkboxContainer: {
    alignItems: 'flex-start',
  },
};
