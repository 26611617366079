import dayjs from 'dayjs';

import { hoursMinsFormat, yearMonthDateFormat, yearMonthDateHoursMinsFormat } from '@/constants/datetime';

import { IBulkScheduleDaysOfWeek } from './BulkScheduleForm.d';
import { isValidDate } from '@/utils/datetimeUtils';

export const validateDaysOfWeekRequired = (daysOfWeek: IBulkScheduleDaysOfWeek[]) => {
  if (daysOfWeek) {
    if (!daysOfWeek.some((day) => day.selected)) return 'dashboard.bulk.schedule.daysOfWeek.required';
    return true;
  }
  return false;
};

export enum ETimeValidationType {
  START = 'START',
  END = 'END',
}

type TValidateStartTimeEndTimeParams = {
  startTime: string;
  endTime: string;
  type: ETimeValidationType;
  translationKey: {
    invalid: string;
    timeNotInRange: string;
  };
};

export const validateStartTimeEndTime = ({
  startTime,
  endTime,
  type,
  translationKey,
}: TValidateStartTimeEndTimeParams) => {
  const convertedStartTime = dayjs(`${dayjs().format(yearMonthDateFormat)} ${startTime}`)
    .format(yearMonthDateHoursMinsFormat)
    .valueOf();
  const convertedEndTime = dayjs(`${dayjs().format(yearMonthDateFormat)} ${endTime}`)
    .format(yearMonthDateHoursMinsFormat)
    .valueOf();

  if (type === ETimeValidationType.START) {
    if (!isValidDate(startTime, hoursMinsFormat)) return translationKey.invalid;

    if (endTime && convertedStartTime >= convertedEndTime) return translationKey.timeNotInRange;
    return true;
  }

  if (!isValidDate(endTime, hoursMinsFormat)) return translationKey.invalid;

  if (startTime && convertedStartTime >= convertedEndTime) return translationKey.timeNotInRange;
  return true;
};
