import { FC, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { navigateDefault } from '@/utils/commonUtil';
import RoutesString from '@/pages/routesString';
import useAuthManagement from '@/store/authManagement/AuthManagement';
import useProfileManagement from '@/store/profileManagement/ProfileManagement';
import { usePreventDownloadMedia } from '@/utils/hooks/usePreventDownloadMedia';
import { API_ENTITY, NUMBER_ONE, SYMBOLS } from '@/constants';
import { TAnonymousLayoutProps } from './AnonymousLayout.d';

const AnonymousUserLayout: FC<TAnonymousLayoutProps> = ({ children, title }) => {
  const [authMgtState] = useAuthManagement();
  const { token } = authMgtState;
  const [profileMgtState] = useProfileManagement();
  const { activeUser } = profileMgtState ?? {};
  const intl = useIntl();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  usePreventDownloadMedia();

  useEffect(() => {
    const pathSegments = pathname.split(SYMBOLS.SLASH);
    const paymentResultSegment = pathSegments[NUMBER_ONE];

    if (paymentResultSegment !== API_ENTITY.PAYMENT && token) {
      if (activeUser) return navigateDefault(activeUser, navigate);
      navigate(RoutesString.Profile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = title
      ? `${intl.formatMessage({ id: 'common.title' })} ${SYMBOLS.HYPHEN} ${intl.formatMessage({ id: title })}`
      : intl.formatMessage({ id: 'common.title' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  return <>{children}</>;
};

export default AnonymousUserLayout;
