import classNames from 'classnames';
import { Stack } from '@mui/material';

import { CUSTOM_BADGE_COLORS } from '@/constants/enums';
import { CustomBadgeProps } from './CustomBadge';

import { styles } from './styles';

const CustomBadge = (props: CustomBadgeProps) => {
  const {
    color = CUSTOM_BADGE_COLORS.GREEN_2,
    content,
    contentColor = CUSTOM_BADGE_COLORS.GREEN_2,
    backgroundColor = CUSTOM_BADGE_COLORS.GREEN_1,
    className,
  } = props;
  const customBGColor = backgroundColor && `bg-${backgroundColor}`;
  const customContentColor = contentColor && `text-${contentColor}`;

  return (
    <Stack sx={styles.customBadgeContainer} className={classNames(color, customBGColor, className)}>
      <span className={customContentColor}>{content}</span>
    </Stack>
  );
};

export default CustomBadge;
