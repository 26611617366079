import { IBulkScheduleSuccessDialogScheduleData } from './BulkScheduleDialog.d';

export const defaultBulkScheduleSuccessDialogData: IBulkScheduleSuccessDialogScheduleData = {
  courseName: '',
  numberLessons: 0,
  numberHomeworks: 0,
  duration: 0,
  startDate: '',
  endDate: '',
};
