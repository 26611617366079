import { FC, SyntheticEvent, useMemo, useState } from 'react';
import { Stack, Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useIntl } from 'react-intl';
import { Dayjs } from 'dayjs';

import CalendarTab from './calendarTab';

import { ZERO } from '@/constants';

import { ICustomTabItem, ICustomTabs } from './CustomTab.d';

import { styles } from './styles';

const findCalendarActiveItem = (items: ICustomTabItem[], activeIndex: string) => {
  let result = false;
  for (let i = ZERO; i < items.length; i++) {
    const currentItem = items[i];
    const { isCalendarMode = false, tabKey } = currentItem ?? {};
    if (isCalendarMode && tabKey === activeIndex) {
      result = true;
      break;
    }
  }

  return result;
};

const CustomTab: FC<ICustomTabs> = ({
  tabListLabel,
  sxTabListWrapper = {},
  sxTabListLabel = {},
  sxTabList = {},
  sxTabItem = {},
  sxTabContent = {},
  items = [],
  active,
  onChangeItem,
}) => {
  const [activeTab, setActiveTab] = useState(active);
  const isCalendarActive = useMemo(() => {
    return findCalendarActiveItem(items, activeTab);
  }, [activeTab, items]);
  const intl = useIntl();

  const handleOnClick = (newTab: string, selectedDate?: Dayjs | null) => {
    setActiveTab(newTab);
    if (onChangeItem) onChangeItem(newTab, selectedDate);
  };

  const handleChange = (_: SyntheticEvent, newTab: string) => {
    handleOnClick(newTab);
  };

  return (
    <Stack>
      <TabContext value={activeTab}>
        <Box sx={sxTabListWrapper}>
          {tabListLabel && <Box sx={{ ...styles.tabListLabel, ...sxTabListLabel }}>{tabListLabel}</Box>}
          <TabList onChange={handleChange} sx={{ ...styles.tabList, ...sxTabList }}>
            {items.map((item) => {
              const { label, tabKey, isCalendarMode } = item;
              return isCalendarMode ? (
                <CalendarTab
                  key={`calendar-tab-list-${tabKey}-${label}`}
                  sxTextField={{ maxWidth: '152px' }}
                  tabKey={tabKey}
                  label={intl.formatMessage({
                    id: label,
                  })}
                  active={isCalendarActive}
                  onClick={handleOnClick}
                />
              ) : (
                <Tab
                  key={`tab-list-${tabKey}-${label}`}
                  sx={{ ...styles.tabItem, ...sxTabItem }}
                  label={intl.formatMessage({
                    id: label,
                  })}
                  value={tabKey}
                />
              );
            })}
          </TabList>
        </Box>

        {items.map((item) => {
          const { component, tabKey, label } = item;
          return (
            <TabPanel
              key={`tab-content-${tabKey}-${label}`}
              value={tabKey}
              sx={{ ...styles.tabContent, ...sxTabContent }}
            >
              {component}
            </TabPanel>
          );
        })}
      </TabContext>
    </Stack>
  );
};

export default CustomTab;
