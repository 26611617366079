import { useState } from 'react';
import { Box, IconButton, Typography, Grid, Tooltip } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';

import { ISchoolItem } from '@/types/School.d';
import ClassRosterImportDialog from './ClassRosterImportDialog';
import ClassRosterImportPopover from './ClassRosterImportPopover';
import { UserRoleEnum } from '@/types/Dashboard';
import { useLocale } from '@/providers/LocaleProvider';
import { handleDownload } from '@/utils/commonUtil';
import { CLASS_ROSTER_IMPORT_TEMPLATE_NAME, CLASS_ROSTER_IMPORT_TEMPLATE_PATH } from './constants';
import { ELocaleCode } from '@/constants/enums';
import useProfileManagement from '@/store/profileManagement/ProfileManagement';

import downloadIcon from '@/assets/images/icons/download-icon.svg';

import { styles } from './styles';

const ClassRosterImportButtons = () => {
  const [selectedSchool, setSelectedSchool] = useState<ISchoolItem | null>(null);
  const [openImportDialog, setOpenImportDialog] = useState<boolean>(false);
  const { locale } = useLocale();

  const [profileMgtState] = useProfileManagement();
  const { activeUser } = profileMgtState ?? {};
  const { roleId, schoolName, schoolId } = activeUser ?? {};
  const isOrgAdmin = Boolean(roleId) && roleId === Number(UserRoleEnum.ORG_ADMIN);
  const selectedSchoolName = get(selectedSchool, 'schoolName');
  const selectedSchoolId = get(selectedSchool, 'schoolId');

  const schoolData = !isOrgAdmin
    ? {
        schoolId,
        schoolName,
      }
    : {
        schoolId: selectedSchoolId,
        schoolName: selectedSchoolName,
      };

  const downloadProps =
    locale === ELocaleCode.EN
      ? {
          uri: CLASS_ROSTER_IMPORT_TEMPLATE_PATH.EN,
          name: CLASS_ROSTER_IMPORT_TEMPLATE_NAME.EN,
        }
      : {
          uri: CLASS_ROSTER_IMPORT_TEMPLATE_PATH.VN,
          name: CLASS_ROSTER_IMPORT_TEMPLATE_NAME.VN,
        };
  const handleOpenImportDialog = () => {
    setOpenImportDialog(true);
  };

  const handleCloseImportDialog = () => {
    setSelectedSchool(null);
  };

  const onTriggerImportButton = () => {
    handleOpenImportDialog();
  };

  const onCloseImportPopover = () => {
    setSelectedSchool(null);
  };

  const onSelectSchool = (item: ISchoolItem) => {
    setSelectedSchool(item);
    handleOpenImportDialog();
  };

  return (
    <>
      <Typography sx={styles.rosterTitle}>
        <FormattedMessage id="dashboard.classRoster.rostersImporting" />
      </Typography>
      <Grid container={true} justifyContent="space-between" sx={styles.rosterImporting}>
        <Grid item={true}>
          <Tooltip
            title={<FormattedMessage id="dashboard.classRoster.downloadTemplate" />}
            arrow={true}
            componentsProps={{
              tooltip: { sx: styles.tooltip },
            }}
          >
            <IconButton sx={styles.rosterImportingBtn} onClick={() => handleDownload(downloadProps)}>
              <Box component="img" src={downloadIcon} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item={true}>
          <ClassRosterImportPopover
            isOrgAdmin={isOrgAdmin}
            onClosePopover={onCloseImportPopover}
            onSelectSchool={onSelectSchool}
            onTriggerImportButton={onTriggerImportButton}
          />
          <ClassRosterImportDialog
            open={openImportDialog}
            setOpen={setOpenImportDialog}
            onClose={handleCloseImportDialog}
            schoolData={schoolData}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ClassRosterImportButtons;
