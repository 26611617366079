import { COMMON_VALUE_LAYOUT } from '@/styles/theme';

export const styles = {
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
  },
  blockHeader: {
    width: `calc(100% - ${COMMON_VALUE_LAYOUT.WIDTH_LEFT_MENU_CLIENT})`,
    marginLeft: COMMON_VALUE_LAYOUT.WIDTH_LEFT_MENU_CLIENT,

    display: 'flex',
    flexDirection: 'column',

    '&.spacing': {
      padding: '16px 30px',
    },
  },
};
