import { Box, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { stylesAttachedHomeworkDialog } from './styles';

const BulkScheduleKeepLessons = () => {
  return (
    <Box>
      <Typography variant="body2" sx={stylesAttachedHomeworkDialog.subtitle}>
        <FormattedMessage id="dashboard.bulk.schedule.allowed.attached.homework" />
      </Typography>
      <Typography variant="body2" sx={stylesAttachedHomeworkDialog.description}>
        <FormattedMessage id="dashboard.bulk.schedule.attached.homeworks.description" />
      </Typography>
    </Box>
  );
};

export default BulkScheduleKeepLessons;
