export const CLASS_ROSTER_IMPORT_TEMPLATE_NAME = {
  EN: 'Importing_Users_EN',
  VN: 'Importing_Users_VN',
};

export const CLASS_ROSTER_IMPORT_TEMPLATE_PATH = {
  EN: '/rostersImportingTemplate/en.xlsx',
  VN: '/rostersImportingTemplate/vn.xlsx',
};

export const MAX_CLASS_ROSTER_IMPORT_FILE_SIZE = 500000; // equals 500kb
