import { Box } from '@mui/material';
import { useState } from 'react';

import { NUMBER_ONE, UINT32_NUMBER, ZERO } from '@/constants';

import bird from '@/assets/images/bird.png';
import birdSide from '@/assets/images/bird-side.png';
import turtle from '@/assets/images/turtle.png';
import turtleSide from '@/assets/images/turtle-side.png';
import rabbit from '@/assets/images/rabbit.png';
import rabbitSide from '@/assets/images/rabbit-side.png';
import chicken from '@/assets/images/chicken.png';
import chickenSide from '@/assets/images/chicken-side.png';
import frog from '@/assets/images/frog.png';
import frogSide from '@/assets/images/frog-side.png';

interface Props {
  imageType: string;
}

const styles = {
  image: {
    height: '208px',
    objectFit: 'contain',
  },
};

export const randomImage = () => {
  const arrImg = ['bird', 'turtle', 'rabbit', 'chicken', 'frog'];
  const array = new Uint32Array(NUMBER_ONE);
  window.crypto.getRandomValues(array);
  const randomByte = array[ZERO];
  const normalizedRandom = randomByte / UINT32_NUMBER;
  return arrImg[Math.floor(normalizedRandom * arrImg.length)];
};

export const ProfileImage = ({ imageType }: Props) => {
  let image = '';
  let imageHover = '';

  switch (imageType) {
    case 'bird':
      image = bird;
      imageHover = birdSide;

      break;
    case 'turtle':
      image = turtle;
      imageHover = turtleSide;

      break;
    case 'rabbit':
      image = rabbit;
      imageHover = rabbitSide;

      break;
    case 'chicken':
      image = chicken;
      imageHover = chickenSide;

      break;
    case 'frog':
      image = frog;
      imageHover = frogSide;

      break;
    default:
      break;
  }

  const [mainImage, setMainImage] = useState<string>(image);

  return (
    <Box
      component="img"
      src={mainImage}
      sx={styles.image}
      onMouseOver={() => {
        setMainImage(imageHover);
      }}
      onMouseOut={() => {
        setMainImage(image);
      }}
    />
  );
};
