import { memo, useEffect, useState, MouseEvent } from 'react';
import { Box, Avatar, Menu, MenuItem, IconButton, Tooltip, Typography, Collapse } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';

import { UserProfile } from '@/types/Organization';
import { getUsersOnlyInSchool } from '@/modules/profileManagement/profile/action';
import { useLocale } from '@/providers/LocaleProvider';
import {
  ENTITY_STATUS,
  FIRST_ITEM_INDEX,
  FIRST_ITEM_NUMBER,
  NOT_EXIST_INDEX,
  ZERO,
  mappingLanguageOptionsFromLanguageCode,
} from '@/constants';
import RoutesString from '@/pages/routesString';
import { checkArrayHasLength, navigateDefault, translateRoleProfile } from '@/utils/commonUtil';
import useProfilesQuery from '@/utils/hooks/dashboard/useProfilesQuery';
import useTransferTokenQuery from '@/utils/hooks/dashboard/useTransferTokenQuery';
import useAuthManagement from '@/store/authManagement/AuthManagement';
import useProfileManagement from '@/store/profileManagement/ProfileManagement';

import ellipseImg from '@/assets/images/ellipse.png';
import userIconPath from '@/assets/images/icons/user-icon.svg';
import profileSelectionPath from '@/assets/images/icons/profile-selection-icon.svg';
import logoutIcon from '@/assets/images/icons/logout-original-icon.svg';

import { CustomSvg } from '../menuItemBtn/styles';
import { styles } from './styles';
import { ELocaleCode } from '@/constants/enums';

export const UserMenu = memo(() => {
  const [authMgtState, authMgtActions] = useAuthManagement();
  const { token: authToken } = authMgtState;
  const [profileMgtState, profileMgtActions] = useProfileManagement();
  const { activeOrganization: activeOrganizeItem, activeUser } = profileMgtState;

  const [hoverMenu, setHoverMenu] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const queryClient = useQueryClient();
  const [users, setUsers] = useState<UserProfile[]>();
  const [currentUser, setCurrentUser] = useState<UserProfile>();

  const { data: dataProfiles, refetch } = useProfilesQuery(authToken);
  const { data: dataTransfer } = useTransferTokenQuery(
    {
      userId: currentUser?.userId,
      organizationId: activeOrganizeItem?.organizationId,
    },
    authToken,
  );

  const {
    userFirstName: activeUserFirstName,
    userId: activeUserId,
    schoolName: activeSchoolName,
    roleId: activeRoleId,
  } = activeUser ?? {};

  const navigate = useNavigate();
  const location = useLocation();
  const { locale, setLocale } = useLocale();

  const handleSwitchLanguage = (lang: ELocaleCode) => {
    setLocale(lang);
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataProfiles) {
      const { organizations } = dataProfiles;
      const newUsers = getUsersOnlyInSchool(organizations, activeOrganizeItem, activeUser)
        .sort((firstItem: UserProfile, secondItem: UserProfile) => firstItem.roleId - secondItem.roleId)
        .sort((firstItem: UserProfile) => (firstItem.userId === activeUserId ? NOT_EXIST_INDEX : ZERO));
      setUsers(newUsers);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProfiles, activeUser]);

  useEffect(() => {
    if (dataTransfer) {
      authMgtActions.setTransferToken(dataTransfer.token);
      profileMgtActions.setProfileInfo({
        activeUser: currentUser,
      });

      currentUser && navigateDefault(currentUser, navigate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTransfer, currentUser]);

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitchUser = async (user: UserProfile) => {
    if (user.userStatus === ENTITY_STATUS.INACTIVE) return;

    queryClient.clear();
    setCurrentUser(user);
  };

  const handleLogout = () => {
    authMgtActions.reset();
    profileMgtActions.reset();

    navigate(RoutesString.Home);
  };

  const handleMoveToOrganization = () => {
    authMgtActions.setTransferToken('');
    profileMgtActions.setProfileInfo({
      activeUser: null,
    });

    navigate(RoutesString.Profile);
  };

  const calculateShortName = (userName = '') => {
    const arrUserName = userName.split(' ');
    let name = arrUserName[FIRST_ITEM_INDEX].charAt(FIRST_ITEM_INDEX);

    if (arrUserName.length > FIRST_ITEM_NUMBER) {
      name = `${arrUserName[FIRST_ITEM_INDEX].charAt(FIRST_ITEM_INDEX)}${arrUserName[
        arrUserName.length - FIRST_ITEM_NUMBER
      ].charAt(FIRST_ITEM_INDEX)}`;
    }
    return name;
  };

  const renderSwitchLanguage = (lang: ELocaleCode) => {
    const nextLanguage = lang === ELocaleCode.EN ? ELocaleCode.VI : ELocaleCode.EN;

    return (
      <MenuItem onClick={() => handleSwitchLanguage(nextLanguage)}>
        <Box sx={styles.blockSwitchLanguage}>
          <Box component="img" src={mappingLanguageOptionsFromLanguageCode[nextLanguage].flag} />
          <Typography>
            <FormattedMessage id="menu.dropdown.switchLanguage" />
          </Typography>
        </Box>
      </MenuItem>
    );
  };

  return (
    <Box sx={styles.container} key={`user-menu-${location.pathname}`}>
      <Tooltip
        title={activeSchoolName}
        arrow={true}
        componentsProps={{
          tooltip: { sx: styles.tooltip },
        }}
      >
        <Box
          onMouseOver={() => setHoverMenu(true)}
          onMouseOut={() => setHoverMenu(false)}
          onClick={handleClick}
          sx={styles.blockMenu}
        >
          <IconButton size="small">
            <Avatar sx={[styles.avatar, styles.avatarCircle]}>{calculateShortName(activeUserFirstName)}</Avatar>
          </IconButton>

          <Collapse orientation="horizontal" in={hoverMenu || Boolean(anchorEl)}>
            <Box sx={styles.blockName}>
              <Typography className="info name">
                {`${activeUserFirstName} - `}
                <FormattedMessage id={translateRoleProfile(activeRoleId)} />
              </Typography>
              <Typography className="info">{activeSchoolName}</Typography>
            </Box>
            <KeyboardArrowDownIcon />
          </Collapse>
        </Box>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: styles.blockDropdown,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        disableAutoFocusItem={true}
      >
        <MenuItem>
          <Box sx={styles.blockUser}>
            <Box component="img" src={ellipseImg} />
            <Box ml="10px" />
            <Typography>{activeOrganizeItem?.organizationName}</Typography>
          </Box>
        </MenuItem>

        <Box sx={styles.divider} />

        <Box sx={styles.blockProfile}>
          {checkArrayHasLength(users) &&
            users?.map((user: UserProfile) => {
              const { userId, userFirstName, roleId } = user;
              return (
                <MenuItem
                  key={`${userFirstName}-${userId}`}
                  onClick={() => handleSwitchUser(user)}
                  className={classNames({
                    activeUser: userId === activeUserId,
                  })}
                >
                  <Box sx={styles.blockUser}>
                    <CustomSvg
                      className={classNames('svg', {
                        activeSvg: userId === activeUserId,
                      })}
                      src={userIconPath}
                    />
                    <Box ml="10px" />
                    <Typography>
                      {`${userFirstName} - `}
                      <FormattedMessage id={translateRoleProfile(roleId)} />
                    </Typography>
                  </Box>
                </MenuItem>
              );
            })}
        </Box>

        <MenuItem onClick={handleMoveToOrganization}>
          <Box sx={styles.blockUser}>
            <CustomSvg className="svg" src={profileSelectionPath} />
            <Box ml="10px" />
            <Typography>
              <FormattedMessage id="menu.dropdown.organizationSelect" />
            </Typography>
          </Box>
        </MenuItem>

        <Box sx={styles.divider} />

        {renderSwitchLanguage(locale)}
        <MenuItem onClick={handleLogout}>
          <Box sx={styles.blockUser}>
            <CustomSvg className="svg" src={logoutIcon} />
            <Box ml="10px" />
            <Typography>
              <FormattedMessage id="menu.dropdown.signOut" />
            </Typography>
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  );
});
