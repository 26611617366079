import { FC } from 'react';
import { DialogContent, Box, DialogTitle, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

import { CustomDialog } from '@/components/customDialog/CustomDialog';

import scheduleIcon from '@/assets/images/icons/calendar-tick.svg';
import { SYMBOLS } from '@/constants';
import { yearMonthDateFullTimeFormat } from '@/constants/datetime';

import { TBulkScheduleSuccessDialogProps } from './BulkScheduleDialog.d';

import { styles } from './styles';

const BulkScheduleSuccessDialog: FC<TBulkScheduleSuccessDialogProps> = ({ scheduleData, open, setOpen }) => {
  const { courseName, numberLessons, numberHomeworks, duration, startDate, endDate } = scheduleData;

  return (
    <CustomDialog position="middle" open={open} onClose={() => setOpen(false)}>
      <DialogTitle sx={styles.dialogTitle}>
        <Box component="img" src={scheduleIcon} />
        <Typography sx={styles.dialogTitleText}>
          <FormattedMessage id="dashboard.bulk.schedule.createSuccessfully" values={{ number: numberLessons }} />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={styles.dialogContent}>
          <Box justifyContent="center">
            <Typography>{courseName}</Typography>
          </Box>
          <Box>
            <Typography>
              <FormattedMessage id="dashboard.bulk.schedule.attachedHomework" />
              {SYMBOLS.COLON}
            </Typography>
            {numberHomeworks} <FormattedMessage id="dashboard.bulk.schedule.homeworkSchedule" />
          </Box>
          <Box>
            <Typography>
              <FormattedMessage id="dashboard.bulk.schedule.duration" />
              {SYMBOLS.COLON}
            </Typography>
            {duration} <FormattedMessage id="dashboard.bulk.schedule.weeks" />
          </Box>
          <Box>
            <Typography>
              <FormattedMessage id="dashboard.bulk.schedule.kickoff" />
              {SYMBOLS.COLON}
            </Typography>
            {dayjs(startDate).format(yearMonthDateFullTimeFormat)}
          </Box>
          <Box>
            <Typography>
              <FormattedMessage id="dashboard.bulk.schedule.finish" />
              {SYMBOLS.COLON}
            </Typography>
            {dayjs(endDate).format(yearMonthDateFullTimeFormat)}
          </Box>
        </Box>
      </DialogContent>
    </CustomDialog>
  );
};

export default BulkScheduleSuccessDialog;
