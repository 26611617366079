import { colors, fontSize, fontWeight, lineHeight, zIndex } from '@/styles/theme';
import { TMuiStyle } from '@/types/Common';

export const styles: TMuiStyle = {
  dateWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    gap: '8px',
    position: 'absolute',
    width: '100%',
    height: '112px',
    left: '0',
    top: '0',
    borderRadius: '32px',
    cursor: 'pointer',
    zIndex: zIndex.zIndexPopupLearning,
    border: `4px solid ${colors.white3}`,
    background: colors.white1,
    boxSizing: 'border-box',
  },
  date: {
    fontSize: fontSize.btnXS,
    lineHeight: lineHeight.textLineHeightLG,
    color: colors.secondary1,
    fontWeight: fontWeight.bold,
  },
  day: {
    fontSize: fontSize.btnSM,
    lineHeight: lineHeight.titleXXS,
    color: colors.secondary1,
  },
  timeLabel: {
    fontSize: fontSize.btnXS,
    lineHeight: lineHeight.textLineHeightLG,
    color: colors.secondary1,
    textTransform: 'uppercase',
  },
  time: {
    fontWeight: fontWeight.bold,
    fontSize: fontSize.btnSM,
    lineHeight: lineHeight.titleXXS,
    color: colors.black1,
    cursor: 'pointer',
  },
  statusWrapper: {
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dot: {
    width: '8px',
    height: '8px',
    background: colors.black4,
    borderRadius: '100%',
  },
  selectedDot: {
    background: colors.nhUnit1,
  },
  timeSelectorWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'relative',
    width: '60px',
    height: '112px',
    background: colors.white3,
    boxShadow: `0px 1px 2px -4px ${colors.black6Opa10}`,
    borderRadius: '32px',
    transition: 'height 0.5s ease',
  },
  selectedTimeSelectorWrapper: {
    '> .date-wrapper': {
      background: colors.linearPrimary2,
      boxShadow: `0 2px 4px -4px ${colors.black6Opa20}`,
      border: 'none',
    },
  },
  selectedWithTimeSelectorWrapper: {
    height: '256px',
  },
  timeWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    margin: '8px 0 14px',
    alignItems: 'center',
  },
  timeGroupWrapper: {
    transition: 'opacity 0.5s ease',
    opacity: 0,
  },
  selectedTimeGroupWrapper: {
    opacity: 1,
  },
  disabled: {
    '&.MuiBox-root': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  },
  popover: {
    '.MuiTextField-root': {
      marginTop: '5px',
    },
  },
  dateSunday: {
    '>.MuiTypography-root': {
      color: colors.primary1,
    },
  },
};
