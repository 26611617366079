import { useEffect, FC } from 'react';
import { useLocation } from 'react-router-dom';

import { ZERO } from '@/constants';

export const scrollToTop = () => {
  window.scrollTo({
    top: ZERO,
    behavior: 'smooth',
  });
};

const ScrollToTop: FC = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [pathname, search]);

  return null;
};

export default ScrollToTop;
