import { ENTITY_STATUS, ZERO } from '@/constants';
import { Organization, UserProfile, Schools } from '@/types/Organization';
import { randomImage } from '@/modules/profileManagement/components/ProfileImage';
import { UserRoleEnum } from '@/types/Dashboard';

export const newOrganizationWithImages = (arrOrg: Organization[]): Organization[] => {
  const cloneObj = JSON.parse(JSON.stringify(arrOrg));

  const organizationsWithImages = cloneObj.map((orgItem: Organization) => {
    let newUserAdmin: UserProfile[] = [];

    if (orgItem?.users) {
      newUserAdmin = orgItem.users.map((user) => {
        return {
          ...user,
          imageType: randomImage(),
        };
      });

      let newSchool: Schools[] = [];
      if (orgItem?.schools) {
        newSchool = orgItem?.schools.map((school) => {
          return {
            ...school,
            users: school.users.map((user) => {
              return {
                ...user,
                imageType: randomImage(),
              };
            }),
          };
        });
      }

      return {
        ...orgItem,
        imageType: randomImage(),

        users: newUserAdmin,
        schools: newSchool,
      };
    }

    let newSchool: Schools[] = [];
    if (orgItem?.schools) {
      newSchool = orgItem.schools.map((school) => {
        return {
          ...school,
          users: school.users.map((user) => {
            return {
              ...user,
              imageType: randomImage(),
            };
          }),
        };
      });

      return {
        ...orgItem,
        imageType: randomImage(),
        schools: newSchool,
      };
    }

    return orgItem;
  });

  return organizationsWithImages;
};

export const sanitizeData = (activeOrganizeItem: Organization | null) => {
  const arr: any = [];

  if (activeOrganizeItem?.users && activeOrganizeItem.users.length > ZERO) {
    arr.push(activeOrganizeItem.users);
  }

  if (activeOrganizeItem?.schools && activeOrganizeItem.schools.length > ZERO) {
    activeOrganizeItem.schools.forEach((school: Schools) => {
      const newData = school.users.map((user) => {
        const { schoolId, schoolName } = school;
        return {
          ...user,
          schoolName,
          schoolId,
        };
      });
      arr.push(newData);
    });
  }
  return arr;
};

const sanitizeSchoolItem = (schoolItem: Schools, schoolIdOfActiveUser: number | undefined, arr: UserProfile[]) => {
  const { schoolName, schoolId, users: schoolUsers } = schoolItem;
  if (schoolId !== schoolIdOfActiveUser) return;

  Array.isArray(schoolUsers) &&
    schoolUsers.length &&
    schoolUsers.forEach((user) => {
      const { userStatus } = user;

      if (userStatus === ENTITY_STATUS.INACTIVE) return;

      arr.push({
        ...user,
        schoolName,
        schoolId,
      });
    });
};

export const getUsersOnlyInSchool = (
  organizations: Organization[],
  activeOrganizeItem: Organization | null,
  activeUser: UserProfile | null,
) => {
  const arr: UserProfile[] = [];
  const { roleId, schoolId: schoolIdOfActiveUser } = activeUser ?? {};

  const { organizationId } = activeOrganizeItem ?? {};

  const foundNewActiveOrg = organizations.find((orgItem) => orgItem.organizationId === organizationId);

  const { users, schools } = foundNewActiveOrg ?? {};

  if (Array.isArray(users) && users.length && roleId === Number(UserRoleEnum.ORG_ADMIN)) {
    users.length &&
      users.forEach((user) => {
        const { userStatus } = user;

        if (userStatus === ENTITY_STATUS.INACTIVE) return;

        arr.push({
          ...user,
        });
      });

    return arr;
  }

  if (Array.isArray(schools) && schools.length) {
    schools.forEach((schoolItem) => {
      sanitizeSchoolItem(schoolItem, schoolIdOfActiveUser, arr);
    });
  }

  return arr;
};
