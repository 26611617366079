import { FC, MouseEvent, useEffect, useState } from 'react';
import { Box, Popover, Typography } from '@mui/material';
import { Controller, FieldPath, Path } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import get from 'lodash/get';
import dayjs from 'dayjs';

import { DEFAULT_TIME_VALUE } from './constants';
import { dateMonthHyphenFormat, dateMonthYearDotFormat, daysOfWeekFormat, hoursMinsFormat } from '@/constants/datetime';
import { FIRST_ITEM_NUMBER, ZERO } from '@/constants';
import {
  ETimeValidationType,
  validateStartTimeEndTime,
} from '@/modules/scheduleManagement/bulkSchedule/form/validation';
import { buildValidation } from '@/utils/validationUtils';
import { isValidDate } from '@/utils/datetimeUtils';

import TimePicker from '../timePicker';

import { IBulkScheduleValues } from '@/modules/scheduleManagement/bulkSchedule/form/BulkScheduleForm.d';
import { TScheduleTimeSelector } from './types';

import errorIcon from '@/assets/images/icons/attention.svg';

import { styles } from './styles';

const ScheduleTimeSelector: FC<TScheduleTimeSelector> = ({
  dayItem,
  disabled,
  control,
  watch,
  setValue,
  pathName,
  showTimeSelector,
  error,
}) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<{ anchor: HTMLSpanElement | null; id: string }>({
    anchor: null,
    id: '',
  });
  const { anchor, id } = anchorEl;
  const startTimeId = `${pathName}.startTime` as Path<IBulkScheduleValues>;
  const endTimeId = `${pathName}.endTime` as Path<IBulkScheduleValues>;
  const isAlwaysSelectedId = `${pathName}.isAlwaysSelected` as Path<IBulkScheduleValues>;
  const selectedId = `${pathName}.selected` as Path<IBulkScheduleValues>;

  const openStart = Boolean(anchorEl.anchor) && id === startTimeId;
  const openEnd = Boolean(anchorEl.anchor) && id === endTimeId;
  const startTimeValue = watch(startTimeId) as string;
  const endTimeValue = watch(endTimeId) as string;
  const isAlwaysSelectedValue = watch(isAlwaysSelectedId) as boolean;
  const selectedValue = watch(selectedId) as boolean;

  const { date } = dayItem;
  const dateLabel = date.format(dateMonthHyphenFormat);
  const dayOfWeekLabel = date.format(daysOfWeekFormat);
  const isSunday = date.day() === ZERO;

  const convertedStartTime = `${dayjs(date).format(dateMonthYearDotFormat)} ${startTimeValue}`;
  const convertedEndTime = `${dayjs(date).format(dateMonthYearDotFormat)} ${endTimeValue}`;
  const minEndTime = dayjs(convertedStartTime).add(FIRST_ITEM_NUMBER, 'minute');
  const maxStartTime = dayjs(convertedEndTime).subtract(FIRST_ITEM_NUMBER, 'minute');

  const isDisabled = disabled ?? isAlwaysSelectedValue;

  const onSelect = () => {
    if (!isDisabled) {
      setValue(selectedId, !selectedValue);
    }
  };

  const handleOpenPopover = (event: MouseEvent<HTMLSpanElement>, openId: string) => {
    setAnchorEl({ anchor: event.currentTarget, id: openId });
  };

  const handleClosePopover = () => {
    setAnchorEl({ anchor: null, id: '' });
  };

  const handleOnChangeTime = (time: string, field: FieldPath<IBulkScheduleValues>) => {
    if (isValidDate(time, hoursMinsFormat)) setValue(field, time);
  };

  const startTimeForm = (
    <Controller
      name={startTimeId}
      control={control}
      rules={{
        required: intl.formatMessage({
          id: 'dashboard.bulk.schedule.lessonStart.required',
        }),
        validate: (startTime, formValues) => {
          const endTime = get(formValues, endTimeId) || '';
          const validationValues = validateStartTimeEndTime({
            startTime,
            endTime,
            type: ETimeValidationType.START,
            translationKey: {
              invalid: 'dashboard.bulk.schedule.lessonStart.invalid',
              timeNotInRange: 'dashboard.bulk.schedule.lessonStart.mustBeSmallerThanEndTime',
            },
          });
          return buildValidation(validationValues, intl);
        },
      }}
      render={({ field, fieldState }) => (
        <TimePicker
          {...field}
          {...fieldState}
          label="schedule.form.from.placeholder"
          placeholder="schedule.form.startTime.placeholder"
          maxTime={maxStartTime}
          hideError={true}
          onChange={(time) => handleOnChangeTime(time, startTimeId)}
        />
      )}
    />
  );

  const endTimeForm = (
    <Controller
      name={endTimeId}
      control={control}
      rules={{
        required: intl.formatMessage({
          id: 'dashboard.bulk.schedule.lessonEnd.required',
        }),
        validate: (endTime, formValues) => {
          const startTime = get(formValues, startTimeId) || '';
          const validationValues = validateStartTimeEndTime({
            startTime,
            endTime,
            type: ETimeValidationType.END,
            translationKey: {
              invalid: 'dashboard.bulk.schedule.lessonEnd.invalid',
              timeNotInRange: 'dashboard.bulk.schedule.lessonEnd.mustBeBiggerThanStartTime',
            },
          });
          return buildValidation(validationValues, intl);
        },
      }}
      render={({ field, fieldState }) => (
        <TimePicker
          {...field}
          {...fieldState}
          label="schedule.form.to.placeholder"
          placeholder="schedule.form.startTime.placeholder"
          minTime={minEndTime}
          hideError={true}
          onChange={(time) => handleOnChangeTime(time, endTimeId)}
        />
      )}
    />
  );

  useEffect(() => {
    if (isAlwaysSelectedValue) {
      setValue(selectedId, true);
    }
  }, [selectedId, isAlwaysSelectedValue, setValue]);

  return (
    <Box
      sx={{
        ...styles.timeSelectorWrapper,
        ...(selectedValue && styles.selectedTimeSelectorWrapper),
        ...(selectedValue && showTimeSelector && styles.selectedWithTimeSelectorWrapper),
        ...(disabled && styles.disabled),
      }}
    >
      <Box
        className="date-wrapper"
        sx={{
          ...styles.dateWrapper,
          ...(isSunday && styles.dateSunday),
        }}
        onClick={onSelect}
      >
        <Typography sx={styles.date}>{dateLabel}</Typography>
        <Typography sx={styles.day}>{dayOfWeekLabel}</Typography>
        <Box sx={{ ...styles.statusWrapper }}>
          {!error ? (
            <Box sx={{ ...styles.dot, ...(selectedValue && styles.selectedDot) }} />
          ) : (
            <Box component="img" src={errorIcon} />
          )}
        </Box>
      </Box>

      {showTimeSelector && (
        <Box
          sx={{
            ...styles.timeGroupWrapper,
            ...(selectedValue && styles.selectedTimeGroupWrapper),
          }}
        >
          <Box sx={styles.timeWrapper}>
            <Typography sx={styles.timeLabel}>
              <FormattedMessage id="dashboard.bulk.schedule.start" />
            </Typography>
            <Typography sx={styles.time} onClick={(e) => handleOpenPopover(e, startTimeId)}>
              {startTimeValue || DEFAULT_TIME_VALUE}
            </Typography>
            <Popover
              sx={styles.popover}
              id={startTimeId}
              open={openStart}
              anchorEl={anchor}
              onClose={handleClosePopover}
            >
              {startTimeForm}
            </Popover>
            {selectedValue && <Box sx={{ display: 'none' }}>{startTimeForm}</Box>}
          </Box>

          <Box sx={styles.timeWrapper}>
            <Typography sx={styles.timeLabel}>
              <FormattedMessage id="dashboard.bulk.schedule.end" />
            </Typography>
            <Typography sx={styles.time} onClick={(e) => handleOpenPopover(e, endTimeId)}>
              {endTimeValue || DEFAULT_TIME_VALUE}
            </Typography>
            <Popover sx={styles.popover} id={endTimeId} open={openEnd} anchorEl={anchor} onClose={handleClosePopover}>
              {endTimeForm}
            </Popover>
            {selectedValue && <Box sx={{ display: 'none' }}>{endTimeForm}</Box>}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ScheduleTimeSelector;
