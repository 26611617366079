import { colors } from '@/styles/theme';

export const styles = {
  container: {
    color: colors.secondary1,
  },
  tooltip: {
    background: colors.secondaryLight3,
    color: colors.secondary1,
    fontSize: '13px',
    '&.MuiTooltip-tooltip.MuiTooltip-tooltip.MuiTooltip-tooltip': {
      marginTop: '8px',
    },

    '.MuiTooltip-arrow': {
      color: colors.secondaryLight3,
    },
  },

  blockMenu: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    border: `1px solid ${colors.secondary1}`,
    borderRadius: '30px',
    cursor: 'pointer',

    '.MuiCollapse-wrapperInner': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  blockName: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '0 12px',
    height: '40px',

    '.info': {
      fontSize: '13px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '150px',
      whiteSpace: 'nowrap',

      '&.name': {
        fontWeight: '600',
      },
    },
  },
  avatar: {
    width: 32,
    height: 32,
    backgroundColor: colors.secondary1,
    color: colors.white1,
    fontSize: '11px',
    fontWeight: '700',
    margin: '0 0 0 4px',
    textTransform: 'uppercase',
  },
  avatarCircle: {
    margin: '0',
  },

  divider: {
    border: `1px dashed ${colors.black4}`,
    margin: '16px 10px',
  },

  blockDropdown: {
    fontSize: '13px',
    width: '270px',

    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },

    '.MuiTypography-root': {
      whiteSpace: 'normal',
    },

    '.MuiButtonBase-root:hover': {
      backgroundColor: colors.secondaryLight3,
      color: colors.secondary1,

      '.svg': {
        backgroundColor: colors.secondary1,
      },
    },

    '.activeUser': {
      color: colors.secondary1,
      '.MuiTypography-root': {
        fontWeight: '600',
      },
      '.activeSvg': {
        backgroundColor: colors.secondary1,
      },
    },
  },

  blockUser: {
    display: 'flex',
    alignItems: 'center',
  },

  blockProfile: {
    maxHeight: '300px',
    overflow: 'auto',
  },

  blockSwitchLanguage: {
    display: 'flex',
    gap: '6px',

    img: {
      width: '24px',
      height: '24px',
    },
  },
};
