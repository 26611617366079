import { fetchJson } from '@/utils/requestUtils';
import {
  ResetPasswordWithPhoneFormData,
  ResetPasswordWithPhoneResponse,
  CheckPhoneFormData,
  CheckPhoneResponse,
  LoginFormData,
  LoginResponse,
  RegisterFormData,
  RegisterResponse,
} from './auth';

const endpoint = process.env.REACT_APP_AUTH_SERVICE_API_URL;

export const authClient = {
  loginWithPhone: async (params: LoginFormData): Promise<LoginResponse> => {
    const url = `${endpoint}/api/v1/auth`;
    const response: LoginResponse = await fetchJson(url, 'POST', params);
    return response;
  },

  checkPhone: async (params: CheckPhoneFormData): Promise<CheckPhoneResponse> => {
    const url = `${endpoint}/api/v1/users/check-phone`;
    const response: CheckPhoneResponse = await fetchJson(url, 'POST', params);
    return response;
  },

  registerWithPhone: async (params: RegisterFormData): Promise<RegisterResponse> => {
    const url = `${endpoint}/api/v1/users/register-with-phone`;
    const response: RegisterResponse = await fetchJson(url, 'POST', params);
    return response;
  },

  resetPasswordWithPhone: async (params: ResetPasswordWithPhoneFormData): Promise<ResetPasswordWithPhoneResponse> => {
    const url = `${endpoint}/api/v1/users/reset-password-with-phone`;
    const response: ResetPasswordWithPhoneResponse = await fetchJson(url, 'POST', params);
    return response;
  },
};
