import { FC } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { BrowserRouter } from 'react-router-dom';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';

import CustomRoutes from '@/pages/routes';
import { FirebaseProvider } from '@/providers/FirebaseProvider';
import { LocaleProvider } from '@/providers/LocaleProvider';
import { ConfirmDialogProvider } from '@/modules/dashboard/commonElementsManagement/providers/ConfirmDialogProvider';
import { ToastProvider } from '@/modules/dashboard/commonElementsManagement/providers/ToastProvider';
import withAuthManagementPersist from './store/authManagement/withAuthManagement';
import withProfileManagementPersist from './store/profileManagement/withProfileManagement';
import withLanguageManagementPersist from './store/languageManagement/withLanguageManagement';
import { WEEK_START_FROM_MONDAY } from './constants/timers';
import { SENTRY_TRACKING_URLS } from './constants';
import REGEX from './constants/regex';
import { ORIGINAL_EXCEPTION } from './constants/sentry';
import RoutesString from './pages/routesString';

import '@/store/middlewares/persistent';

import theme from './styles/theme';

ReactGA.initialize([
  {
    trackingId: String(process.env.REACT_APP_GA_TRACKING_ID || ''),
  },
]);

Sentry.init({
  environment: process.env.REACT_APP_ENV,
  dsn: process.env.REACT_APP_SENTRY_ID,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: SENTRY_TRACKING_URLS,
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  ignoreErrors: [REGEX.ZALO_JS],
  beforeSend(event, hint) {
    /**
     * Link to 86enj9nc2
     * For now we will skip Timeout for ForgotPassword and Register
     * And make sure to handle timeout for these two pages
     */
    if (hint.originalException === ORIGINAL_EXCEPTION.TIMEOUT) {
      const url = event.request?.url || '';
      const path = new URL(url).pathname;

      if (path === RoutesString.ForgotPassword || path === RoutesString.Register) {
        return null;
      }
    }

    return event; // Return the event unchanged
  },
});

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: WEEK_START_FROM_MONDAY,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: 1,
    },
  },
});

const App: FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <FirebaseProvider>
          <LocaleProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <BrowserRouter>
                <ConfirmDialogProvider>
                  <ToastProvider>
                    <CustomRoutes />
                  </ToastProvider>
                </ConfirmDialogProvider>
              </BrowserRouter>
            </LocalizationProvider>
          </LocaleProvider>
        </FirebaseProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default withAuthManagementPersist(withProfileManagementPersist(withLanguageManagementPersist(App)));
