export const DEBOUNCE_INPUT_TIME = 500;
export const FIVE_MINUTES_STALE_TIME = 5 * (60 * 1000);
export const ZERO_CACHE_TIME = 0;
export const ONE_THOUSAND_MILLISECONDS = 1000;
export const ONE_MINUTE = 60 * 60;
export const FIFTEEN_MINUTES = ONE_MINUTE * 15;
export const HAFT_HOUR = ONE_MINUTE * 30;
export const FORTY_FIVE_MINUTES = ONE_MINUTE * 45;
export const ONE_HOUR = HAFT_HOUR * 2;
export const SIX_HOURS = ONE_HOUR * 6;
export const SEVEN_HOURS = ONE_HOUR * 7;
export const EIGHT_HOURS = ONE_HOUR * 8;
export const NINE_HOURS = ONE_HOUR * 9;
export const TEN_HOURS = ONE_HOUR * 10;
export const ELEVEN_HOURS = ONE_HOUR * 11;
export const TWELVE_HOURS = ONE_HOUR * 12;
export const THIRTEEN_HOURS = ONE_HOUR * 13;
export const FOURTEEN_HOURS = ONE_HOUR * 14;
export const FIFTEEN_HOURS = ONE_HOUR * 15;
export const SIXTEEN_HOURS = ONE_HOUR * 16;
export const SEVENTEEN_HOURS = ONE_HOUR * 17;
export const EIGHTEEN_HOURS = ONE_HOUR * 18;
export const NINETEEN_HOURS = ONE_HOUR * 19;
export const TWENTY_HOURS = ONE_HOUR * 20;
export const TWENTY_ONE_HOURS = ONE_HOUR * 21;
export const TWENTY_TWO_HOURS = ONE_HOUR * 22;
export const TWENTY_THREE_HOURS = ONE_HOUR * 23;
export const ONE_DAY = ONE_HOUR * 24;
export const WEEK_START_FROM_MONDAY = 1;

export const DEBOUNCE_RENDER_TIME = 500;
export const TRANSITION_TIME = 500;
export const MEDIUM_TRANSITION_TIME = 300;
export const FAST_TRANSITION_TIME = 100;

export const DEBOUNCE_ANIMATION_TROPHY_TIME = 1200;
export const DELAY_TWO_SECONDS = 2000;
export const DELAY_ONE_AND_A_HALF = 1500;

export const COUNTDOWN_OTP_SECONDS = 3 * 60;
export const COUNTDOWN_RESEND_SECONDS = 60;
