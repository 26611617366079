import { Box, Snackbar, Typography } from '@mui/material';
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import successIcon from '@/assets/images/success-icon.svg';
import errorIcon from '@/assets/images/error-icon.svg';

import { colors } from '@/styles/theme';

interface Props {
  children: ReactNode | ReactNode[];
}

type ToastContextProps = {
  showSuccess: (_message: string) => void;
  showError: (_message: string) => void;
};

const ToastContext = createContext<ToastContextProps>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showSuccess: (_message: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showError: (_message: string) => {},
});

const styles = {
  container: {
    backgroundColor: '#DAFAE7',
    border: '1px solid #AFEFC9',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    padding: '16px',
    gap: '16px',
    maxWidth: '424px',
  },
  errorContainer: {
    backgroundColor: '#FFEBEB',
    border: '1px solid #FFB3B3',
  },
  iconContainer: {
    width: '24px',
    minWidth: '24px',
    height: '24px',
    borderRadius: '8px',
    padding: '2px',
    backgroundColor: colors.spectrum1,
  },
  errorIconContainer: {
    backgroundColor: colors.destructiveRed,
  },
  icon: {
    width: '100%',
    height: '100%',
  },
  message: {
    flexGrow: 1,
    color: colors.spectrum1,
  },
  errorMessage: {
    color: colors.destructiveRed,
  },
};
export const ToastProvider = ({ children }: Props) => {
  const [toast, setToast] = useState({
    key: 0,
    open: false,
    message: '',
    error: false,
  });

  const handleClose = () => {
    setToast({
      key: new Date().getTime(),
      open: false,
      message: '',
      error: false,
    });
  };

  const showSuccess = useCallback(
    (message: string) => {
      setToast({
        key: new Date().getTime(),
        open: true,
        message,
        error: false,
      });
    },
    [setToast],
  );

  const showError = useCallback(
    (message: string) => {
      setToast({
        key: new Date().getTime(),
        open: true,
        message,
        error: true,
      });
    },
    [setToast],
  );

  const toastContextProviderValue = useMemo(() => ({ showSuccess, showError }), [showError, showSuccess]);

  return (
    <ToastContext.Provider value={toastContextProviderValue}>
      {children}
      <Snackbar
        key={toast.key}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={toast.open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Box sx={[styles.container, toast.error ? styles.errorContainer : null]}>
          <Box sx={[styles.iconContainer, toast.error ? styles.errorIconContainer : null]}>
            <Box sx={styles.icon} component="img" src={toast.error ? errorIcon : successIcon} />
          </Box>
          <Typography sx={[styles.message, toast.error ? styles.errorMessage : null]}>{toast.message}</Typography>
        </Box>
      </Snackbar>
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
