import { FC, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useIntl } from 'react-intl';

import CustomTab from '@/components/customTab';
import ScheduleStepTab from './ScheduleStepTab';

import { getNumberOfDaysFromDate, getScheduleDaysOfWeek } from '../../utils';
import { SCHEDULE_STEP_TAB_KEY } from '../constants';
import { FIRST_ITEM_NUMBER, ZERO } from '@/constants';
import { SEVEN_DAYS } from '@/constants/datetime';

import { IBulkScheduleDaysOfWeek, IScheduleStep } from './BulkScheduleForm.d';

import { colors } from '@/styles/theme';

const ScheduleStep: FC<IScheduleStep> = ({ control, errors, watch, setValue, getValues }) => {
  const intl = useIntl();
  const today = dayjs();
  const firstDateNextWeek = today.add(FIRST_ITEM_NUMBER, 'week').startOf('week');
  const scheduleTabId = watch('scheduleTabId');
  const daysOfWeek = watch('daysOfWeek');

  const handleOnChangeTab = (newTab: string, date?: Dayjs | null) => {
    setValue('scheduleTabId', newTab);

    let scheduleDaysOfWeek: IBulkScheduleDaysOfWeek[] = [];
    switch (newTab) {
      case SCHEDULE_STEP_TAB_KEY.CUSTOMIZE:
        {
          const nextSevenDays = getNumberOfDaysFromDate(date ?? today, SEVEN_DAYS);
          scheduleDaysOfWeek = getScheduleDaysOfWeek(nextSevenDays, ZERO);
        }
        break;
      case SCHEDULE_STEP_TAB_KEY.TODAY:
        {
          const nextSevenDays = getNumberOfDaysFromDate(today, SEVEN_DAYS);
          scheduleDaysOfWeek = getScheduleDaysOfWeek(nextSevenDays, ZERO);
        }
        break;
      case SCHEDULE_STEP_TAB_KEY.NEXT_WEEK:
        {
          const nextSevenDays = getNumberOfDaysFromDate(firstDateNextWeek, SEVEN_DAYS);
          scheduleDaysOfWeek = getScheduleDaysOfWeek(nextSevenDays);
        }
        break;
      default:
        break;
    }

    setValue('daysOfWeek', [...scheduleDaysOfWeek]);
  };

  useEffect(() => {
    if (!daysOfWeek.length) {
      const nextSevenDays = getNumberOfDaysFromDate(firstDateNextWeek, SEVEN_DAYS);
      setValue('daysOfWeek', [...getScheduleDaysOfWeek(nextSevenDays)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scheduleStepTabs = {
    tabListLabel: intl.formatMessage({
      id: 'dashboard.bulk.schedule.scheduleKickOffStart',
    }),
    sxTabListWrapper: {
      padding: '40px',
      background: colors.linearPrimary2,
    },
    sxTabItem: {
      flex: 1,
    },
    active: scheduleTabId ?? SCHEDULE_STEP_TAB_KEY.NEXT_WEEK,
    items: [
      {
        label: 'dashboard.bulk.schedule.nextWeek',
        tabKey: SCHEDULE_STEP_TAB_KEY.NEXT_WEEK,
        component: (
          <ScheduleStepTab control={control} errors={errors} watch={watch} setValue={setValue} getValues={getValues} />
        ),
      },
      {
        label: 'dashboard.bulk.schedule.customize',
        tabKey: SCHEDULE_STEP_TAB_KEY.CUSTOMIZE,
        component: (
          <ScheduleStepTab control={control} watch={watch} errors={errors} setValue={setValue} getValues={getValues} />
        ),
        isCalendarMode: true,
      },
      {
        label: 'dashboard.bulk.schedule.today',
        tabKey: SCHEDULE_STEP_TAB_KEY.TODAY,
        component: (
          <ScheduleStepTab control={control} watch={watch} errors={errors} setValue={setValue} getValues={getValues} />
        ),
      },
    ],
    onChangeItem: handleOnChangeTab,
  };

  return <CustomTab {...scheduleStepTabs} />;
};

export default ScheduleStep;
