import { colors, fontWeight } from '@/styles/theme';

export const styles = {
  search: () => ({
    width: '100%',
    fontSize: '18px',
    fontWeight: fontWeight.bold,
    '& input::placeholder': {
      color: colors.secondaryLight4,
    },
  }),
};
