export const FIRE_BASE_ERROR_CODE = {
  LOGIN_FAILED: 10001,
  CREATE_TOKEN_FAILED: 10002,
  CHECK_PHONE_FAILED: 10005,
  REGISTER_FAILED: 10007,
  CHECK_ID_TOKEN_FAILED: 10008,
  ID_TOKEN_NOT_OF_PHONE: 10009,
  PHONE_NOT_EXISTED: 10011,
  CHANGE_PASSWORD_FAILED: 10013,
};

export const ERROR_CODE = {
  DUPLICATE_SCHOOL_NAME: 10309,
  PERMISSION_DENIED: 403,
  GENERATE_H5P_FAIL: 10008,
  USER_NOT_FOUND: 10207,
  GET_USER_FAIL: 10213,

  GET_CLASS_FAIL: 10902,
  CLASS_NOT_FAIL: 10907,
  GET_CLASS_STUDENT_FAIL: 10918,
  GET_USER_ORG_SCHOOL_FAIL: 11001,
  GET_HOMEWORK_FAIL: 30104,

  HOMEWORK_NOT_BELONG_TO_CURRENT_USER: 30107,
  ADD_ACTIVITY_FOR_HOMEWORK_FAIL: 30108,
  CANNOT_START_HOMEWORK_BEFORE_ITS_START_TIME: 30109,
  HOMEWORK_NOT_FOUND: 30111,
  STUDENT_IT_NOT_ALLOWED_DO_HOMEWORK: 30114,

  GET_HOMEWORK_STUDENT_FAIL: 30115,
  SCHEDULE_NOT_FOUND: 40001,
  GET_SCHEDULE_FAIL: 40002,

  ONLY_TEACHER_DO: 10106,
  GET_CLASS_TEACHER_FAIL: 10919,
  LESSON_NOT_FOUND: 30014,
  GET_LESSON_DETAIL_FAIL: 30015,
  ADD_ACTIVITY_FOR_LESSON_FAIL: 30019,

  TEACHER_IS_NOT_ALLOWED_TO_TEACH_THIS_LESSON: 30020,
  LESSON_NOT_BELONG_TO_CURRENT_USER_OR_SCHOOL: 30021,
  CANNOT_START_LESSON_BEFORE_ITS_START_TIME: 30022,
  CANNOT_START_LESSON_WITHOUT_STUDENTS: 30023,

  USERS_BULK_IMPORT_FAILED: 10229,
  USERS_BULK_IMPORT_FILE_ROWS_EXCEED: 11402,
  USERS_BULK_IMPORT_FILE_SIZE_EXCEED: 11411,
  USERS_BULK_IMPORT_FILE_EMPTY: 11412,

  USERS_BULK_IMPORT_INVALID_RECORDS: 11410,
  USERS_BULK_IMPORT_USER_NAME_REQUIRED: 10233,
  USERS_BULK_IMPORT_PHONE_REQUIRED: 10234,
  USERS_BULK_IMPORT_PHONE_INVALID: 10231,
  USERS_BULK_IMPORT_ROLE_REQUIRED: 10235,
  USERS_BULK_IMPORT_ROLE_ONLY_TEACHER_STUDENT: 11409,
  USERS_BULK_IMPORT_DATE_OF_BIRTH_INVALID: 10236,
  USERS_BULK_IMPORT_EMAIL_INVALID: 10230,
  USERS_BULK_IMPORT_GENDER_INVALID: 10232,
  USERS_BULK_IMPORT_DUPLICATE_FROM_FILE: 11407,
  USERS_BULK_IMPORT_USER_ALREADY_EXIST: 10201,

  SCHEDULES_BULK_CREATE_DUPLICATE_COURSE: 30203,
  SCHEDULE_DUPLICATED: 90005,
};

export const SUCCESS_CODE = {
  OK: 200,
};
