import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { TLookupFieldLoadMoreProps } from './LookupField.d';

import { styles } from './styles';

export const LookupFieldLoadMore: React.FC<TLookupFieldLoadMoreProps> = ({
  pagination,
  currentPage,
  disabled,
  onLoadMore,
}) => {
  const { totalPages, totalRows, pageSize } = pagination || {};

  const handleLoadMore = () => {
    if (!disabled) onLoadMore();
  };

  if (totalPages && currentPage < totalPages)
    return (
      <Box
        sx={styles.lastOption}
        className={classNames('load-more-btn', {
          disabled,
        })}
        onClick={handleLoadMore}
      >
        <Typography>
          <FormattedMessage id="common.loadMore" />
        </Typography>
      </Box>
    );

  if (totalRows && pageSize && currentPage * pageSize >= totalRows)
    return (
      <Typography sx={styles.lastOption} className="end-of-list">
        <FormattedMessage id="common.endOfList" />
      </Typography>
    );

  return <></>;
};
