import { ZERO } from '@/constants';
import { defaultClassRosterImportTableData } from './constants';

import { IBulkUploadUser } from '../classRosterImport/ClassRosterImport.d';
import { EUserRole } from '@/types/Dashboard';
import { IClassRosterImportClassTable } from './ClassRosterImportClassTable.d';
import { EClassType } from './enums';

const calcClassType = (classId: number, className: string) => {
  if (!classId) {
    if (className) return EClassType.NOT_MATCHING;
    return EClassType.NOT_ASSIGNED;
  }
  return EClassType.MATCHING;
};

export const mappingUsersIntoClasses = (users: IBulkUploadUser[]): IClassRosterImportClassTable[] => {
  // Group users by class name and class ID
  const classGroups: { [key: string]: IClassRosterImportClassTable } = {};

  for (const user of users) {
    const { className = '', classId = ZERO, role, error } = user;
    const key = `${className}_${classId}`;
    const classType = calcClassType(classId, className);

    if (!classGroups[key]) {
      classGroups[key] = {
        ...defaultClassRosterImportTableData,
        classType,
        className,
        classId,
        users: [],
      };
    }

    const currentClassGroup = classGroups[key];

    role === EUserRole.TEACHER ? currentClassGroup.totalTeachers++ : currentClassGroup.totalStudents++;
    Array.isArray(error) && error.length ? currentClassGroup.invalidRows++ : currentClassGroup.validRows++;
    currentClassGroup.users.push(user);
  }

  // Convert class groups object into the final class array and push not assigned to bottom
  const notAssignedClassGroups = [];
  const defaultClassGroups = [];
  for (const [, value] of Object.entries(classGroups)) {
    if (value.classType === EClassType.NOT_ASSIGNED) notAssignedClassGroups.push(value);
    else defaultClassGroups.push(value);
  }

  return [...defaultClassGroups, ...notAssignedClassGroups];
};
