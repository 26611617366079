import { FC } from 'react';
import { DialogContent, Box, Typography, DialogTitle } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { CustomDialog } from '@/components/customDialog/CustomDialog';

import scheduleIcon from '@/assets/images/icons/calendar-tick.svg';

import { TBulkScheduleLoadingDialogProps } from './BulkScheduleDialog.d';

import { styles } from './styles';
import ProgressBar from '@/components/progressBar/ProgressBar';

const BulkScheduleLoadingDialog: FC<TBulkScheduleLoadingDialogProps> = ({ open }) => {
  return (
    <CustomDialog position="middle" PaperProps={{ sx: styles.dialogWrapper }} open={open}>
      <DialogTitle sx={styles.dialogTitle}>
        <Box component="img" src={scheduleIcon} />
        <Typography sx={styles.dialogTitleText}>
          <FormattedMessage id="dashboard.bulk.schedule.bulkCreate" />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <ProgressBar sx={styles.progressBar} />
        <Typography>
          <FormattedMessage id="dashboard.bulk.schedule.warning.bulkCreateInProgress" />
        </Typography>
      </DialogContent>
    </CustomDialog>
  );
};

export default BulkScheduleLoadingDialog;
