import { FC, Suspense } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import get from 'lodash/get';

import Spinner from '../components/spinner/Spinner';
import ErrorBoundary from '@/components/ErrorBoundary';
import PrivateRoute from './PrivateRoute';
import PageNotFound from './errorManagement/PageNotFound';
import AnonymousUserLayout from '@/components/layout/anonymousLayout/AnonymousLayout';

/**
 * TODO will change the approach for this header layout
 */
import ManageDashboardHeader from '@/modules/dashboard/commonElementsManagement/components/defaultHeaderDashboard';
import ManagerUserHeader from '@/modules/dashboard/userManagement/userList/ManageUserHeader';
import ManageSchoolHeader from '@/modules/dashboard/schoolManagement/schoolList/ManageSchoolHeader';
import ClassRosterListHeader from '@/modules/classRoster/classRosterListing/ClassRosterListHeader';
import AdminScheduleListLayoutHeader from '@/modules/scheduleManagement/scheduleList/ScheduleListLayoutHeader';
import AdminScheduleHomeworkHeader from '@/pages/adminManagement/scheduleManagement/homework/ScheduleHomeworkHeader';
import AdminScheduleLessonHeader from '@/pages/adminManagement/scheduleManagement/lesson/ScheduleLessonHeader';

import RoutesString, { Pages } from './routesString';
import { PAGES_TITLE, PAGES_TITLE_HEADERS } from '@/constants/index';
import { LAYOUT_MODE } from '@/constants/enums';
import { useHelpDesk } from '@/utils/hooks/useHelpDesk';
import useProfileManagement from '@/store/profileManagement/ProfileManagement';
import { mapGetCurrentRoleName } from '@/utils/permissionUtil';

const CustomRoutes: FC = (): JSX.Element => {
  const [profileMgtState] = useProfileManagement();
  const { activeUser } = profileMgtState ?? {};
  const roleName = get(mapGetCurrentRoleName, `${activeUser?.roleId}`);

  useHelpDesk(roleName);

  /**
   * TO-DO: keep this navigate for trigger re-render, will look back soon
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();

  return (
    <ErrorBoundary>
      <Routes>
        {/* Anonymous Routes */}

        <Route
          index={true}
          element={
            <Suspense fallback={<Spinner />}>
              <AnonymousUserLayout title={PAGES_TITLE.HOME}>
                <Pages.Home />
              </AnonymousUserLayout>
            </Suspense>
          }
        />
        <Route
          path={RoutesString.Login}
          element={
            <Suspense fallback={<Spinner />}>
              <AnonymousUserLayout title={PAGES_TITLE.LOGIN}>
                <Pages.Login />
              </AnonymousUserLayout>
            </Suspense>
          }
        />
        <Route
          path={RoutesString.Register}
          element={
            <Suspense fallback={<Spinner />}>
              <AnonymousUserLayout title={PAGES_TITLE.REGISTER}>
                <Pages.Register />
              </AnonymousUserLayout>
            </Suspense>
          }
        />
        <Route
          path={RoutesString.ForgotPassword}
          element={
            <Suspense fallback={<Spinner />}>
              <AnonymousUserLayout title={PAGES_TITLE.FORGOT_PASSWORD}>
                <Pages.ForgotPassword />
              </AnonymousUserLayout>
            </Suspense>
          }
        />
        <Route
          path={RoutesString.Logout}
          element={
            <Suspense fallback={<Spinner />}>
              <Pages.Logout />
            </Suspense>
          }
        />

        <Route
          path={RoutesString.ClientPaymentPage}
          element={
            <Suspense fallback={<Spinner />}>
              <AnonymousUserLayout title={PAGES_TITLE.PAYMENT}>
                <Pages.ClientPaymentPage />
              </AnonymousUserLayout>
            </Suspense>
          }
        />

        {/* Admin Routes */}

        <Route
          path={RoutesString.Profile}
          element={
            <PrivateRoute
              exact={true}
              title={PAGES_TITLE.PROFILE}
              path={RoutesString.Profile}
              component={Pages.Profile}
              pageName={PAGES_TITLE.PROFILE}
              layoutMode={LAYOUT_MODE.NO}
            />
          }
        />

        <Route
          path={RoutesString.BusinessSelection}
          element={
            <PrivateRoute
              exact={true}
              title={PAGES_TITLE.BUSINESS_SELECTION}
              path={RoutesString.BusinessSelection}
              component={Pages.BusinessSelectionPage}
              pageName={PAGES_TITLE.BUSINESS_SELECTION}
              layoutMode={LAYOUT_MODE.NO}
            />
          }
        />

        <Route
          path={RoutesString.Dashboard}
          element={
            <PrivateRoute
              exact={true}
              title={PAGES_TITLE.DASHBOARD}
              path={RoutesString.Dashboard}
              component={Pages.Dashboard}
              pageName={PAGES_TITLE.DASHBOARD}
              headerTitleComponent={<ManageDashboardHeader />}
              layoutMode={LAYOUT_MODE.ADMIN}
            />
          }
        />
        <Route
          path={RoutesString.Library}
          element={
            <PrivateRoute
              exact={true}
              title={PAGES_TITLE.LIBRARY}
              path={RoutesString.Library}
              component={Pages.Library}
              pageName={PAGES_TITLE.LIBRARY}
              layoutMode={LAYOUT_MODE.ADMIN}
            />
          }
        />
        <Route
          path={RoutesString.ProgramDetail}
          element={
            <PrivateRoute
              exact={true}
              title={PAGES_TITLE.PROGRAM_DETAIL}
              path={RoutesString.ProgramDetail}
              component={Pages.ProgramDetail}
              pageName={PAGES_TITLE.PROGRAM}
              layoutMode={LAYOUT_MODE.ADMIN}
            />
          }
        />
        <Route
          path={RoutesString.UserDashboard}
          element={
            <PrivateRoute
              exact={true}
              title={PAGES_TITLE.USER}
              path={RoutesString.UserDashboard}
              component={Pages.UserDashboard}
              pageName={PAGES_TITLE.USER}
              headerTitleComponent={<ManagerUserHeader />}
              layoutMode={LAYOUT_MODE.ADMIN}
            />
          }
        />
        <Route
          path={RoutesString.SchoolDashboard}
          element={
            <PrivateRoute
              exact={true}
              title={PAGES_TITLE.SCHOOL}
              path={RoutesString.SchoolDashboard}
              component={Pages.SchoolDashboard}
              pageName={PAGES_TITLE.SCHOOL}
              headerTitleComponent={<ManageSchoolHeader />}
              layoutMode={LAYOUT_MODE.ADMIN}
            />
          }
        />
        <Route
          path={RoutesString.ClassRosterListDashboard}
          element={
            <PrivateRoute
              exact={true}
              title={PAGES_TITLE.CLASS_ROSTER}
              path={RoutesString.ClassRosterListDashboard}
              component={Pages.ClassRosterListDashboard}
              pageName={PAGES_TITLE.CLASS_ROSTER}
              headerTitleComponent={<ClassRosterListHeader />}
              layoutMode={LAYOUT_MODE.ADMIN}
            />
          }
        />

        <Route
          path={RoutesString.AdminScheduleListingPage}
          element={
            <PrivateRoute
              exact={true}
              title={PAGES_TITLE.SCHEDULE}
              path={RoutesString.AdminScheduleListingPage}
              component={Pages.AdminScheduleListingPage}
              pageName={PAGES_TITLE.SCHEDULE}
              headerTitleComponent={<AdminScheduleListLayoutHeader />}
              layoutMode={LAYOUT_MODE.ADMIN}
              scrollToTopOnMount={true}
            />
          }
        />

        <Route
          path={RoutesString.AdminScheduleHomeworkCreatePage}
          element={
            <PrivateRoute
              exact={true}
              title={PAGES_TITLE.SCHEDULE}
              path={RoutesString.AdminScheduleHomeworkCreatePage}
              component={Pages.AdminScheduleHomeworkCreatePage}
              pageName={PAGES_TITLE.SCHEDULE}
              headerTitleComponent={<AdminScheduleHomeworkHeader />}
              layoutMode={LAYOUT_MODE.ADMIN}
            />
          }
        />
        <Route
          path={RoutesString.AdminScheduleLessonCreatePage}
          element={
            <PrivateRoute
              exact={true}
              title={PAGES_TITLE.SCHEDULE}
              path={RoutesString.AdminScheduleLessonCreatePage}
              component={Pages.AdminScheduleLessonCreatePage}
              pageName={PAGES_TITLE.SCHEDULE}
              headerTitleComponent={<AdminScheduleLessonHeader />}
              layoutMode={LAYOUT_MODE.ADMIN}
            />
          }
        />

        <Route
          path={RoutesString.AdminLearningRoute}
          element={
            <PrivateRoute
              exact={true}
              title={PAGES_TITLE.LEARNING}
              path={RoutesString.AdminLearningRoute}
              component={Pages.ClientLearningPage}
              pageName={PAGES_TITLE.LEARNING}
              layoutMode={LAYOUT_MODE.ADMIN}
            />
          }
        />

        {/* Client Routes */}

        <Route
          path={RoutesString.ClientHome}
          element={
            <PrivateRoute
              exact={true}
              title={PAGES_TITLE.DASHBOARD}
              path={RoutesString.ClientHome}
              component={Pages.ClientHomePage}
              pageName={PAGES_TITLE.DASHBOARD}
              titleHeader={PAGES_TITLE_HEADERS.CLIENT_HOME_PAGE}
              layoutMode={LAYOUT_MODE.CLIENT}
            />
          }
        />

        <Route
          path={RoutesString.ClientScheduleListingPage}
          element={
            <PrivateRoute
              exact={true}
              title={PAGES_TITLE.SCHEDULE}
              path={RoutesString.ClientScheduleListingPage}
              component={Pages.ClientScheduleListingPage}
              pageName={PAGES_TITLE.SCHEDULE}
              isShowHeader={false}
              layoutMode={LAYOUT_MODE.CLIENT}
              scrollToTopOnMount={true}
            />
          }
        />

        <Route
          path={RoutesString.ClientPrograms}
          element={
            <PrivateRoute
              exact={true}
              title={PAGES_TITLE.PROGRAM}
              path={RoutesString.ClientPrograms}
              component={Pages.ClientProgramsPage}
              pageName={PAGES_TITLE.PROGRAM}
              titleHeader={PAGES_TITLE_HEADERS.CLIENT_PROGRAMS_PAGE}
              layoutMode={LAYOUT_MODE.CLIENT}
            />
          }
        />
        <Route
          path={RoutesString.ClientProgramDetail}
          element={
            <PrivateRoute
              exact={true}
              title={PAGES_TITLE.PROGRAM_DETAIL}
              path={RoutesString.ClientProgramDetail}
              component={Pages.ProgramDetail}
              pageName={PAGES_TITLE.PROGRAM_DETAIL}
              titleHeader={PAGES_TITLE_HEADERS.CLIENT_PROGRAMS_PAGE}
              layoutMode={LAYOUT_MODE.CLIENT}
            />
          }
        />

        <Route
          path={RoutesString.ClientLearningRoute}
          element={
            <PrivateRoute
              exact={true}
              title={PAGES_TITLE.LEARNING}
              path={RoutesString.ClientLearningRoute}
              component={Pages.ClientLearningPage}
              pageName={PAGES_TITLE.LEARNING}
              isShowHeader={false}
              layoutMode={LAYOUT_MODE.CLIENT}
            />
          }
        />

        <Route
          path={RoutesString.ClientClassRosterList}
          element={
            <PrivateRoute
              exact={true}
              title={PAGES_TITLE.CLASS_ROSTER}
              path={RoutesString.ClientClassRosterList}
              component={Pages.ClientClassRosterListPage}
              pageName={PAGES_TITLE.CLASS_ROSTER}
              titleHeader={PAGES_TITLE_HEADERS.CLASS_ROSTER_PAGE}
              layoutMode={LAYOUT_MODE.CLIENT}
            />
          }
        />

        {/* Error Routes */}

        <Route
          path={RoutesString.PermissionDenied}
          element={
            <PrivateRoute
              exact={true}
              title={PAGES_TITLE.PERMISSION_DENIED}
              path={RoutesString.PermissionDenied}
              component={Pages.PermissionDenied}
              pageName={PAGES_TITLE.PERMISSION_DENIED}
              layoutMode={LAYOUT_MODE.NO}
            />
          }
        />

        <Route
          path={RoutesString.CheckPayment}
          element={
            <PrivateRoute
              exact={true}
              title={PAGES_TITLE.CHECK_PAYMENT}
              path={RoutesString.CheckPayment}
              component={Pages.CheckPayment}
              pageName={PAGES_TITLE.CHECK_PAYMENT}
              layoutMode={LAYOUT_MODE.NO}
            />
          }
        />

        <Route
          path={RoutesString.Support}
          element={
            <PrivateRoute
              exact={true}
              title={PAGES_TITLE.SUPPORT}
              path={RoutesString.Support}
              component={Pages.SupportPage}
              pageName={PAGES_TITLE.SUPPORT}
              layoutMode={LAYOUT_MODE.NO}
            />
          }
        />

        <Route key="page-not-found" path="*" element={<PageNotFound />} />
      </Routes>
    </ErrorBoundary>
  );
};

export default CustomRoutes;
