import { FC } from 'react';

import { renderLookupFieldOption } from '@/utils/commonUtil';
import { ILookupFieldItem } from './LookupField.d';

const LookupFieldItem: FC<ILookupFieldItem> = ({ option, itemDataKeys, ...restProps }) => {
  return <li {...restProps}>{renderLookupFieldOption({ option, itemDataKeys })}</li>;
};

export default LookupFieldItem;
