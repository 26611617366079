import { FC, useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import LeftMenuClient from '@/components/layout/clientLayout/leftMenuClient';
import ClientHeader from './clientHeader';
import { IClientLayout } from './ClientLayout';
import ScrollToTopBtn from '@/components/scrollToTop/';

import RoutesString from '@/pages/routesString';
import useProfileManagement from '@/store/profileManagement/ProfileManagement';
import useAPIErrorManagement from '@/store/apiErrorManagement/APIErrorManagement';
import { ERROR_CODE } from '@/constants/apiCode';
import { useToast } from '@/modules/dashboard/commonElementsManagement/providers/ToastProvider';
import { checkCurrentMonthPaidStatus } from '@/utils/commonUtil';
import { checkAccessRole, isStudentRole } from '@/utils/validationUtils';
import { clientRoleArr, ZERO } from '@/constants';

import { styles } from './styles';

const ClientLayout: FC<IClientLayout> = ({ children, isShowHeader = true, titleHeader, scrollToTopOnMount }) => {
  const navigate = useNavigate();
  const [profileMgtState] = useProfileManagement();
  const { activeUser } = profileMgtState ?? {};
  const {
    roleId = ZERO,
    requireSubscription,
    isCurrentMonthPaid,
    nearestPaidMonth,
    nearestPaidYear,
  } = activeUser ?? {};
  const [state] = useAPIErrorManagement();
  const { showError } = useToast();
  useEffect(() => {
    const { statusCode, msg } = state;
    if (statusCode === ERROR_CODE.PERMISSION_DENIED) {
      navigate(RoutesString.PermissionDenied);
      msg && showError(msg);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (!activeUser || !roleId) navigate(RoutesString.UserProfile);
    if (roleId && !checkAccessRole(clientRoleArr, `${roleId}`)) navigate(RoutesString.Library);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const allowToAccess = checkCurrentMonthPaidStatus({
      requireSubscription,
      isCurrentMonthPaid,
      nearestPaidMonth,
      nearestPaidYear,
    });

    isStudentRole(roleId) && !allowToAccess && navigate(RoutesString.CheckPayment);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUser]);

  return (
    <Box sx={styles.container}>
      <LeftMenuClient roleId={roleId} />
      <Box
        sx={styles.blockHeader}
        className={classNames({
          spacing: isShowHeader,
        })}
      >
        {isShowHeader && <ClientHeader titleHeader={titleHeader} />}
        {children}
        {scrollToTopOnMount && <ScrollToTopBtn />}
      </Box>
    </Box>
  );
};

export default ClientLayout;
