/* eslint-disable radix */
import React, { FC, useEffect, useState } from 'react';
import { Box, DialogActions, DialogContent, Grid, Slide, Typography } from '@mui/material';
import classNames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import get from 'lodash/get';

import { CustomDialog } from '@/components/customDialog/CustomDialog';
import LessonModuleDialog from '@/components/schedule/lessonDialog/lessonModule';
import LessonContentDialog from '@/components/schedule/lessonDialog/lessonContent';
import HorizontalStepper from '@/components/stepper/horizontal';
import FormTitle from './FormTitle';
import OverviewStep from './OverviewStep';
import { FormButton } from '@/components/formButton';
import ScheduleStep from './ScheduleStep';
import SettingStep from './SettingStep';

import { FIRST_ITEM_NUMBER, NUMBER_ONE, NUMBER_SECOND, ZERO } from '@/constants';
import { bulkScheduleSteps, defaultSelectedProgram, defaultBulkScheduleValues, BULK_SCHEDULE_STEP } from '../constants';
import useLessonContentOfUnit from '@/utils/hooks/dashboard/useLessonContentOfUnit';
import { useConfirmDialog } from '@/modules/dashboard/commonElementsManagement/providers/ConfirmDialogProvider';

import { IBulkScheduleValues, ISelectedProgram, TBulkScheduleFormProps, TSelectedLesson } from './BulkScheduleForm.d';

import { styles } from './styles';
import Spinner from '@/components/spinner/Spinner';
import BulkScheduleConfirmDialog from './BulkScheduleConfirmDialog';
import BulkScheduleKeepLessons from './BulkScheduleKeepLessons';
import attachedIcon from '@/assets/images/icons/attached.svg';

const BulkScheduleForm: FC<TBulkScheduleFormProps> = ({ open, handleOnSubmit, handleOnConfirmCancel }) => {
  const intl = useIntl();
  const { openConfirmation } = useConfirmDialog();

  const {
    reset,
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    clearErrors,
    trigger,
    formState: { errors, isDirty },
  } = useForm<IBulkScheduleValues>({
    defaultValues: defaultBulkScheduleValues,
  });
  const [showLessonBlock, setShowLessonBlock] = useState(false);
  const [showLessonContent, setShowLessonContent] = useState(false);
  const { steps, currentStep } = bulkScheduleSteps;
  const maxStep = steps.length - NUMBER_ONE;
  const [activeStep, setActiveStep] = useState(currentStep);
  const [selectedProgram, setSelectedProgram] = useState<ISelectedProgram>(defaultSelectedProgram);
  const [selectedUnitIndex, setSelectedUnitIndex] = useState(ZERO);

  const [homeworkMode, setHomeworkMode] = useState(false);
  const [selectedLessons, setSelectedLessons] = useState<TSelectedLesson[]>([]);
  const [selectedHomeworks, setSelectedHomeworks] = useState<TSelectedLesson[]>([]);

  const selectedUnits = selectedProgram.units;
  const selectedUnit = selectedUnits[selectedUnitIndex] || { ageGroupId: ZERO, lessonModuleId: ZERO };
  const { data: unitDetail, isFetching: isFetchingUnitDetail } = useLessonContentOfUnit(
    selectedUnit.ageGroupId,
    selectedUnit.lessonModuleId,
  );
  const lessonContentDialogData = {
    lessonModuleName: get(unitDetail, 'lessonModuleName') ?? '',
    lessons: get(unitDetail, 'lessonContents') ?? [],
    unitId: get(unitDetail, 'lessonModuleId') ?? ZERO,
    unitOrdinal: get(unitDetail, 'lessonModuleOrdinalLabel') ?? `${ZERO}`,
  };
  const watchProgramId = watch('programId');
  const watchEnableHomework = watch('attachHomework.enable');
  const courseName = getValues('courseName');
  const showNext = selectedUnitIndex < selectedUnits.length - FIRST_ITEM_NUMBER;
  const showPrev = selectedUnitIndex > ZERO;

  useEffect(() => {
    const isOverview = activeStep === BULK_SCHEDULE_STEP.OVERVIEW && watchProgramId;
    const isSetting = activeStep === BULK_SCHEDULE_STEP.SETTING && watchEnableHomework;

    clearErrors('units');

    setShowLessonBlock(Boolean(isOverview || isSetting));
    setHomeworkMode(isSetting);
    if (activeStep !== BULK_SCHEDULE_STEP.SETTING || !watchEnableHomework) {
      setValue('attachHomework.enable', false);
      setSelectedHomeworks([]);
    }

    if (watchEnableHomework && activeStep === BULK_SCHEDULE_STEP.SETTING) {
      openConfirmation({
        typeDialog: 'middle',
        typeFooterText: 'uppercase',
        iconSrc: attachedIcon,
        hasCloseBtn: false,
        title: intl.formatMessage({
          id: 'dashboard.bulk.schedule.attached.homework',
        }),
        description: <BulkScheduleKeepLessons />,
        textYes: intl.formatMessage({
          id: 'dashboard.bulk.schedule.default.homework.list',
        }),
        textNo: intl.formatMessage({
          id: 'dashboard.bulk.schedule.re.pick.homework.list',
        }),
      })
        .then(() => {
          setSelectedHomeworks(selectedLessons);
        })
        .catch((error) => {
          console.warn(error);
        });
    }
    setShowLessonContent(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, watchEnableHomework]);

  const handleOnToggleViewDetail = (toggle: boolean) => {
    setShowLessonContent(toggle);
  };

  const handleOnViewUnitDetail = (index: number) => {
    handleOnToggleViewDetail(true);
    setSelectedUnitIndex(index);
  };

  const handlePrevUnitDetail = () => {
    if (showPrev) setSelectedUnitIndex(selectedUnitIndex - FIRST_ITEM_NUMBER);
  };

  const handleNextUnitDetail = () => {
    if (showNext) setSelectedUnitIndex(selectedUnitIndex + FIRST_ITEM_NUMBER);
  };

  const handleOnChangeStep = (newStep?: number) => {
    if (typeof newStep === 'number') {
      return setActiveStep(newStep);
    }
    if (activeStep < maxStep) setActiveStep(activeStep + NUMBER_ONE);
  };

  const formattedUnitDataValue = (lessons: TSelectedLesson[], homeworks?: TSelectedLesson[]) => {
    return lessons.map((lesson, index) => {
      const { unitId, lessonContentId } = lesson;
      return {
        unitId: Number(unitId),
        lessonContentId,
        homeworkContentId: homeworks?.[index]?.lessonContentId ?? ZERO,
      };
    });
  };

  const sortAsUnitOrderForLessonLists = () => {
    const sortDataLesson = selectedLessons.sort((a, b) => {
      return parseInt(a.unitOrdinalLabel) - parseInt(b.unitOrdinalLabel);
    });
    const sortDataHomework = selectedHomeworks.sort((a, b) => {
      return parseInt(a.unitOrdinalLabel) - parseInt(b.unitOrdinalLabel);
    });
    setSelectedLessons(sortDataLesson);
    setSelectedHomeworks(sortDataHomework);
  };

  const handleNextStep = async () => {
    let isValid = true;

    if (activeStep === BULK_SCHEDULE_STEP.OVERVIEW) {
      setValue('units', formattedUnitDataValue(selectedLessons, selectedHomeworks));
    }

    switch (activeStep) {
      case BULK_SCHEDULE_STEP.OVERVIEW:
        isValid = await trigger(['classId', 'programId', 'courseName', 'units']);
        break;

      case BULK_SCHEDULE_STEP.SCHEDULE:
        isValid = await trigger(['daysOfWeek', 'startDate', 'globalTime']);
        break;

      case BULK_SCHEDULE_STEP.SETTING:
        isValid = await trigger(['attachHomework.durationDay', 'units']);
        break;

      default:
        break;
    }
    if (isValid) {
      if (activeStep === BULK_SCHEDULE_STEP.SETTING) {
        sortAsUnitOrderForLessonLists();
        setValue('units', formattedUnitDataValue(selectedLessons, selectedHomeworks));

        if (watchEnableHomework) {
          return openConfirmation({
            typeDialog: 'middle',
            isShowIconTitle: false,
            typeFooterText: 'uppercase',
            hasCloseBtn: false,
            title: intl.formatMessage({
              id: 'dashboard.bulk.schedule.confirmation.dialog.title',
            }),
            description: (
              <BulkScheduleConfirmDialog
                lessons={selectedLessons}
                homeworks={selectedHomeworks}
                lessonModuleName={lessonContentDialogData.lessonModuleName}
                courseName={courseName}
              />
            ),
            textYes: intl.formatMessage({
              id: 'common.confirm',
            }),
            textNo: intl.formatMessage({
              id: 'common.cancel',
            }),
          })
            .then(() => {
              handleSubmit(handleOnSubmit)();
            })
            .catch((error) => {
              console.warn(error);
            });
        }
        return handleSubmit(handleOnSubmit)();
      }
      return handleOnChangeStep();
    }
  };

  const handleModifyLessonList = (lessons: TSelectedLesson[]) => {
    if (homeworkMode) return setSelectedHomeworks(lessons);
    return setSelectedLessons(lessons);
  };

  const handleCloseBulkScheduleForm = () => {
    setShowLessonBlock(false);
    handleOnConfirmCancel && handleOnConfirmCancel();
  };

  const handleOnCancel = () => {
    if (!isDirty && handleOnConfirmCancel) return handleCloseBulkScheduleForm();

    openConfirmation({
      typeDialog: 'middle',
      typeFooterText: 'uppercase',
      description: intl.formatMessage({
        id: 'schedule.toast.confirmCancel.description',
      }),
    })
      .then(() => {
        if (handleOnConfirmCancel) handleCloseBulkScheduleForm();
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const handleOnClickProgramName = () => {
    if (showLessonContent) handleOnToggleViewDetail(false);
  };

  const renderStep = () => {
    let result;
    switch (activeStep) {
      case NUMBER_ONE:
        result = (
          <ScheduleStep control={control} errors={errors} watch={watch} setValue={setValue} getValues={getValues} />
        );
        break;
      case NUMBER_SECOND:
        result = <SettingStep control={control} errors={errors} watch={watch} setValue={setValue} />;
        break;
      default:
        result = (
          <OverviewStep
            control={control}
            errors={errors}
            watch={watch}
            setSelectedProgram={setSelectedProgram}
            setShowLessonBlock={setShowLessonBlock}
            setValue={setValue}
            onModifyLessonList={handleModifyLessonList}
          />
        );
        break;
    }

    return result;
  };

  useEffect(() => {
    if (!open) {
      reset();
      setActiveStep(BULK_SCHEDULE_STEP.OVERVIEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    setSelectedUnitIndex(ZERO);
  }, [selectedProgram.programId]);

  return (
    <CustomDialog
      open={open}
      sx={styles.dialogWrapper}
      className={classNames({
        'show-lesson-info': showLessonBlock,
      })}
    >
      <DialogContent>
        <Grid container={true} height="100%">
          {showLessonBlock && (
            <Slide direction="up" in={showLessonBlock}>
              <Grid item={true} xs={5}>
                <Box sx={styles.headerWrapper}>
                  <Typography
                    sx={{ ...styles.headerTitle, ...(showLessonContent && { cursor: 'pointer' }) }}
                    onClick={handleOnClickProgramName}
                  >
                    {selectedProgram.programName}
                  </Typography>
                </Box>

                <Controller
                  name="units"
                  control={control}
                  rules={{
                    validate: {
                      minLength: (value) => {
                        if (watchEnableHomework) {
                          return (
                            value.length === selectedHomeworks.length || 'dashboard.not.equal.quant.homeworks.lessons'
                          );
                        }
                        return value.length >= NUMBER_ONE || 'dashboard.bulk.schedule.units.required';
                      },
                    },
                  }}
                  render={({ fieldState: { error } }) => {
                    const errorMsg = get(error, 'message');
                    return (
                      <Box>
                        {isFetchingUnitDetail ? (
                          <Box position="relative">
                            <Spinner />
                          </Box>
                        ) : showLessonContent && lessonContentDialogData.lessons.length ? (
                          <LessonContentDialog
                            {...lessonContentDialogData}
                            showNext={showNext}
                            showPrev={showPrev}
                            onPrev={handlePrevUnitDetail}
                            onNext={handleNextUnitDetail}
                            selectedLessons={homeworkMode ? selectedHomeworks : selectedLessons}
                            unitId={lessonContentDialogData.unitId}
                            onModifyLessonList={handleModifyLessonList}
                            unitOrdinal={lessonContentDialogData.unitOrdinal}
                          />
                        ) : (
                          <LessonModuleDialog
                            {...selectedProgram}
                            isHomework={homeworkMode}
                            onViewUnitDetail={handleOnViewUnitDetail}
                            selectedUnits={selectedLessons}
                            selectedHomeworks={selectedHomeworks}
                          />
                        )}
                        {errorMsg && (
                          <Box sx={styles.error}>
                            <span className="errorMsg">
                              <FormattedMessage id={errorMsg} />
                            </span>
                          </Box>
                        )}
                      </Box>
                    );
                  }}
                />
              </Grid>
            </Slide>
          )}
          <Grid item={true} xs={!showLessonBlock ? 12 : 7} sx={styles.borderLeft}>
            <FormTitle />
            <HorizontalStepper {...bulkScheduleSteps} currentStep={activeStep} onClick={handleOnChangeStep} />
            {renderStep()}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container={true}>
          {showLessonBlock && <Grid item={true} xs={5} />}
          <Grid
            item={true}
            xs={!showLessonBlock ? 12 : 7}
            sx={{ ...styles.borderLeft, ...styles.bulkScheduleFormAction }}
          >
            <FormButton variant="outlined" sx={styles.btnFooter} onClick={handleOnCancel}>
              <FormattedMessage id="common.cancel" />
            </FormButton>
            <FormButton variant="contained" sx={styles.btnFooter} onClick={handleNextStep}>
              <FormattedMessage id={activeStep === BULK_SCHEDULE_STEP.SETTING ? 'common.complete' : 'common.next'} />
            </FormButton>
          </Grid>
        </Grid>
      </DialogActions>
    </CustomDialog>
  );
};

export default BulkScheduleForm;
