export enum SORT_STATUS {
  ASC = 'asc',
  DESC = 'desc',
}

export enum DIALOG_POSITION {
  BOTTOM_RIGHT = 'bottom-right',
  MIDDLE = 'middle',
}

export enum DIALOG_ACTION_DIRECTION {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export enum COMMON_ACTION {
  NEXT = 'next',
  PREVIOUS = 'previous',
}

export enum DASHBOARD_MODE {
  CLIENT = 'client',
  ADMIN = 'admin',
}

export enum COMMON_VALUE {
  ALL = 'all',
}

export enum OTP_STEP {
  REQUEST = 'request',
  VERIFY = 'verify',
  FINISH = 'finish',
}

export enum OTP_STATUS {
  IDLE = 'idle',
  INITIALIZING = 'initializing',
  REQUESTING = 'requesting',
  VERIFYING = 'verifying',
}

export enum CUSTOM_BADGE_COLORS {
  GREEN_1 = 'green-1',
  GREEN_2 = 'green-2',
}

export enum PERMISSION {
  // School
  VIEW_SCHOOL = 'view-school',
  NEW_SCHOOL = 'new-school',
  EDIT_SCHOOL = 'edit-school',
  DELETE_SCHOOL = 'delete-school',
}

export enum ELocaleCode {
  VI = 'vi',
  EN = 'en',
}

export enum ELanguageName {
  VI = 'Vietnamese',
  EN = 'English',
}

export enum ELanguageShortName {
  VI = 'VN',
  EN = 'EN',
}

export enum PHONE_NUMBER_COUNTRY_CODE {
  VN = '+84',
}

export enum AUTH_STATUS_STATE {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export enum LAYOUT_MODE {
  CLIENT = 'client',
  ADMIN = 'admin',
  NO = 'no',
}

export enum EFileExtension {
  XLS = '.xls',
  CSV = '.csv',
  XLSX = '.xlsx',
  JPG = '.jpg',
  PNG = '.png',
  HTML = '.html',
  HTM = '.htm',
}

export enum CHARACTERS {
  TURTLE = 'turtle',
  RABBIT = 'rabbit',
}

export enum ECalendarMode {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export enum EScheduleType {
  HOMEWORK = 'homework',
  LESSON = 'lesson',
}

export enum EFullCalendarLocaleCode {
  VI = 'vi',
  EN_GB = 'en-gb',
}

export enum OTP_PROVIDER {
  FIREBASE = 'firebase',
  ESMS = 'esms',
}

export enum TAG_HELP_DESK {
  ADMIN = 'admin',
  TEACHER = 'teacher',
  STUDENT = 'student',
  WEB_PLATFORM = 'web',
}
