import { FC } from 'react';
import { DialogActions, DialogContent, DialogContentText, Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { CustomDialog } from '@/components/customDialog/CustomDialog';
import { FormButton } from '@/components/formButton';
import ProgressBar from '@/components/progressBar/ProgressBar';

import { TClassRosterConfirmImportDialogProps } from './ClassRosterImport.d';

import importIcon from '@/assets/images/icons/cloud-upload-blue.svg';

import { classRosterImportDialogStyles as styles } from './styles';

const ClassRosterConfirmImportDialog: FC<TClassRosterConfirmImportDialogProps> = ({
  description,
  loading,
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <CustomDialog position="middle" PaperProps={{ sx: styles.dialogWrapper }} open={open}>
      <DialogContent sx={styles.dialogContent}>
        <Box component="img" src={importIcon} />
        {loading ? (
          <>
            <DialogContentText sx={styles.dialogImportLabel}>
              <FormattedMessage id="common.importing" />
            </DialogContentText>
            <ProgressBar sx={styles.loadingWrapper} />
          </>
        ) : (
          <>
            {open && (
              <>
                <DialogContentText sx={styles.dialogImportLabel}>
                  <FormattedMessage id="dashboard.classRoster.importConfimation.title" />
                </DialogContentText>
                {description && (
                  <DialogContentText sx={styles.dialogImportDescription}>{description}</DialogContentText>
                )}
              </>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions sx={styles.dialogAction}>
        {!loading ? (
          <>
            <FormButton variant="contained" onClick={onConfirm}>
              <FormattedMessage id="common.yes" />
            </FormButton>
            <FormButton variant="outlined" onClick={onClose}>
              <FormattedMessage id="common.no" />
            </FormButton>
          </>
        ) : (
          <>
            {open && (
              <Typography sx={styles.dialogImportDescription}>
                <FormattedMessage id="common.warning.importingInProgress" />
              </Typography>
            )}
          </>
        )}
      </DialogActions>
    </CustomDialog>
  );
};

export default ClassRosterConfirmImportDialog;
