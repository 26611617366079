import { FC } from 'react';
import { Box, Container, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { ILessonModuleDialog } from './LessonModule.d';

import folder from '@/assets/images/icons/folder-size-20.svg';

import { styles } from './styles';
import { SYMBOLS } from '@/constants';

const LessonModuleDialog: FC<ILessonModuleDialog> = ({
  width,
  units,
  onViewUnitDetail,
  isHomework,
  selectedUnits,
  selectedHomeworks,
}) => {
  const handleOnViewDetail = (index: number) => {
    if (onViewUnitDetail) onViewUnitDetail(index);
  };

  const getLengthLessonListInCurrentLesson = (currentUnitId: number) => {
    const unitData = isHomework ? selectedHomeworks : selectedUnits;
    const selectedLessonListInUnit = unitData.filter((unit) => unit.unitId === currentUnitId);
    return selectedLessonListInUnit.length;
  };

  return (
    <Container sx={{ ...styles.lessonModuleWrapper, width }}>
      {Array.isArray(units) && units.length
        ? units.map((unit, index) => {
            const { lessonModuleId, lessonModuleName, lessonContents } = unit;

            return (
              <Grid
                container={true}
                sx={styles.unitDetailWrapper}
                key={`lesson-item-${lessonModuleId}-${lessonModuleName}`}
              >
                <Grid item={true} xs={10} sx={styles.unitName}>
                  <Box>
                    {lessonModuleName}
                    {SYMBOLS.COLON} {(lessonContents ?? []).length}{' '}
                    <FormattedMessage id="dashboard.bulk.schedule.lessons" />
                  </Box>
                  <Box sx={styles.chosenLessons}>
                    <FormattedMessage id="dashboard.bulk.schedule.chosen" />{' '}
                    {getLengthLessonListInCurrentLesson(lessonModuleId)}{' '}
                    <FormattedMessage
                      id={isHomework ? 'dashboard.bulk.schedule.homeworks' : 'dashboard.bulk.schedule.lessons'}
                    />
                  </Box>
                </Grid>
                <Grid item={true} xs={2} sx={styles.unitDetail} onClick={() => handleOnViewDetail(index)}>
                  <Box component="img" src={folder} />
                </Grid>
              </Grid>
            );
          })
        : null}
    </Container>
  );
};

export default LessonModuleDialog;
