import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { UserMenu } from '@/modules/dashboard/commonElementsManagement/userMenu';
import { styles } from './styles';
import { IClientHeader } from '../ClientLayout';

const ClientHeader: FC<IClientHeader> = ({ titleHeader }) => {
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>{titleHeader && <FormattedMessage id={titleHeader} />}</Typography>

      <Box mr="12px" />

      <UserMenu />
    </Box>
  );
};

export default ClientHeader;
