import {
  createContext,
  ReactNode,
  useContext,
  Dispatch,
  SetStateAction,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';

import { ConfirmDialog, ConfirmationOptions } from '@/components/customDialog/confirmDialog';

interface Props {
  children: ReactNode | ReactNode[];
}

type TypeContext = {
  openConfirmation: (_options: ConfirmationOptions) => Promise<void>;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
};

export const ConfirmDialogContext = createContext<TypeContext>({
  openConfirmation: Promise.reject,
  setOpenDialog: () => {},
});

export const ConfirmDialogProvider = ({ children }: Props) => {
  const [confirmationState, setConfirmationState] = useState<ConfirmationOptions | null>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const awaitingPromiseRef = useRef<{
    resolve: () => void;
    reject: () => void;
  }>();

  const openConfirmation = useCallback((options: ConfirmationOptions) => {
    setOpenDialog(true);
    setConfirmationState(options);

    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  }, []);

  const confirmDialogContextProviderValue = useMemo(
    () => ({ openConfirmation, setOpenDialog }),
    [openConfirmation, setOpenDialog],
  );

  const handleClose = () => {
    if (confirmationState && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setOpenDialog(false);
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    setOpenDialog(false);
  };

  return (
    <>
      <ConfirmDialogContext.Provider value={confirmDialogContextProviderValue}>
        {children}
      </ConfirmDialogContext.Provider>

      <ConfirmDialog open={openDialog} onSubmit={handleSubmit} onClose={handleClose} {...confirmationState} />
    </>
  );
};

export const useConfirmDialog = () => useContext(ConfirmDialogContext);
