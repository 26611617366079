import axios from 'axios';
import qs from 'query-string';
import get from 'lodash/get';

import { APIFunction, ErrorObjectType } from './api.d';
import { GET_METHOD } from './api.constants';
import './interceptors';

export const API_ERROR_MESSAGE_GENERAL = 'Oops. Something wrong happened';
export const ERROR_MESSAGE_NO_NETWORK = 'ERROR_MESSAGE_NO_NETWORK';

let isOnline: boolean = navigator.onLine;

window.addEventListener('offline', () => {
  isOnline = false;
});

window.addEventListener('online', () => {
  isOnline = true;
});

const api: APIFunction = async ({
  url,
  params = '',
  method = GET_METHOD,
  headers = {},
  data = '',
  responseType,
  cancelTokenSource,
  onUploadProgress,
}) => {
  const newParams = qs.parse(qs.stringify(params as object, { arrayFormat: 'comma' }));
  try {
    const response = await axios({
      method,
      url,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...headers,
      },
      responseType,
      params: newParams,
      data,
      cancelToken: cancelTokenSource?.token,
      onUploadProgress,
    });
    return response;
  } catch (error: any) {
    if (axios.isCancel(error)) throw error;

    const response = get(error, 'response', {});
    const dataResponse = get(error, 'response.data', {});
    const dataResponseJson = dataResponse instanceof Blob ? JSON.parse(await dataResponse.text()) : dataResponse;

    const userMessage =
      get(dataResponseJson, 'userMessage', '') ||
      get(dataResponseJson, 'message', '') ||
      get(dataResponseJson, 'error', '');

    const errors = get(error, 'response.data.errors', null);
    let extraErrorMessage = '';
    if (errors) {
      extraErrorMessage = errors.map((err: ErrorObjectType) => err?.message).join('<br /> ');
      extraErrorMessage = `<br />${extraErrorMessage}`;
    }

    if (isOnline) {
      const errObject = {
        ...error,
        response: {
          ...response,
          data: {
            ...dataResponse,
            userMessage: `${userMessage}${extraErrorMessage}`,
          },
        },
      };

      throw errObject;
    } else {
      const offlineResponse = {
        response: {
          data: {
            userMessage,
          },
        },
      };

      throw offlineResponse;
    }
  } finally {
    // dome something else
  }
};

export default api;
