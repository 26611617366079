import { isNumber, isBoolean, isString } from 'lodash';
import { IntlShape } from 'react-intl';

import REGEX from '@/constants/regex';
import { UserRoleEnum } from '@/types/Dashboard';

export const validatePasswordLength = (password: string): boolean => {
  const regexp = new RegExp(REGEX.PASSWORD_RANGE);
  return regexp.test(password);
};

export const validatePasswordAtLeastALetter = (password: string): boolean => {
  const regexp = new RegExp(REGEX.HAS_AT_LEAST_A_LETTER);
  return regexp.test(password);
};

export const validatePasswordAtLeastANumber = (password: string): boolean => {
  const regexp = new RegExp(REGEX.HAS_AT_LEAST_A_NUMBER);
  return regexp.test(password);
};

export const validatePhone = (phone: string): boolean => {
  const regexp = new RegExp(REGEX.PHONE_NUMBER);
  return regexp.test(phone);
};

export const atLeastRole = (roleId: number | undefined, lowestRole: UserRoleEnum): boolean => {
  if (!roleId) return false;

  return roleId <= Number(lowestRole);
};

export const isStudentRole = (roleId: number | undefined): boolean => {
  if (!roleId) return false;

  return roleId === Number(UserRoleEnum.STUDENT);
};

export const validateNumberLargerThan = (checkNumber: number | string, comparedNumber: number): boolean => {
  const numberTypeOfCheckNumber = Number(checkNumber);
  if (checkNumber && isNumber(numberTypeOfCheckNumber)) {
    return numberTypeOfCheckNumber > comparedNumber;
  }

  return false;
};

export const buildValidation = (validationValues: any, intl: IntlShape) => {
  if (isBoolean(validationValues) && validationValues) return validationValues;

  if (isString(validationValues))
    return intl.formatMessage({
      id: validationValues,
    });

  return true;
};

export const checkAccessRole = (roleIds: string[], currentRoleId: string) => roleIds.includes(currentRoleId);
