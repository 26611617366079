import { fontSize, lineHeight, fontWeight, colors } from '@/styles/theme';
import { TMuiStyle } from '@/types/Common';

export const styles: TMuiStyle = {
  customAdornment: {
    position: 'absolute',
    left: 0,
    top: 0,
    maxWidth: '152px',
    width: '100%',
    height: '100%',
    background: colors.white1,
    zIndex: 1,
    borderRadius: 'unset',
    fontSize: fontSize.textLG,
    lineHeight: lineHeight.titleXXS,
    fontWeight: fontWeight.normal,
    textTransform: 'capitalize',
  },
  activeAdornment: {
    background: colors.secondary1,
    color: colors.white1,
  },
};
