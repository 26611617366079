export const dateMonthYearSlashFormat = 'DD/MM/YYYY';
export const dateMonthYearHyphenFormat = 'DD-MM-YYYY';
export const dateMonthHyphenFormat = 'DD-MM';
export const yearMonthDateHyphenFormat = 'YYYY-MM-DD';
export const dateMonthYearDotFormat = 'DD.MM.YYYY';
export const dateMonthDotFormat = 'DD.MM';
export const dateMonthYearHoursMinsDotFormat = 'DD.MM.YYYY - HH:mm';
export const dateMonthStrFormat = 'MMM D';
export const dateMonthStrYearFormat = 'MMM D, YYYY';
export const yearMonthDateFormat = 'YYYY-MM-DD';
export const yearMonthDateHoursMinsFormat = 'YYYY-MM-DD HH:mm';
export const yearMonthDateFullTimeFormat = 'YYYY-MM-DD HH:mm:ss';
export const yearMonthDateHoursMinsAPMFormat = 'YYYY/MM/DD h:mm A';
export const hoursMinsFormat = 'HH:mm';
export const daysOfWeekFormat = 'ddd';
export const monthShortFormat = 'M';
export const SEVEN_DAYS = 7;
export const SYSTEM_DATE_START = '2000/01/01';
export const TIME_START = '00:00';

export const dateMonthYearHoursMinsHyphenFormat = 'DD-MM-YYYY HH:mm';

export const TYPE_DATE = {
  HOMEWORK_START_DATE: 'homeworkStartDate',
  HOMEWORK_START_TIME: 'homeworkStartTime',

  HOMEWORK_END_DATE: 'homeworkEndDate',
  HOMEWORK_END_TIME: 'homeworkEndTime',

  LESSON_START_DATE: 'lessonStartDate',
  LESSON_START_TIME: 'lessonStartTime',
};

export const DAY_IN_WEEK = {
  MONDAY: 'mon',
  TUESDAY: 'tue',
  WEDNESDAY: 'wed',
  THURSDAY: 'thu',
  FRIDAY: 'fri',
  SATURDAY: 'sat',
  SUNDAY: 'sun',
};

export const DAY_NUMBER = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 0,
};

export const MAPPING_DAY_NUMBER_TO_DAY_IN_WEEK = {
  [DAY_NUMBER.MONDAY]: DAY_IN_WEEK.MONDAY,
  [DAY_NUMBER.TUESDAY]: DAY_IN_WEEK.TUESDAY,
  [DAY_NUMBER.WEDNESDAY]: DAY_IN_WEEK.WEDNESDAY,
  [DAY_NUMBER.THURSDAY]: DAY_IN_WEEK.THURSDAY,
  [DAY_NUMBER.FRIDAY]: DAY_IN_WEEK.FRIDAY,
  [DAY_NUMBER.SATURDAY]: DAY_IN_WEEK.SATURDAY,
  [DAY_NUMBER.SUNDAY]: DAY_IN_WEEK.SUNDAY,
};
