import { Accept } from 'react-dropzone';

import { MATCHING_MIME_TYPE_TO_FILE_EXTENSION } from '@/constants/acceptType';
import { EFileExtension } from '@/constants/enums';

export const getAcceptedFileTypes = (acceptExtensions: EFileExtension[]) => {
  const acceptedFileTypes: Accept = {};

  acceptExtensions.forEach((extension) => {
    MATCHING_MIME_TYPE_TO_FILE_EXTENSION[extension].forEach((mimeType) => {
      if (!acceptedFileTypes[mimeType]) {
        acceptedFileTypes[mimeType] = [];
      }
      acceptedFileTypes[mimeType].push(extension);
    });
  });

  return acceptedFileTypes;
};
