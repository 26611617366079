import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';

import { ELocaleCode } from '@/constants/enums';
import useLanguageManagement from '@/store/languageManagement/LanguageManagement';
import { TProps, TLocaleContextProps, TMessage } from './LocaleProvider.d';

import english from '@/lang/en.json';
import vietnamese from '@/lang/vi.json';

const messages: TMessage = {
  en: english,
  vi: vietnamese,
};

const LocaleContext = createContext<TLocaleContextProps>({
  locale: ELocaleCode.EN,
  setLocale: () => {},
});

export const LocaleProvider = ({ children }: TProps) => {
  const [languageMgtState, languageMgtActions] = useLanguageManagement();
  const { localeCode } = languageMgtState;

  const setLocale = useCallback(
    (newLocaleCode: ELocaleCode) => {
      languageMgtActions.setLanguage({
        localeCode: newLocaleCode,
        initiated: false,
      });

      dayjs.locale(newLocaleCode);
    },
    [languageMgtActions],
  );

  const localeContextProps = useMemo(() => {
    return { setLocale, locale: localeCode };
  }, [setLocale, localeCode]);

  useEffect(() => {
    dayjs.locale(localeCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LocaleContext.Provider value={localeContextProps}>
      <IntlProvider locale={localeCode} messages={messages[localeCode]}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export const useLocale = () => useContext(LocaleContext);
