import { PERMISSION, TAG_HELP_DESK } from '@/constants/enums';
import { UserRoleEnum } from '@/types/Dashboard';

export type IRolePermissions = {
  ORG_ADMIN: PERMISSION[];
  SCHOOL_ADMIN: PERMISSION[];
  TEACHER: PERMISSION[];
  STUDENT: PERMISSION[];
};

export const rolePermissions: IRolePermissions = {
  ORG_ADMIN: [
    // School
    PERMISSION.VIEW_SCHOOL,
    PERMISSION.NEW_SCHOOL,
    PERMISSION.EDIT_SCHOOL,
    PERMISSION.DELETE_SCHOOL,
  ],
  SCHOOL_ADMIN: [
    // School
    PERMISSION.VIEW_SCHOOL,
  ],
  TEACHER: [],
  STUDENT: [],
};

export const checkPermission = (role: UserRoleEnum | number | undefined, permission: PERMISSION): boolean => {
  if (!role) return false;

  let rolePermission: PERMISSION[];
  switch (`${role}`) {
    case UserRoleEnum.ORG_ADMIN:
      rolePermission = rolePermissions.ORG_ADMIN;
      break;
    case UserRoleEnum.SCHOOL_ADMIN:
      rolePermission = rolePermissions.SCHOOL_ADMIN;
      break;
    case UserRoleEnum.TEACHER:
      rolePermission = rolePermissions.TEACHER;
      break;
    case UserRoleEnum.STUDENT:
      rolePermission = rolePermissions.STUDENT;
      break;
    default:
      return false;
  }

  return rolePermission.includes(permission);
};

export const mapGetCurrentRoleName = {
  [UserRoleEnum.ORG_ADMIN]: TAG_HELP_DESK.ADMIN,
  [UserRoleEnum.SCHOOL_ADMIN]: TAG_HELP_DESK.ADMIN,
  [UserRoleEnum.TEACHER]: TAG_HELP_DESK.TEACHER,
  [UserRoleEnum.STUDENT]: TAG_HELP_DESK.STUDENT,
};
