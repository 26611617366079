import { IConfig } from './index';
import { API_VERSION, API_PREFIX, API_ENTITY, API_ACTION, API_FEATURE } from '@/constants';

export const REACT_APP_DMS_SERVICE_API_URL = process.env.REACT_APP_DMS_SERVICE_API_URL || 'http://localhost:3004';
export const REACT_APP_AUTH_SERVICE_API_URL = process.env.REACT_APP_AUTH_SERVICE_API_URL;

export const REACT_APP_H5P_SERVICE_PLAY_API_URL = process.env.REACT_APP_H5P_SERVICE_PLAY_API_URL;
export const REACT_APP_C2P_SERVICE_PLAY_API_URL = process.env.REACT_APP_C2P_SERVICE_PLAY_API_URL;
export const REACT_APP_APP_LINK_URL = process.env.REACT_APP_APP_LINK_URL;
export const REACT_APP_DEEP_LINK_URL = process.env.REACT_APP_DEEP_LINK_URL;
export const REACT_APP_AI_REPORT_URL = process.env.REACT_APP_AI_REPORT_URL;

const CONFIG: IConfig = {
  API: {
    ROOT_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}`,
    AUTH_ENDPOINT: `${REACT_APP_AUTH_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.USERS}/${API_ACTION.CHECK_PHONE}`,
    PROFILES_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.AUTH}/${API_ENTITY.PROFILES}`,
    USER_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.USERS}`,
    USER_BULK_UPLOAD_VERIFY_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.USERS}/${API_ACTION.BULK_UPLOADS}`,
    USER_BULK_UPLOAD_IMPORT_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.USERS}/${API_ACTION.BULK_UPLOADS}/${API_ACTION.IMPORT}`,
    SCHOOLS_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.SCHOOLS}`,
    PROGRAMS_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.PROGRAMS}`,
    DISTRICTS_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.DISTRICTS}`,
    CITIES_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.CITIES}`,
    CLASSES_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.CLASSES}`,
    SCHEDULES_HOMEWORK_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.SCHEDULES}/${API_ENTITY.HOMEWORK}`,
    SCHEDULES_LESSONS_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.SCHEDULES}/${API_ENTITY.LESSONS}`,
    SCHEDULES_LESSON_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.SCHEDULES}/${API_ENTITY.LESSON}`,
    SCHEDULES_BULK_CREATE: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ACTION.BULK_SCHEDULE}`,
    LESSON_CONTENTS_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.LESSON_CONTENTS}`,
    H5P_PLAY_ENDPOINT: `${REACT_APP_H5P_SERVICE_PLAY_API_URL}`,
    C2P_PLAY_ENDPOINT: `${REACT_APP_C2P_SERVICE_PLAY_API_URL}`,

    ATTENDEES_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.ATTENDEES}`,
    CLIENT_DASHBOARDS_HOMEWORK_REPORTS: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.REPORTS}/${API_ENTITY.HOMEWORK}`,
    ATTEMPTED_HOMEWORK_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.SCHEDULES}/${API_ENTITY.HOMEWORK}/${API_ENTITY.ATTEMPTED}`,
    MATERIALS_REPORT_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.REPORTS}/${API_ENTITY.MATERIALS}`,
    SKILLS_REPORT_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.REPORTS}/${API_ENTITY.SKILLS}`,
    TRANSFER_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.AUTH}/${API_ENTITY.TRANSFER}`,
    ROLES_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.ROLES}`,
    LESSON_MODULES_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.LESSON_MODULES}`,
    GENERATE_REPORTS_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_FEATURE.AI_REPORTS}/${API_ENTITY.REPORTS}/${API_ACTION.GENERATE}`,
    UNITS_REPORTS_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_FEATURE.AI_REPORTS}/${API_ENTITY.UNITS}`,
    B2C_PROFILES_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_FEATURE.B2C}/${API_ENTITY.PROFILES}`,
    ADS_ENDPOINT: `${REACT_APP_DMS_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.ADS}`,
    AUTH_GENERATE_OTP_ENDPOINT: `${REACT_APP_AUTH_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.OTP}/${API_ACTION.GENERATE_OTP}`,
    AUTH_VERIFY_OTP_ENDPOINT: `${REACT_APP_AUTH_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.OTP}/${API_ACTION.VERIFY_OTP}`,
    AUTH_OTP_PROVIDER_ENDPOINT: `${REACT_APP_AUTH_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.OTP}/${API_ACTION.OTP_PROVIDER}`,
    AUTH_REGISTER_ENDPOINT: `${REACT_APP_AUTH_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.USERS}/${API_ACTION.REGISTER}`,
    AUTH_RESET_PASSWORD_ENDPOINT: `${REACT_APP_AUTH_SERVICE_API_URL}/${API_PREFIX}/${API_VERSION}/${API_ENTITY.USERS}/${API_ACTION.RESET_PASSWORD}`,
  },
  EXTERNAL_DOMAIN: {
    APP_LINK_URL: `${REACT_APP_APP_LINK_URL}`,
    DEEP_LINK_URL: `${REACT_APP_DEEP_LINK_URL}`,
    AI_REPORT_URL: `${REACT_APP_AI_REPORT_URL}`,
    AI_REPORT_RESULT_URL: `${REACT_APP_AI_REPORT_URL}/report-result?reportId=:id&token=:token&from=web`,
  },
  LANG: 'en',
};

export default CONFIG;
