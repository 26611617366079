export const KEYS = {
  DEFAULT_IDENTIFY: 0,
  ENTER: 13,
  TAB: 9,
  BACKSPACE: 8,
  UP_ARROW: 38,
  LEFT_ARROW: 37,
  DOWN_ARROW: 40,
  ESCAPE: 27,
  RIGHT_ARROW: 39,
  LETTER_E: 69,
  NUMPAD_0: 96,
  NUMPAD_9: 105,
  DOT: 190,
  CTRL: 17,
  V: 86,
  ZERO: 48,
  NUMPAD_ZERO: 96,
  NINE: 57,
  NUMPAD_NINE: 105,
  NUM_LOCK: 144,
  DELETE: 46,
  LETTER_A: 65,
  COMMA: 188,
};

export const KEYCODE_ACCEPT_PREVENT_BEHAVIOR = [
  KEYS.TAB,
  KEYS.DOWN_ARROW,
  KEYS.UP_ARROW,
];

export const KEY_NAMES = {
  ENTER: 'Enter',
  TAB: 'Tab',
  BACKSPACE: 'Backspace',
  DELETE: 'Delete',
};

export const DELETE_KEY_GROUP = [
  KEYS.BACKSPACE,
  KEYS.DELETE,
  KEY_NAMES.BACKSPACE,
  KEY_NAMES.DELETE,
];
