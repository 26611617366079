import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { Box, IconButton, TableCell, TableRow, Typography } from '@mui/material';

import { EClassType } from './enums';
import ClassRosterImportUserTable from './ClassRosterImportUserTable';

import { IClassRosterImportClassTable } from './ClassRosterImportClassTable.d';

import { colors, fontSize, fontWeight } from '@/styles/theme';
import { classRosterImportClassTableRowStyles as styles } from './styles';

const ClassRosterImportTableRow: React.FC<{ classData: IClassRosterImportClassTable }> = ({ classData }) => {
  const { className, validRows, invalidRows, totalTeachers, totalStudents, users, classType } = classData;
  const [open, setOpen] = useState(false);

  const renderClassName = () => {
    const defaultClassName = {
      name: className,
      description: <FormattedMessage id="dashboard.classRoster.matchingClass" />,
      isError: false,
    };

    switch (classType) {
      case EClassType.MATCHING:
        return defaultClassName;
      case EClassType.NOT_ASSIGNED:
        return {
          name: <FormattedMessage id="dashboard.classRoster.noClass" />,
          description: <FormattedMessage id="dashboard.classRoster.notAssignedClass" />,
          isError: true,
        };
      case EClassType.NOT_MATCHING:
        return {
          name: className,
          description: <FormattedMessage id="dashboard.classRoster.notMatchingClass" />,
          isError: true,
        };
      default:
        return defaultClassName;
    }
  };

  const { name, description, isError } = renderClassName();

  return (
    <>
      <TableRow sx={{ ...styles, ...(open && styles.open) }}>
        <TableCell>
          <Box sx={styles.classNameWrapper}>
            <IconButton sx={styles.userTableCollapse} onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
            <Box>
              <Typography color={colors.secondary1} fontWeight={fontWeight.semiBold}>
                {name}
              </Typography>
              <Typography color={isError ? colors.destructiveRed : colors.black2} fontSize={fontSize.textSM}>
                {description}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell align="left">{totalTeachers}</TableCell>
        <TableCell align="left">{totalStudents}</TableCell>
        <TableCell align="left">
          <Box sx={styles.validWrapper}>
            {Boolean(validRows) && (
              <Box sx={styles.roundedBox}>
                <Typography color={colors.green3}>
                  <FormattedMessage id="dashboard.classRoster.validRowsNumber" values={{ number: validRows }} />
                </Typography>
              </Box>
            )}
            {Boolean(invalidRows) && (
              <Box
                sx={{
                  ...styles.roundedBox,
                  ...styles.roundedBoxInvalid,
                }}
              >
                <Typography color={colors.destructiveRed1}>
                  <FormattedMessage id="dashboard.classRoster.invalidRowsNumber" values={{ number: invalidRows }} />
                </Typography>
              </Box>
            )}
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <ClassRosterImportUserTable open={open} users={users} />
      </TableRow>
    </>
  );
};

export default ClassRosterImportTableRow;
