import queryString from 'query-string';
import get from 'lodash/get';
import { NavigateFunction } from 'react-router-dom';
import dayjs from 'dayjs';
import { QueryClient } from '@tanstack/react-query';

import REGEX from '@/constants/regex';
import { CHARACTERS, ELocaleCode, PHONE_NUMBER_COUNTRY_CODE, SORT_STATUS } from '@/constants/enums';
import { ISort, LocationParams, TDownload, TRenderCharacterProps } from '@/types/Common';
import {
  clientRoleArr,
  ENTITY_STATUS,
  FIRST_ITEM_INDEX,
  FIRST_ITEM_NUMBER,
  HUNDRED,
  NUMBER_ONE,
  NUMBER_SECOND,
  SYMBOLS,
  ZERO,
  ZERO_POINT_EIGHT,
  ZERO_POINT_FIVE,
  ZERO_POINT_FOUR,
  ZERO_POINT_SIX,
  ZERO_POINT_TWO,
} from '@/constants';
import { IFilterQuickItemGroup } from '@/components/filterQuick/FilterQuick.d';
import { ILookupFieldItem } from '@/components/lookupField/LookupField.d';

import sortIcon from '@/assets/images/icons/sort-icon.svg';
import sortIconDown from '@/assets/images/icons/sort-arr-down-icon.svg';
import sortIconUp from '@/assets/images/icons/sort-arr-up-icon.svg';
import { AnyObject, ObjectFieldType } from '@/constants/types';
import RoutesString from '@/pages/routesString';
import { UserProfile, Organization, IUserPaidProps } from '@/types/Organization';
import { errors } from '@/constants/errors';
import { SYSTEM_DATE_START, yearMonthDateFullTimeFormat } from '@/constants/datetime';
import { UserRoleEnum } from '@/types/Dashboard';

import turtleNoHomeworkEn from '@/assets/images/turtle-no-homework-en.png';
import turtleNoHomeworkVi from '@/assets/images/turtle-no-homework-vi.png';
import turtleNoLessonEn from '@/assets/images/turtle-no-lesson-en.png';
import turtleNoLessonVi from '@/assets/images/turtle-no-lesson-vi.png';
import rabbitNoHomeworkEn from '@/assets/images/rabbit-no-homework-en.png';
import rabbitNoHomeworkVi from '@/assets/images/rabbit-no-homework-vi.png';
import rabbitNoLessonEn from '@/assets/images/rabbit-no-lesson-en.png';
import rabbitNoLessonVi from '@/assets/images/rabbit-no-lesson-vi.png';

const characterImages = {
  [`${ELocaleCode.EN}-${CHARACTERS.TURTLE}-${true}`]: turtleNoHomeworkEn,
  [`${ELocaleCode.VI}-${CHARACTERS.TURTLE}-${true}`]: turtleNoHomeworkVi,
  [`${ELocaleCode.EN}-${CHARACTERS.TURTLE}-${false}`]: turtleNoLessonEn,
  [`${ELocaleCode.VI}-${CHARACTERS.TURTLE}-${false}`]: turtleNoLessonVi,
  [`${ELocaleCode.EN}-${CHARACTERS.RABBIT}-${true}`]: rabbitNoHomeworkEn,
  [`${ELocaleCode.VI}-${CHARACTERS.RABBIT}-${true}`]: rabbitNoHomeworkVi,
  [`${ELocaleCode.EN}-${CHARACTERS.RABBIT}-${false}`]: rabbitNoLessonEn,
  [`${ELocaleCode.VI}-${CHARACTERS.RABBIT}-${false}`]: rabbitNoLessonVi,
};

export const renderNoScheduleCharacter = ({ isHomework, localeCode, character }: TRenderCharacterProps) => {
  const key = `${localeCode}-${character}-${isHomework}`;
  return characterImages[key] || turtleNoHomeworkVi;
};

export const addingUrlWithParams = (
  location: LocationParams<unknown>,
  field: string,
  data: any,
  isRemove?: boolean,
): string => {
  const paramsObj = queryString.parse(location.search, {
    arrayFormat: 'comma',
  });

  if (field === 'sort') {
    // have property already -> second click
    if (paramsObj.sortOrder) {
      paramsObj.sortOrder = paramsObj.sortOrder === SORT_STATUS.DESC ? SORT_STATUS.ASC : SORT_STATUS.DESC;
    } else {
      paramsObj.sortOrder = SORT_STATUS.ASC;
    }
  }

  paramsObj[field] = data;

  if (isRemove) delete paramsObj[field];

  let result = queryString.stringify(paramsObj, { arrayFormat: 'comma' });
  if (!result.includes('?')) result = `?${result}`;

  return result;
};

export const calcSortStatus = (sortObj: ISort) => {
  const { sortName, sortDirection } = sortObj;

  let finalSortStatus = '';
  switch (sortDirection) {
    case SORT_STATUS.DESC:
      finalSortStatus = SORT_STATUS.ASC;
      break;
    case SORT_STATUS.ASC:
      finalSortStatus = SORT_STATUS.DESC;
      break;
    default:
      finalSortStatus = SORT_STATUS.ASC;
      break;
  }

  return { sortName, sortDirection: finalSortStatus };
};

export const renderSortIcon = (type: string, sortObj: ISort) => {
  const { sortName, sortDirection } = sortObj;
  let finalSortIcon = sortIcon;
  switch (true) {
    case type === sortName && sortDirection === SORT_STATUS.DESC:
      finalSortIcon = sortIconDown;
      break;
    case type === sortName && sortDirection === SORT_STATUS.ASC:
      finalSortIcon = sortIconUp;
      break;
    default:
      break;
  }

  return finalSortIcon;
};

export const getAllFilterQuickFilterCheckedItems = (quickFilters: IFilterQuickItemGroup[]) => {
  let result: any = [];

  if (Array.isArray(quickFilters) && quickFilters.length) {
    quickFilters.forEach((filter) => {
      result = [...result, ...filter.allValue];
    });
  }

  return result;
};

export const checkArrContainArr = (rootArr: string[], compareArr: string[]) => {
  let check = true;

  for (let i = FIRST_ITEM_INDEX; i < rootArr.length; i++) {
    const item = rootArr[i];
    if (!compareArr.includes(item)) {
      check = false;
      break;
    }
  }

  return check;
};

export const renderLookupFieldOption = (props: ILookupFieldItem) => {
  let result = '';
  const { itemDataKeys, option } = props;
  itemDataKeys.forEach((key, index) => {
    const optionKey = get(option, key) || '';
    if (index === itemDataKeys.length - FIRST_ITEM_NUMBER) {
      result += optionKey;
    } else {
      result += `${optionKey} ${SYMBOLS.HYPHEN} `;
    }
  });

  return result;
};

export const parseSimpleArrayStrToNumber = (value: string[] | null) => {
  if (Array.isArray(value) && value.length) {
    return value.map((item) => {
      return item ? Number(item) : null;
    });
  }

  return null;
};

export const trimAllValueWithObjectField = (objField: ObjectFieldType) => {
  if ((!Array.isArray(objField) && typeof objField !== 'object') || objField === null) {
    return objField;
  }

  return Object.keys(objField).reduce(
    (acc: ObjectFieldType, key) => {
      acc[key] =
        typeof objField[key] === 'string'
          ? (objField[key] as string).trim()
          : trimAllValueWithObjectField(objField[key]);
      return acc;
    },
    Array.isArray(objField) ? [] : {},
  );
};

export const parseToNumberAllValueWithObjectField = (objField: any) => {
  if ((!Array.isArray(objField) && typeof objField !== 'object') || objField === null) {
    return objField;
  }

  return Object.keys(objField).reduce(
    (acc: ObjectFieldType, key) => {
      acc[key] =
        typeof objField[key] === 'string' ? Number(objField[key]) : parseToNumberAllValueWithObjectField(objField[key]);
      return acc;
    },
    Array.isArray(objField) ? [] : {},
  );
};

export const navigateDefault = (activeUser: UserProfile, navigate: NavigateFunction) => {
  if (clientRoleArr.includes(activeUser.roleId.toString())) {
    navigate(RoutesString.ClientHome);
  } else {
    navigate(RoutesString.Library);
  }
};

export const isChangeUserRole = (activeOrganizations: any, organizations: Organization[]) => {
  const { organizationId: activeOrganizationId } = get(activeOrganizations, 'activeOrganization') ?? {};
  const { roleId: activeRoleId, userId: activeUserId } = get(activeOrganizations, 'activeUser') ?? {};
  const organizationNeedToCheck = organizations.find(
    (organization) => activeOrganizationId === organization.organizationId,
  );
  const { schools } = organizationNeedToCheck ?? {};
  const allUserOfOrganization: UserProfile[] = [];

  if (!Array.isArray(schools) || (Array.isArray(schools) && !schools.length)) return undefined;

  schools.forEach((school) => {
    allUserOfOrganization.push(...school.users);
  });

  return allUserOfOrganization.find((user) => {
    const { roleId, userId } = user;
    return activeRoleId !== roleId && activeUserId === userId;
  });
};

export const mapErrorCode = (errorCode?: number) => {
  const errObj = errors.find((item) => item.errorCode === errorCode);

  return get(errObj, 'strErrorCode') ?? 'dashboard.toast.unexpectedError';
};

export const calcSubmissionStatus = (dateEnd: string, firstSubmitTime: string) => {
  let result = '';
  const formattedDateEnd = dayjs(dateEnd).isValid() && dayjs(dateEnd).format(yearMonthDateFullTimeFormat);
  const formattedSubmittedTime =
    dayjs(firstSubmitTime).isValid() && dayjs(firstSubmitTime).format(yearMonthDateFullTimeFormat);

  switch (true) {
    case formattedSubmittedTime < SYSTEM_DATE_START: {
      result = 'dashboard.information.notSubmitted';
      break;
    }
    case formattedSubmittedTime <= formattedDateEnd: {
      result = 'dashboard.information.onTime';
      break;
    }
    case formattedSubmittedTime > formattedDateEnd: {
      result = 'dashboard.information.lateSubmission';
      break;
    }
    default: {
      result = 'dashboard.information.notSubmitted';
      break;
    }
  }

  return result;
};

export const calcPersonalRating = (personalRate: number) => {
  let result = '';

  switch (true) {
    case personalRate < ZERO_POINT_FIVE: {
      result = 'dashboard.information.rating.emerging';
      break;
    }
    case ZERO_POINT_FIVE <= personalRate && personalRate < ZERO_POINT_EIGHT: {
      result = 'dashboard.information.rating.good';
      break;
    }
    case personalRate >= ZERO_POINT_EIGHT: {
      result = 'dashboard.information.rating.excellent';
      break;
    }
    default: {
      break;
    }
  }

  return result;
};

export const calcPersonalBadge = (personalRate: number, isHasScore: boolean = true) => {
  let result = '';
  if (!isHasScore) return 'dashboard.information.rating.wellDone';

  switch (true) {
    case personalRate >= ZERO && personalRate < ZERO_POINT_TWO: {
      result = 'dashboard.information.rating.emerging';
      break;
    }
    case personalRate >= ZERO_POINT_TWO && personalRate < ZERO_POINT_FOUR: {
      result = 'dashboard.information.rating.practicing';
      break;
    }
    case personalRate >= ZERO_POINT_FOUR && personalRate < ZERO_POINT_SIX: {
      result = 'dashboard.information.rating.developing';
      break;
    }
    case personalRate >= ZERO_POINT_SIX && personalRate < ZERO_POINT_EIGHT: {
      result = 'dashboard.information.rating.proficient';
      break;
    }
    case personalRate >= ZERO_POINT_EIGHT: {
      result = 'dashboard.information.rating.mastery';
      break;
    }
    default: {
      break;
    }
  }

  return result;
};

export const calcPercent = (score: number | null, maxScore: number = NUMBER_ONE) => {
  if (!score) return `${ZERO}${SYMBOLS.PERCENT}`;

  const percent = (score / maxScore) * HUNDRED;
  const finalPercent = Number.isInteger(percent) ? percent : parseFloat(`${percent}`).toFixed(NUMBER_SECOND);
  return `${finalPercent}${SYMBOLS.PERCENT}`;
};

export const handleRefetchReactQueries = (queryClient: QueryClient, arrayEntity: string[]) => {
  // invalidate queries to update API queries

  arrayEntity.forEach((keyEntity) => {
    queryClient.removeQueries({
      queryKey: [keyEntity],
    });
  });
};

export const translateRoleProfile = (roleId: number | undefined) => {
  let strTranslated = '';

  switch (`${roleId}`) {
    case UserRoleEnum.ORG_ADMIN:
      strTranslated = 'dashboard.user.organizationAdmin';
      break;

    case UserRoleEnum.SCHOOL_ADMIN:
      strTranslated = 'dashboard.user.schoolAdmin';
      break;

    case UserRoleEnum.TEACHER:
      strTranslated = 'dashboard.user.teacher';
      break;

    case UserRoleEnum.PARENTS:
      strTranslated = 'dashboard.user.parents';
      break;

    case UserRoleEnum.STUDENT:
      strTranslated = 'dashboard.user.student';
      break;

    default:
      strTranslated = 'common.notFound';
      break;
  }

  return strTranslated;
};

export const translateStatusProfile = (statusUser: string | undefined) => {
  let strTranslated = '';

  switch (statusUser) {
    case ENTITY_STATUS.ACTIVE:
      strTranslated = 'dashboard.user.active';
      break;

    case ENTITY_STATUS.INACTIVE:
      strTranslated = 'dashboard.user.inactive';
      break;

    case ENTITY_STATUS.PENDING:
      strTranslated = 'dashboard.user.pending';
      break;

    default:
      strTranslated = 'common.notFound';
      break;
  }

  return strTranslated;
};

export const handleDownload = ({ uri, name }: TDownload) => {
  const aTag = 'a';
  const downloadAttribute = 'download';
  const link = document.createElement(aTag);
  link.setAttribute(downloadAttribute, name);
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const convertPhoneToInternationalFormat = (phoneNumber: string) => {
  const trimmedNumber = phoneNumber.trim();

  // Remove leading zeros
  const removedLeadingZerosNumber = trimmedNumber.replace(REGEX.SELECT_FIRST_ZERO, '');

  // Remove any non-digit characters
  const removedNonDigitCharactersNumber = removedLeadingZerosNumber.replace(REGEX.NON_DIGIT, '');

  // Check if the number starts with "84" or "+84"
  if (removedNonDigitCharactersNumber.startsWith(PHONE_NUMBER_COUNTRY_CODE.VN.substring(FIRST_ITEM_NUMBER))) {
    return `${SYMBOLS.PLUS}${removedNonDigitCharactersNumber}`;
  }
  if (!removedNonDigitCharactersNumber.startsWith(SYMBOLS.PLUS)) {
    return `${PHONE_NUMBER_COUNTRY_CODE.VN}${removedNonDigitCharactersNumber}`;
  }

  return trimmedNumber;
};

export const sumAverage = (results: any[], field: string): number => {
  const validArray = Array.isArray(results) && results.length > ZERO;
  if (validArray)
    return (
      results.reduce((accumulator, currentValue) => accumulator + get(currentValue, field) || ZERO, ZERO) /
      results.length
    );

  return ZERO;
};

export const checkArrayHasLength = (param: any) => {
  return Array.isArray(param) && param.length > ZERO;
};

export const checkNumberAndReturnDefault = (number: number, defaultNumber: number) => {
  return Number(number) || defaultNumber;
};

export const renderProfilePaidStatus = ({
  requireSubscription,
  isCurrentMonthPaid,
  nearestPaidMonth = ZERO,
  nearestPaidYear = ZERO,
}: IUserPaidProps) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + NUMBER_ONE;

  let result = {
    id: 'profile.unpaid',
    values: {},
  };

  if (
    !requireSubscription ||
    (isCurrentMonthPaid && nearestPaidMonth === currentMonth && nearestPaidYear === currentYear)
  ) {
    result = {
      id: 'profile.readyToUse',
      values: {},
    };
  }

  if (
    requireSubscription &&
    !isCurrentMonthPaid &&
    (nearestPaidYear > currentYear || (nearestPaidYear === currentYear && currentMonth <= nearestPaidMonth))
  ) {
    result = {
      id: 'profile.readyToUseFrom',
      values: {
        from: `${nearestPaidMonth}${SYMBOLS.SLASH}${nearestPaidYear}`,
      },
    };
  }

  return result;
};

export const checkCurrentMonthPaidStatus = ({
  requireSubscription,
  isCurrentMonthPaid,
  nearestPaidMonth = ZERO,
  nearestPaidYear = ZERO,
}: IUserPaidProps) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + NUMBER_ONE;

  return (
    !requireSubscription || (isCurrentMonthPaid && nearestPaidMonth === currentMonth && nearestPaidYear === currentYear)
  );
};

export const removeDuplicatesByField = (arr: AnyObject[], fieldPath: string) => {
  const seenMap = new Map();

  if (Array.isArray(arr) && arr.length > ZERO)
    arr.forEach((item) => {
      const value = get(item, fieldPath);
      seenMap.set(value, item);
    });

  return Array.from(seenMap.values());
};

export const getUniqueObjectFromNestedArray = (arr: any, key: string) => {
  const keyMap = new Map();

  for (const item of arr) {
    if (keyMap.has(item[key])) {
      keyMap.set(item[key], null);
    } else {
      keyMap.set(item[key], item);
    }
  }

  return Array.from(keyMap.values()).filter((item) => item !== null);
};

export const getValidImageSource = (URL: string, onError: () => void) => {
  const imageTestUrl = new Image();
  imageTestUrl.onerror = () => {
    onError();
  };
  imageTestUrl.src = URL;
};

export const loadScript = (params: { scriptId: string; innerHtml: string }) => {
  const { scriptId, innerHtml } = params;
  const existingScript = document.getElementById(scriptId);

  if (existingScript) {
    existingScript.remove();
  }
  const newScript = document.createElement('script');
  newScript.async = true;
  newScript.id = scriptId;
  newScript.innerHTML = innerHtml;

  document.head.appendChild(newScript);

  return newScript;
};

export const parseJson = (jsonString: string) => {
  let jsonObject: AnyObject = {};
  try {
    jsonObject = JSON.parse(jsonString);
  } catch (e) {
    console.error(e);
  }
  return jsonObject;
};
