import { FC, useState } from 'react';
import omit from 'lodash/omit';
import { FormattedMessage, useIntl } from 'react-intl';
import get from 'lodash/get';
import { Box } from '@mui/material';
import dayjs, { utc } from 'dayjs';

import { API_ENTITY, FIRST_ITEM_INDEX, ZERO } from '@/constants';
import { yearMonthDateFormat } from '@/constants/datetime';
import { useConfirmDialog } from '@/modules/dashboard/commonElementsManagement/providers/ConfirmDialogProvider';
import { TBulkScheduleRequestBody, useScheduleBulkCreate } from '@/utils/hooks/dashboard/useSchedulesBulk';
import { defaultBulkScheduleSuccessDialogData } from '../dialog/constants';
import { ERROR_CODE } from '@/constants/apiCode';
import { mapErrorCode } from '@/utils/commonUtil';
import config from '@/config';
import useCommonListPage from '@/utils/hooks/dashboard/useQueryCommonList';
import useProfileManagement from '@/store/profileManagement/ProfileManagement';

import BulkScheduleForm from './BulkScheduleForm';
import BulkScheduleSuccessDialog from '../dialog/BulkScheduleSuccessDialog';
import BulkScheduleLoadingDialog from '../dialog/BulkScheduleLoadingDialog';

import { IBulkScheduleValues, TBulkScheduleFormWrapperProps } from './BulkScheduleForm.d';

import flagIcon from '@/assets/images/icons/flag.svg';

import { styles } from './styles';
import { splitAgeGroupIdFromProgram } from '../constants';

const BulkScheduleFormWrapper: FC<TBulkScheduleFormWrapperProps> = ({ open, setOpen }) => {
  const { isLoading: isLoadingScheduleBulkCreate, mutate: triggerScheduleBulkCreate } = useScheduleBulkCreate();
  const [profileMgtState] = useProfileManagement();
  const { activeUser } = profileMgtState ?? {};
  const { schoolId = '' } = activeUser ?? {};

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [bulkScheduleSuccessDialogData, setBulkScheduleSuccessDialogData] = useState(
    defaultBulkScheduleSuccessDialogData,
  );

  const { refetch } = useCommonListPage(
    {
      endpoint: config.API.SCHEDULES_LESSONS_ENDPOINT,
      dataKey: API_ENTITY.LESSONS,
    },
    {
      schoolId: `${schoolId}`,
      passed: `${ZERO}`,
      from: utc(dayjs().startOf('week')).format(),
      to: utc(dayjs().endOf('week')).format(),
    },
    false,
  );

  const { openConfirmation } = useConfirmDialog();
  const intl = useIntl();

  const handleCloseBulkScheduleModal = () => {
    setOpen(false);
  };

  const onTriggerBulkCreateSchedule = (customValues: TBulkScheduleRequestBody) => {
    triggerScheduleBulkCreate(customValues, {
      onSuccess: (response) => {
        const { courseName, lessonNumber, relatedHomework, durationWeek, startDate: resStartDate, endDate } = response;
        handleCloseBulkScheduleModal();
        setOpenSuccessDialog(true);
        setBulkScheduleSuccessDialogData({
          courseName,
          numberLessons: lessonNumber,
          numberHomeworks: relatedHomework,
          duration: durationWeek,
          startDate: resStartDate,
          endDate,
        });
        refetch();
      },
      onError: (error: any) => {
        const errorCode = get(error, 'response.data.code');
        if (errorCode === ERROR_CODE.SCHEDULES_BULK_CREATE_DUPLICATE_COURSE) {
          const responseData = get(error, 'response.data.data');
          const { programName, className, duplicatedCourseName } = responseData || {};

          openConfirmation({
            typeDialog: 'middle',
            typeFooterText: 'uppercase',
            description: (
              <FormattedMessage
                id="dashboard.bulk.schedule.bulkCreate.error.duplicatedSchedule.description"
                values={{
                  programName: (
                    <Box component="span" sx={styles.highlightedText}>
                      {programName}
                    </Box>
                  ),
                  className: (
                    <Box component="span" sx={styles.highlightedText}>
                      {className}
                    </Box>
                  ),
                  courseName: duplicatedCourseName,
                }}
              />
            ),
            title: intl.formatMessage({
              id: mapErrorCode(errorCode),
            }),
            iconSrc: flagIcon,
          })
            .then(() => {
              onTriggerBulkCreateSchedule({
                ...customValues,
                forceCreate: true,
              });
            })
            .catch((err) => {
              console.warn(err);
            });

          return;
        }

        openConfirmation({
          typeDialog: 'middle',
          typeFooterText: 'uppercase',
          title: intl.formatMessage({
            id: 'common.errors.somethingWentWrong',
          }),
          description: intl.formatMessage({
            id: 'dashboard.bulk.schedule.bulkCreate.error.failed',
          }),
          textYes: intl.formatMessage({
            id: 'common.ok',
          }),
          hideNoBtn: true,
          iconSrc: flagIcon,
        });
      },
    });
  };

  const handleOnSubmit = (values: IBulkScheduleValues) => {
    const { daysOfWeek, globalTime, attachHomework, programId, ageGroupId, ...rest } = values;
    const selectedDaysOfWeek = daysOfWeek.filter((day) => day.selected);
    const { enable, startTime, endTime } = globalTime;

    // have to split programId from ageGroupId cause in OverviewStep we concat programId and ageGroupId to get unique key for selection single lookup field
    const actualProgramId = splitAgeGroupIdFromProgram(programId, ageGroupId);
    const { durationDay } = attachHomework;

    const finalDaysOfWeek = selectedDaysOfWeek.map((day) => {
      const { startTime: dayStart, endTime: dayEnd, date } = day;

      if (!enable) {
        return {
          startTime: utc(new Date(`${date.format(yearMonthDateFormat)} ${dayStart}`)).format(),
          endTime: utc(new Date(`${date.format(yearMonthDateFormat)} ${dayEnd}`)).format(),
        };
      }

      return {
        startTime: utc(new Date(`${date.format(yearMonthDateFormat)} ${startTime}`)).format(),
        endTime: utc(new Date(`${date.format(yearMonthDateFormat)} ${endTime}`)).format(),
      };
    });

    let finalGlobalTime = {
      enable,
      startTime: '',
      endTime: '',
    };

    if (enable) {
      finalGlobalTime = {
        enable,
        startTime: finalDaysOfWeek[FIRST_ITEM_INDEX].startTime,
        endTime: finalDaysOfWeek[FIRST_ITEM_INDEX].endTime,
      };
    }

    const customValues = omit(
      {
        ...rest,
        programId: Number(actualProgramId),
        ageGroupId,
        globalTime: { ...finalGlobalTime },
        startDate: finalDaysOfWeek[FIRST_ITEM_INDEX].startTime,
        daysOfWeek: [...finalDaysOfWeek],
        attachHomework: {
          ...attachHomework,
          durationDay: Number(durationDay),
        },
      },
      ['scheduleTabId', 'programName', 'className', 'schoolId'],
    );

    onTriggerBulkCreateSchedule(customValues);
  };

  return (
    <>
      <BulkScheduleForm
        open={open}
        setOpen={setOpen}
        handleOnSubmit={handleOnSubmit}
        handleOnConfirmCancel={handleCloseBulkScheduleModal}
      />
      <BulkScheduleSuccessDialog
        open={openSuccessDialog}
        setOpen={setOpenSuccessDialog}
        scheduleData={bulkScheduleSuccessDialogData}
      />
      <BulkScheduleLoadingDialog open={isLoadingScheduleBulkCreate} />
    </>
  );
};

export default BulkScheduleFormWrapper;
