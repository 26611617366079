import { colors } from '@/styles/theme';

export const styles = {
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',

    '.errorMsg': {
      color: colors.destructiveRed,
      marginTop: '3px',
      fontSize: '0.75rem',
    },
  },
  enteredTextField: {
    '& label': {
      transform: 'translate(0px, -20px) scale(0.75)',
      color: colors.black3,
    },
  },
};
