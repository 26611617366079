import { createContainer, createHook, createStore, StoreActionApi } from 'react-sweet-state';

import { IAPIErrorManagementResponse } from './APIErrorManagement.d';

type StoreApi = StoreActionApi<IAPIErrorManagementResponse>;

export const API_ERROR_MANAGEMENT_STORE = 'APIErrorManagement';

export const initialState: IAPIErrorManagementResponse = {
  initiated: false,
  code: null,
  msg: null,
  statusCode: null,
};

export const actions = {
  setAPIError:
    (payload: IAPIErrorManagementResponse = initialState) =>
    async ({ setState, getState }: StoreApi) => {
      setState({
        ...getState(),
        ...payload,
      });
    },
  reset:
    () =>
    ({ setState, getState }: StoreApi) => {
      setState({
        ...getState(),
        ...initialState,
      });
    },
};

type Actions = typeof actions;

type StoreContainerProps = {
  initialState: IAPIErrorManagementResponse;
};

const getCurrentAPIError = (state: IAPIErrorManagementResponse) => state;

export const Store = createStore({
  initialState,
  actions,
  name: API_ERROR_MANAGEMENT_STORE,
});

const useAPIErrorManagement = createHook(Store);

export const storeKey = `${Store.key}@__global__`;

export const useAPIErrorManagementData = createHook(Store, {
  selector: getCurrentAPIError,
});

export const APIErrorManagementContainer = createContainer<IAPIErrorManagementResponse, Actions, StoreContainerProps>(
  Store,
  {
    onInit:
      () =>
      ({ setState }: StoreApi, { initialState: customInitialState }) => {
        setState({ ...customInitialState });
      },
  },
);

export default useAPIErrorManagement;
