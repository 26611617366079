import qs from 'qs';

type Method = 'GET' | 'POST';

export async function fetchJson<T>(url: string, method: Method, parameters?: any): Promise<T> {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  let requestInit: RequestInit = {
    headers,
    method,
    credentials: 'include',
  };

  if (parameters) {
    switch (method) {
      case 'GET':
        url = `${url}?${qs.stringify(parameters, { encodeValuesOnly: true })}`;
        break;
      case 'POST':
        requestInit = {
          ...requestInit,
          body: JSON.stringify(parameters),
        };
        break;
      default:
        break;
    }
  }
  const response = await fetch(url, requestInit);
  const responseJson = await response.json();
  return responseJson;
}
