import get from 'lodash/get';
import dayjs from 'dayjs';

import { ZERO, SECOND_ITEM_NUMBER, NUMBER_ONE } from '@/constants/index';
import { ONE_HOUR, ONE_MINUTE, ONE_THOUSAND_MILLISECONDS } from '@/constants/timers';
import { TCommonDate } from '@/components/datePicker/DatePicker.d';

export const getUTCDateFn = (date: Date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
};

export const renderTimeRangeSliderLabel = (value: number) => {
  const hours = Math.floor(value / ONE_HOUR).toString();
  const minutes = ((value % ONE_HOUR) / ONE_MINUTE).toString();
  const finalHours = hours.length < SECOND_ITEM_NUMBER ? `${ZERO}${hours}` : hours;
  const finalMinutes = minutes.length < SECOND_ITEM_NUMBER ? `${ZERO}${minutes}` : minutes;
  return `${finalHours} : ${finalMinutes}`;
};

export const renderTimeRangeSliderValue = (value: number) => {
  const hours = Math.floor(value / ONE_HOUR).toString();
  const minutes = ((value % ONE_HOUR) / ONE_MINUTE).toString();
  const finalHours = hours.length < SECOND_ITEM_NUMBER ? `${ZERO}${hours}` : hours;
  const finalMinutes = minutes.length < SECOND_ITEM_NUMBER ? `${ZERO}${minutes}` : minutes;
  return `${finalHours}:${finalMinutes}`;
};

export const parseHoursMinsToSecs = (value: string) => {
  if (!value) return ZERO;

  const splitValue = value.split(':');
  const hours = get(splitValue, ZERO);
  const minutes = get(splitValue, NUMBER_ONE);
  return parseInt(hours, ZERO) * ONE_HOUR + parseInt(minutes, ZERO) * ONE_MINUTE;
};

export const parseSecondsToMilliseconds = (seconds: number) => {
  return seconds * ONE_THOUSAND_MILLISECONDS;
};

export const isValidDate = (date?: TCommonDate, format?: string) => {
  if (!date) return false;
  return format ? dayjs(date, format).isValid() : dayjs(date).isValid();
};
