import RoutesString, { RoutesStringWithoutDynamicKey } from '@/pages/routesString';

import homeIcon from '@/assets/images/icons/home-icon.svg';
import programIcon from '@/assets/images/icons/program-icon.svg';
import scheduleIcon from '@/assets/images/icons/schedule-icon.svg';
import rosterIcon from '@/assets/images/icons/class-roster-client-icon.svg';
import pieChartIcon from '@/assets/images/icons/pie-chart-size40-icon.svg';
import { API_ENTITY, FIRST_ITEM_INDEX, FOURTH_ITEM_INDEX } from '@/constants';
import { ECalendarMode } from '@/constants/enums';

interface IDisableItem {
  isDisable: boolean;
  linkIcon: string;
}

export interface MenuProps {
  name: string;
  colorHover: string;
  link: string;
  linkIcon: string;
  disableProps?: IDisableItem;
  linksActive?: string[];
}

export const commonMenu: MenuProps[] = [
  {
    name: 'menu.home',
    colorHover: 'rgba(35, 66, 141, 0.15)',
    link: RoutesString.ClientHome,
    linksActive: [RoutesString.ClientHome],
    linkIcon: homeIcon,
  },
  {
    name: 'menu.program',
    colorHover: 'rgba(102, 179, 177, 0.15)',
    link: RoutesString.ClientPrograms,
    linksActive: [RoutesString.ClientPrograms, RoutesStringWithoutDynamicKey.ClientProgramDetail],
    linkIcon: programIcon,
  },
  {
    name: 'menu.schedules',
    colorHover: 'rgba(113, 161, 63, 0.15)',
    link: `${RoutesString.ClientScheduleListingPage}?mode=${ECalendarMode.WEEK}`,
    linksActive: [RoutesString.ClientScheduleListingPage],
    linkIcon: scheduleIcon,
  },

  {
    name: 'menu.report',
    colorHover: 'rgba(113, 104, 167, 0.15)',
    // TO-DO: temporary add params here for demo AI report on Apr/2024
    link: `${RoutesString.ClientHome}?${API_ENTITY.REPORT}=true`,
    linksActive: [`${RoutesString.ClientHome}?${API_ENTITY.REPORT}=true`],
    linkIcon: pieChartIcon,
  },
];

export const teacherMenu: MenuProps[] = [
  ...commonMenu.slice(FIRST_ITEM_INDEX, FOURTH_ITEM_INDEX),
  {
    name: 'menu.roster',
    colorHover: 'rgba(209, 160, 127, 0.15)',
    link: RoutesString.ClientClassRosterList,
    linksActive: [RoutesString.ClientClassRosterList],
    linkIcon: rosterIcon,
  },
  ...commonMenu.slice(FOURTH_ITEM_INDEX),
];

export const studentMenu: MenuProps[] = [...commonMenu];
