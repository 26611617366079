import styled from '@emotion/styled';
import { Switch } from '@mui/material';

import { colors, fontSize, lineHeight, fontWeight } from '@/styles/theme';

export const styles = {
  dialogWrapper: {
    '&.show-lesson-info': {
      '.MuiDialog-paper': {
        minWidth: '856px',
      },
    },
    '.MuiDialog-paper': {
      width: '536px',
      height: '100%',
      maxHeight: 'unset',
    },
    '.MuiDialogContent-root, .MuiDialogActions-root': {
      padding: 0,
    },

    '.errorMsg': {
      color: colors.destructiveRed,
      marginTop: '3px',
      fontSize: '0.75rem',
    },
  },
  bulkScheduleFormTitle: {
    padding: '26px 40px',
    color: colors.secondary1,
    fontSize: fontSize.titleSM,
    /**
     * Due to Inter font support issues for Windows OS
     * Temporary use fontWeight.bold instead of fontWeight.extraBold
     * Will look back later if have time
     */
    fontWeight: fontWeight.bold,
    lineHeight: lineHeight.subTitleLG,
  },
  bulkScheduleFormAction: {
    display: 'flex',
    justifyContent: 'end',
    padding: '30px 40px',
    gap: '8px',
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 0',
  },
  fieldContainerWithBackground: {
    padding: '20px 40px',
    background: colors.linearPrimary2,

    '& .MuiTextField-root label.Mui-focused': {
      background: 'transparent',
    },
  },
  formSpacing: {
    padding: '0 40px',
  },
  borderLeft: {
    borderLeft: `1px solid ${colors.white3}`,
  },
  btnFooter: {
    height: '40px',
  },
  attachHomeworkToLessonWrapper: {
    background: colors.linearPrimary2,
  },
  stepThreeCommonStyles: {
    padding: '34px 25px',
    alignItems: 'center',
  },
  attachHomeworkToLessonContainer: {},
  subDesc: {
    fontSize: fontSize.btnXS,
    lineHeight: lineHeight.textLineHeightLG,
    color: colors.black2,
    gap: '4px',
  },
  leftBlock: {
    fontWeight: fontWeight.bold,
    fontSize: fontSize.textLG,
    lineHeight: lineHeight.titleXXS,
  },
  rightBlock: {
    display: 'flex',
    justifyContent: 'end',
  },
  homeworkDurationInput: {
    width: '64px',
    height: '38px',
    padding: 0,
    margin: 0,
    borderRadius: '4px',
    background: colors.white1,

    ' .MuiInputBase-root, .MuiOutlinedInput-root': {
      height: '38px',
    },

    fieldset: {
      top: '-1px',
      border: 'unset',
    },
  },
  nationalHolidaysBlock: {
    paddingTop: 0,
  },
  scheduleTimeSelectorWrapper: {
    padding: '0 16px 115px',
  },
  scheduleTimeSelectorContainer: {
    display: 'flex',
    gap: '8px',
  },
  globalTimeBlock: {
    marginTop: '16px',
    label: {
      '&.MuiFormLabel-filled, &.Mui-focused': {
        display: 'none',
      },
    },
    paddingRight: '8px',
  },
  stepTwoLabel: {
    marginBottom: '8px',
    color: colors.black2,
    fontSize: fontSize.btnXS,
    fontWeight: fontWeight.bold,
    lineHeight: lineHeight.textLineHeightLG,
  },
  scheduleTimeAppliesGloballyBlock: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',

    '.schedule-time-globally-label': {
      fontSize: fontSize.btnSM,
      lineHeight: lineHeight.textLineHeightXXL,
      fontWeight: fontWeight.normal,
    },
  },
  bulkScheduleHomeworkDurationBox: {
    height: 0,
    transition: 'height 0.5s ease, opacity 0.5s ease',
    display: 'flex',
    alignItems: 'center',
    opacity: 0,

    '&.show': {
      height: '78px',
      opacity: 1,
    },
  },
  highlightedText: {
    color: colors.secondary1,
    fontWeight: fontWeight.bold,
  },
  headerWrapper: {
    padding: '36px',
  },
  headerTitle: {
    textAlign: 'center',
    fontSize: fontSize.textLG,
    lineHeight: lineHeight.titleXXS,
    fontWeight: fontWeight.bold,
    color: colors.secondary1,
  },
  error: {
    padding: '12px 24px',
  },
};

export const StyledSwitch = styled(Switch)(() => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',

  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },

    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },

  '& .MuiSwitch-switchBase': {
    padding: 2,

    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: colors.white1,

      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: colors.secondary1,
      },
    },
  },

  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: 'width 0.2s',
  },

  '& .MuiSwitch-track': {
    borderRadius: 8,
    opacity: 1,
    backgroundColor: colors.white3,
    boxSizing: 'border-box',
  },
  '.errorMsg': {
    color: colors.destructiveRed,
    marginTop: '3px',
    fontSize: '0.75rem',
  },
}));

export const styleConfirmDialog = {
  columnContainer: {
    marginTop: '12px',
    textAlign: 'left',
    marginBottom: 0,
  },
  subTitleContainer: {
    margin: '8px 0px',
  },
  subTitle: {
    lineHeight: lineHeight.textLineHeightXXL,
    marginLeft: '4px',
  },
  labelSubtitle: {
    fontWeight: fontWeight.bold,
  },
  columnHeader: {
    color: colors.secondary1,
    fontWeight: fontWeight.bold,
    marginBottom: '12px',
    fontSize: fontSize.textMD,
  },
  lessonsWrapper: {
    maxHeight: '400px',
    overflowY: 'auto',
  },
  lessonLine: {
    borderBottom: `1px solid ${colors.grey2}`,
  },
};

export const stylesAttachedHomeworkDialog = {
  subtitle: {
    fontSize: fontSize.textMD,
    margin: '12px 0',
  },
  description: {
    fontSize: fontSize.textMD,
    color: colors.black,
  },
};
