import { FC, MouseEvent, useState } from 'react';
import { Box, IconButton, Popover, Tooltip, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import SingleLookupField from '@/components/lookupField/singleLookupField';

import config from '@/config';
import { API_ENTITY, KEY_PROPERTY_PARAMS } from '@/constants';
import { ISchoolItem } from '@/types/School';
import { SORT_STATUS } from '@/constants/enums';
import { TClassRosterImportPopoverProps } from './ClassRosterImport';

import importIcon from '@/assets/images/icons/import.svg';

import { styles } from './styles';

const ClassRosterImportPopover: FC<TClassRosterImportPopoverProps> = ({
  isOrgAdmin,
  onSelectSchool,
  onClosePopover,
  onTriggerImportButton,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const id = 'import-popover';
  const open = Boolean(anchorEl);

  const handleClickImportPopover = (event: MouseEvent<HTMLButtonElement>) => {
    if (isOrgAdmin) return setAnchorEl(event.currentTarget);
    return onTriggerImportButton();
  };

  const handleCloseImportPopover = () => {
    setAnchorEl(null);
    onClosePopover();
  };

  const handleSelectSchool = (item: ISchoolItem) => {
    setAnchorEl(null);
    onSelectSchool(item);
  };

  return (
    <>
      <Tooltip
        title={<FormattedMessage id="dashboard.classRoster.rostersImporting" />}
        arrow={true}
        componentsProps={{
          tooltip: { sx: styles.tooltip },
        }}
      >
        <IconButton sx={styles.rosterImportingBtn} onClick={handleClickImportPopover}>
          <Box component="img" src={importIcon} />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseImportPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        BackdropProps={{ invisible: false }}
        PaperProps={{
          sx: styles.selectSchoolPopover,
        }}
      >
        <Box sx={styles.selectSchoolWrapper}>
          <Typography sx={styles.selectSchoolTitle}>
            <FormattedMessage id="dashboard.classRoster.rostersImporting" />
          </Typography>
          <SingleLookupField
            endpoint={config.API.SCHOOLS_ENDPOINT}
            defaultParams={{
              sort: KEY_PROPERTY_PARAMS.NAME,
              sortOrder: SORT_STATUS.ASC,
            }}
            dataKey={API_ENTITY.SCHOOLS}
            selectedDataKey="schoolId"
            itemDataKeys={['schoolName']}
            placeholder="dashboard.classRoster.selectSchool"
            onSelectedItem={(item) => handleSelectSchool(item)}
            enableLoadMore={true}
          />
        </Box>
      </Popover>
    </>
  );
};

export default ClassRosterImportPopover;
