import dev from './development';
import staging from './staging';
import prod from './production';

export interface IConfig {
  API: {
    ROOT_ENDPOINT: string;
    AUTH_ENDPOINT: string;
    PROFILES_ENDPOINT: string;
    USER_ENDPOINT: string;
    USER_BULK_UPLOAD_VERIFY_ENDPOINT: string;
    USER_BULK_UPLOAD_IMPORT_ENDPOINT: string;
    SCHOOLS_ENDPOINT: string;
    PROGRAMS_ENDPOINT: string;
    DISTRICTS_ENDPOINT: string;
    CITIES_ENDPOINT: string;
    CLASSES_ENDPOINT: string;
    SCHEDULES_HOMEWORK_ENDPOINT: string;
    SCHEDULES_LESSONS_ENDPOINT: string;
    SCHEDULES_LESSON_ENDPOINT: string;
    LESSON_CONTENTS_ENDPOINT: string;
    H5P_PLAY_ENDPOINT: string;
    ATTENDEES_ENDPOINT: string;
    CLIENT_DASHBOARDS_HOMEWORK_REPORTS: string;
    ATTEMPTED_HOMEWORK_ENDPOINT: string;
    MATERIALS_REPORT_ENDPOINT: string;
    SKILLS_REPORT_ENDPOINT: string;
    TRANSFER_ENDPOINT: string;
    ROLES_ENDPOINT: string;
    LESSON_MODULES_ENDPOINT: string;
    SCHEDULES_BULK_CREATE: string;
    GENERATE_REPORTS_ENDPOINT: string;
    UNITS_REPORTS_ENDPOINT: string;
    B2C_PROFILES_ENDPOINT: string;
    AUTH_OTP_PROVIDER_ENDPOINT: string;
    AUTH_VERIFY_OTP_ENDPOINT: string;
    AUTH_GENERATE_OTP_ENDPOINT: string;
    AUTH_REGISTER_ENDPOINT: string;
    AUTH_RESET_PASSWORD_ENDPOINT: string;
    ADS_ENDPOINT: string;
    C2P_PLAY_ENDPOINT: string;
  };
  EXTERNAL_DOMAIN: {
    APP_LINK_URL: string;
    DEEP_LINK_URL: string;
    AI_REPORT_URL: string;
    AI_REPORT_RESULT_URL: string;
  };
  LANG: string;
}

let config: IConfig = { ...dev };
const env = process.env.REACT_APP_ENV;
switch (env) {
  case 'dev':
    config = dev;
    break;
  case 'staging':
    config = staging;
    break;
  case 'prod':
    config = prod;
    break;
  default:
    break;
}

export const isDevEnv = () => {
  return process.env.REACT_APP_ENV === 'dev';
};

export const defaultConfig = {
  // Add common config values here
  env,
  ...config,
};

export default defaultConfig;
