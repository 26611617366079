import { colors, fontSize, lineHeight, fontWeight } from '@/styles/theme';

export const styles = {
  horizontalStepperContainer: {
    width: '100%',

    '& .MuiStepLabel-iconContainer': {
      display: 'flex',
      minWidth: '88px',
      minHeight: '40px',
      padding: '10px 14px',
      borderRadius: '4px',
      border: `1px solid ${colors.grey1}`,
      alignItems: 'center',
      justifyContent: 'center',
      gap: '16px',
      color: colors.grey1,
      cursor: 'pointer',

      '&.MuiStepLabel-alternativeLabel': {
        paddingRight: '14px',
      },
    },

    '& .MuiStepLabel-iconContainer, & .MuiStepLabel-iconContainer.Mui-active, & .MuiStepLabel-iconContainer.Mui-completed':
      {
        fontSize: fontSize.btnSM,
        lineHeight: lineHeight.btnMD,
        fontWeight: fontWeight.bold,
      },

    '& .MuiStepLabel-iconContainer.Mui-completed': {
      opacity: 0.8,
    },

    '& .MuiStepLabel-iconContainer.Mui-active, & .MuiStepLabel-iconContainer.Mui-completed': {
      background: colors.secondary1,
      color: colors.white1,
      border: 'none',
    },

    '& .MuiStepButton-root': {
      padding: 0,
    },
  },
  stepItem: {
    '& .MuiStepLabel-label': {
      display: 'flex',
      minWidth: '88px',
      minHeight: '40px',
      padding: '10px 14px',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '16px',
      color: colors.black1,
      cursor: 'pointer',

      '&.MuiStepLabel-alternativeLabel': {
        marginTop: 0,
      },
    },

    '& .MuiStepLabel-label, & .MuiStepLabel-label.Mui-active, & .MuiStepLabel-label.Mui-completed': {
      fontSize: fontSize.btnXS,
      lineHeight: lineHeight.btnMD,
      fontWeight: fontWeight.normal,
    },
  },
};
