import { colors, fontWeight } from '@/styles/theme';

export const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '88px',
    zIndex: 1,
  },
  title: {
    fontWeight: fontWeight.bold,
    fontSize: '18px',
    lineHeight: '28px',
    flexGrow: 1,
    color: colors.secondary1,
    textTransform: 'uppercase',
    margin: 'auto',
  },
  bell: {
    cursor: 'pointer',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    padding: '12px',
    borderRadius: '50%',
    backgroundColor: 'rgba(240, 246, 255, 0.5)',

    '.svg': {
      backgroundColor: colors.secondary1,
    },
  },
};
