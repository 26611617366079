import { TextField, TextFieldProps, Theme } from '@mui/material';
import get from 'lodash/get';
import { ReactNode, useEffect, useState, KeyboardEvent, forwardRef, Ref, FocusEvent } from 'react';

import { KEY_NAMES } from '@/constants/keyCode';
import { fontWeight } from '@/styles/theme';

const styles = {
  textfield: {
    marginTop: '25px',
    '& label.Mui-focused': {
      background: 'white',
      transform: 'translate(0px, -25px) scale(0.75)',
    },
    '& legend': {
      display: 'none',
    },
    '& input': {
      fontWeight: fontWeight.medium,
      fontSize: '15px',
    },
    '& input::placeholder': {
      fontWeight: fontWeight.normal,
    },
  },
  enteredTextField: (theme: Theme) => ({
    '& label': {
      transform: 'translate(0px, -25px) scale(0.75)',
      color: theme.palette.primary.main,
    },
  }),
};

const StyledTextField = forwardRef(({ sx, value, onKeyDown, ...rest }: TextFieldProps, ref: Ref<HTMLDivElement>) => {
  const { placeholder, onFocus, onBlur, label } = rest;
  const [labelState, setLabel] = useState<ReactNode | string>(placeholder);
  const [selfValue, setSelfValue] = useState<unknown>('');
  const [focused, setFocused] = useState(false);

  const handleFocus = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    setSelfValue(event.target.value);
    setFocused(true);

    onFocus?.(event);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    setSelfValue(event.target.value);
    setFocused(false);

    if (onBlur) {
      onBlur(event);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KEY_NAMES.ENTER || event.key === KEY_NAMES.TAB) {
      const currentValue = get(event, 'target.value') ?? '';
      setSelfValue(currentValue);

      if (onKeyDown) onKeyDown(event);
    }
  };

  useEffect(() => {
    if (!selfValue && !focused) {
      return setLabel(placeholder);
    }

    return setLabel(label);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selfValue, focused, label]);

  useEffect(() => {
    setSelfValue(value);
  }, [value]);

  return (
    <TextField
      {...rest}
      value={value}
      ref={ref}
      sx={[styles.textfield, selfValue ? styles.enteredTextField : {}, ...(Array.isArray(sx) ? sx : [sx])]}
      label={labelState}
      placeholder=""
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
    />
  );
});

export default StyledTextField;
