import { FC } from 'react';
import { Box, Button, Container } from '@mui/material';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import useProfileManagement from '@/store/profileManagement/ProfileManagement';
import { styles } from './styles';
import PageNotFoundImage from '@/assets/images/404.png';
import { navigateDefault } from '@/utils/commonUtil';

const PageNotFound: FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [profileMgtState] = useProfileManagement();
  const { activeUser } = profileMgtState ?? {};

  const handleGoBack = () => {
    activeUser && navigateDefault(activeUser, navigate);
  };

  return (
    <Container sx={styles.errorPageContainer}>
      <Box component="img" src={PageNotFoundImage} />

      <Box sx={styles.errorDescription}>
        {intl.formatMessage({
          id: 'common.error.404.description',
        })}
      </Box>

      <Button sx={styles.backToHomeBtn} variant="contained" onClick={handleGoBack}>
        {intl.formatMessage({
          id: 'common.button.backToHome',
        })}
      </Button>
    </Container>
  );
};

export default PageNotFound;
